import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs-home',
  templateUrl: './faqs-home.component.html',
  styleUrls: ['./faqs-home.component.css'],
})
export class FaqsHomeComponent implements OnInit {
  @Input() public backgroundColor: string = '';
  @Input() faqs: any[];
  @Input() public phone_number: string = '';

  constructor(
    public sanitizer: DomSanitizer,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  ngOnInit(): void {
    if (this.phone_number != '') {
      this.phone_number = this.phone_number.toString();
      this.phone_number.replace('34', '');
    }

    if (isPlatformServer(this._platformId)) {
      let script = this._renderer2.createElement('script');
      script.type = `application/ld+json`;
      let text_ob = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [],
      };

      this.faqs.forEach((faq) => {
        const updatedHtml = faq.text_html
          .toString()
          .replace('{{department_phone}}', this.phone_number.toString());

        text_ob.mainEntity.push({
          '@type': 'Question',
          name: faq.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: updatedHtml,
          },
        });
        script.text = JSON.stringify(text_ob);
        this._renderer2.appendChild(this._document.head, script);

        faq.text_html = this.sanitizer.bypassSecurityTrustHtml(
          `<style>a { color: #00bed6!important;}a:hover { color: #00bed6!important; }</style>` +
            updatedHtml
        );
      });
    }
  }
}
