import {isPlatformBrowser} from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import {ReviewInformation} from 'src/app/models/googlereview';
import {GoogleTextReviewService} from 'src/app/services/googlereview.services';

@Component({
  selector: 'app-opinions-home',
  templateUrl: './opinions-home.component.html',
  styleUrls: ['./opinions-home.component.css'],
})
export class OpinionsHomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('carrousel', {static: false})
  carrousel: ElementRef<HTMLDivElement>;
  @Input() reviews: ReviewInformation[] = [];
  public displayedReviews: ReviewInformation[] = [];
  public intervalId: any;
  public isHovered: boolean = false;
  public intervalTime: number = 2000;
  public scrollLeft: number = 3;
  public dots: any[] = [];
  public currentDotIndex: number = 0;
  private isTouching: boolean = false;

  constructor(
    private _googletextreviewsv: GoogleTextReviewService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngOnInit() {
    while (this.reviews.length > 0 && this.displayedReviews.length < 10) {
      this.displayedReviews = [...this.displayedReviews, ...this.reviews];
    }
  }

  ngAfterViewInit() {
    this.startCarousel();
    this.calculateDots();
    this.cdr.detectChanges();
  }

  startCarousel() {
    if (this.carrousel && this.carrousel.nativeElement) {
      this.setIntervalTime();
      if (isPlatformBrowser(this.platformId)) {
        this.intervalId = setInterval(() => {
          if (!this.isHovered && !this.isTouching) {
            const carrouselElement = this.carrousel.nativeElement;
            carrouselElement.scrollLeft += this.scrollLeft;

            if (
              carrouselElement.scrollLeft >=
              carrouselElement.scrollWidth - carrouselElement.clientWidth
            ) {
              carrouselElement.scrollLeft = 0;
            }

            this.updateCurrentDot();
          }
        }, this.intervalTime);
      }
    }
  }

  setIntervalTime() {
    if (isPlatformBrowser(this.platformId) && window.innerWidth < 600) {
      this.intervalTime = 15;
      this.scrollLeft = 1;
    } else {
      this.intervalTime = 100;
    }
  }

  onCarouselInteraction() {
    if (window.innerWidth < 600) {
      this.isTouching = !this.isTouching;
      if (!this.isTouching) {
        this.startCarousel();
      } else {
        clearInterval(this.intervalId);
      }
    }
  }

  onCarouselHover(isHovered: boolean) {
    this.isHovered = isHovered;
    if (isHovered || this.isTouching) {
      clearInterval(this.intervalId);
    } else {
      this.startCarousel();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setIntervalTime();
    this.calculateDots();
  }

  calculateDots() {
    if (this.carrousel && this.carrousel.nativeElement) {
      const carrouselElement = this.carrousel.nativeElement;
      const carrouselWidth = carrouselElement.clientWidth;
      if (carrouselWidth && carrouselWidth > 0) {
        this.dots = new Array(
          Math.ceil(carrouselElement.scrollWidth / carrouselWidth)
        ).fill(null);
      }
    }
  }

  scrollToPage(pageIndex: number) {
    if (this.carrousel && this.carrousel.nativeElement) {
      const carrouselElement = this.carrousel.nativeElement;
      const carrouselWidth = carrouselElement.clientWidth;
      carrouselElement.scrollLeft = pageIndex * carrouselWidth;
      this.currentDotIndex = pageIndex;
      clearInterval(this.intervalId);
    }
  }

  updateCurrentDot() {
    if (this.carrousel && this.carrousel.nativeElement) {
      const carrouselElement = this.carrousel.nativeElement;
      const carrouselWidth = carrouselElement.clientWidth;
      this.currentDotIndex = Math.floor(
        carrouselElement.scrollLeft / carrouselWidth
      );
    }
  }

  onCarouselTouchStart() {
    this.isTouching = true;
    clearInterval(this.intervalId);
  }

  onCarouselTouchEnd() {
    this.isTouching = false;
    if (!this.isHovered) {
      this.startCarousel();
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
}
