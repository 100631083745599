import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.auth.isLoggedIn()) {
      if (state.url.includes('backoffice/login')) {
        return true;
      } else {
        this.router.navigate(['backoffice/login'], {
          queryParams: { returnUrl: state.url.split('?')[0] },
          queryParamsHandling: 'merge',
        });
        return false;
      }
    } else {
      return this.auth.getUser().pipe(
        map((userResp) => {
          this.auth.isAdmin.next(userResp.is_staff);
          if (!userResp.is_staff) {
            this.router.navigate(['error'], { skipLocationChange: true });
            return false;
          }
          return true;
        }),
        catchError((err) => {
          this.router.navigate(['login']);
          this.auth.doLogoutUser();
          return throwError(err);
        })
      );
    }
  }
}
