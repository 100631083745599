import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { UtilsServices } from '../services/utils.services';

@Directive({
  selector: '[appMaxCustom]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true },
  ],
})
export class MaxValidatorDirective implements Validator {
  @Input('appMaxCustom') number: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.number
      ? this.#maxValidator(new Number(this.number))(control)
      : null;
  }

  #maxValidator(int: Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        control.value != null &&
        typeof control.value === 'string' &&
        control.value.indexOf('%')
      ) {
        return new Number(
          control.value.trim().replace('%', '').replace(',', '')
        ) > int
          ? { max: { value: control.value } }
          : null;
      }
      return new Number(control.value) > int
        ? { max: { value: control.value } }
        : null;
    };
  }
}

@Directive({
  selector: '[appMinCustom]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true },
  ],
})
export class MinValidatorDirective implements Validator {
  @Input('appMinCustom') number: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.number
      ? this.#minValidator(new Number(this.number))(control)
      : null;
  }

  #minValidator(int: Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        control.value != null &&
        typeof control.value === 'string' &&
        control.value.indexOf('%')
      ) {
        return new Number(
          control.value.trim().replace('%', '').replace(',', '')
        ) < int
          ? { min: { value: control.value } }
          : null;
      }
      return new Number(control.value) < int
        ? { min: { value: control.value } }
        : null;
    };
  }
}

@Directive({
  selector: '[appMaxDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: maxDateValidatorDirective,
      multi: true,
    },
  ],
})
export class maxDateValidatorDirective implements Validator {
  @Input('appMaxDate') date: Date;
  validate(control: AbstractControl): ValidationErrors | null {
    return this.date
      ? this.#maxDateValidator(new Date(this.date))(control)
      : this.#maxDateValidator(new Date())(control);
  }

  #maxDateValidator(maxDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value == null) {
        return null;
      } else {
        return new Date(control.value) > maxDate
          ? { max: { value: control.value } }
          : null;
      }
    };
  }
}

@Directive({
  selector: '[appMinDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: minDateValidatorDirective,
      multi: true,
    },
  ],
})
export class minDateValidatorDirective implements Validator {
  @Input('appMinDate') date: Date;
  validate(control: AbstractControl): ValidationErrors | null {
    return this.date
      ? this.#minDateValidator(new Date(this.date))(control)
      : this.#minDateValidator(new Date())(control);
  }

  #minDateValidator(minDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value == null) {
        return null;
      } else {
        return new Date(control.value) < minDate
          ? { min: { value: control.value } }
          : null;
      }
    };
  }
}

@Directive({
  selector: '[appValidDNI]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: dniCheckDirective, multi: true },
    UtilsServices,
  ],
})
export class dniCheckDirective implements Validator {
  constructor(public _utilssv: UtilsServices) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    } else {
      return this._utilssv.is_dni_valid(control.value)
        ? null
        : { dni: { value: control.value } };
    }
  }
}

// Caps insensitive
@Directive({
  selector: '[appInputInSet]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: inputInSetValidatorDirective,
      multi: true,
    },
  ],
})
export class inputInSetValidatorDirective implements Validator {
  @Input('appInputInSet') valid_values: string[];

  validate(control: AbstractControl): ValidationErrors | null {
    return this.valid_values
      ? this.#inputInSetValidatorDirective(this.valid_values)(control)
      : null;
  }

  #inputInSetValidatorDirective(valid_values: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value == null) return null;

      const valid_values_uppercase = valid_values.map((str) =>
        str.toUpperCase()
      );
      return valid_values_uppercase.indexOf(control.value.toUpperCase()) == -1
        ? { value_not_in_set: { value: control.value } }
        : null;
    };
  }
}
