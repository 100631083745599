<div [formGroup]="superForm" class="inputs">
  <div class="input-column">
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="country"
        >País:</label
      >
      <ng-select
        notFoundText="Selecciona una opción de la lista"
        [clearable]="false"
        formControlName="country"
      >
        <ng-option
          class="ng-option"
          *ngFor="let country of countriesOptions"
          [value]="country"
          >{{ country }}</ng-option
        >
      </ng-select>
      <div *ngIf="superForm.get('country').hasError('required')">
        <small class="error-message">Requerido.</small>
      </div>
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="municipality"
        >Municipio:</label
      >
      <input
        list="municipality-list"
        type="text"
        id="municipality"
        formControlName="municipality"
      />
      <datalist id="municipality-list">
        <option
          *ngFor="let municipality of municipalityOptions"
          [value]="municipality"
        >
          {{ municipality }}
        </option>
      </datalist>
      <div *ngIf="superForm.get('municipality').hasError('required')">
        <small class="error-message">Requerido.</small>
      </div>
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="street"
        >Nombre de la Vía:</label
      >
      <input
        list="street-list"
        type="text"
        id="street"
        formControlName="street"
      />
      <datalist id="street-list">
        <option *ngFor="let street of streetOptions" [value]="street">
          {{ street }}
        </option>
      </datalist>
      <div *ngIf="superForm.get('street').hasError('required')">
        <small class="error-message">Requerido.</small>
      </div>
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="block"
        >Bloque:</label
      >
      <input type="text" id="block" formControlName="block" />
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="floor"
        >Piso:</label
      >
      <input type="text" id="floor" formControlName="floor" />
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="postal_code"
        >Código Postal:</label
      >
      <input type="text" id="postal_code" formControlName="postal_code" />
      <div *ngIf="superForm.get('postal_code').hasError('required')">
        <small class="error-message">Requerido.</small>
      </div>
    </div>
  </div>
  <div class="input-column">
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="province"
        >Província:</label
      >
      <ng-select
        *ngIf="superForm.get('country').value === 'España'"
        notFoundText="Selecciona una opción de la lista"
        [clearable]="false"
        formControlName="province"
      >
        <ng-option
          class="ng-option"
          *ngFor="let province of provincesSpainOptions"
          [value]="province"
          >{{ province }}</ng-option
        >
      </ng-select>
      <input
        *ngIf="superForm.get('country').value !== 'España'"
        type="text"
        id="province"
        formControlName="province"
      />
      <div *ngIf="superForm.get('province').hasError('required')">
        <small class="error-message">Requerido.</small>
      </div>
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="street_type"
        >Tipo de Vía:</label
      >
      <ng-select
        notFoundText="Selecciona una opción de la lista"
        [clearable]="false"
        formControlName="street_type"
      >
        <ng-option
          class="ng-option"
          *ngFor="let streetType of streetTypesOptions"
          [value]="streetType.id"
          >{{ streetType.name }}</ng-option
        >
      </ng-select>
      <div *ngIf="superForm.get('street_type').hasError('required')">
        <small class="error-message">Requerido.</small>
      </div>
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="number"
        >Número:</label
      >
      <input
        type="number"
        id="number"
        formControlName="number"
        min="1"
        step="1"
      />
      <div *ngIf="superForm.get('number').hasError('required')">
        <small class="error-message">Requerido.</small>
      </div>
      <div *ngIf="superForm.get('number').hasError('min')">
        <small class="error-message">Debe ser mínimo 1.</small>
      </div>
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="stairway"
        >Escalera:</label
      >
      <input type="text" id="stairway" formControlName="stairway" />
    </div>
    <div class="input-block">
      <label
        class="input-title"
        [ngClass]="blackLabels ? 'black-title' : 'white-title'"
        for="door"
        >Puerta:</label
      >
      <input type="text" id="door" formControlName="door" />
    </div>
  </div>
</div>
