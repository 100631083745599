import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisableMousewheelScroll]',
})
export class DisableMousewheelScrollDirective {
  @HostListener('wheel', ['$event'])
  public onScroll(event: WheelEvent) {
    event.preventDefault();
  }
}
