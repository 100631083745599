import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { DjangoErrorHandler } from 'src/app/django.error';
import { ProductsID } from 'src/app/models/contrato';
import { Contact } from 'src/app/models/formcontacto';
import {
  getRegisterAnalyticsURL,
  RegisterAnalytics,
} from 'src/app/models/register';
import { ContactoServices } from 'src/app/services/formcontacto.services';

declare let gtag: Function;
declare let window: any;

@Component({
  selector: 'app-contacto-iframe',
  templateUrl: './contacto-iframe.component.html',
  styleUrls: ['./contacto-iframe.component.css'],
  providers: [ContactoServices],
})
export class ContactoIframeComponent implements OnInit, AfterViewInit {
  public contact: Contact;
  public nonFieldError;
  public iframe_size: string;
  public showPopUp: boolean = false;
  public product_id: number = ProductsID.inheritance_product_id;
  public lead_origin: string = 'landing';
  public affair: string = 'LD';
  public loading: boolean;
  public register_analytics: RegisterAnalytics;
  @ViewChild('contactForm', { static: false }) contactForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private _contactservice: ContactoServices,
    private djangoError: DjangoErrorHandler,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.contact = new Contact(this.affair);
  }

  ngOnInit(): void {
    getRegisterAnalyticsURL(this.route).subscribe((resp) => {
      this.register_analytics = resp;
    });

    this.route.params.subscribe((params: Params) => {
      this.iframe_size = params.iframesize;
    });
    this.contact.enley_accepted = false;
    this.contact.gdpr_accepted = false;
    this.djangoError.nonFieldError.subscribe(
      (error) => (this.nonFieldError = error)
    );
  }

  submit() {
    this.loading = true;
    let contactFormBody = { ...this.contact };

    contactFormBody.register_analytics = this.register_analytics;
    contactFormBody.product_id = this.product_id;

    this._contactservice.postContact(contactFormBody).subscribe(
      (contactResult) => {
        this.showPopUp = true;
        this.loading = false;
        if (
          isPlatformBrowser(this.platformId) &&
          document.location.hostname.search('www.enley.com') !== -1
        ) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'contact_form',
          });
        }
      },
      (error) => {
        this.loading = false;

        this.djangoError.handleError(error, this.contactForm);
      }
    );
  }

  closePopup() {
    this.showPopUp = false;
  }

  @HostListener('window:resize', ['$event'])
  onesize(event) {
    this.send_height_to_parent_function();
  }

  send_height_to_parent_function() {
    if (document.getElementById('formcontacto')) {
      var height = document.getElementById('formcontacto').clientHeight;
      parent.postMessage({ height: height }, '*');
    }
  }

  ngAfterViewInit() {
    if (window.self !== window.top) {
      // if w.self === w.top, we are not in an iframe
      // send message to parent about height updates
      this.send_height_to_parent_function(); //whenever the page is loaded
      var observer = new MutationObserver(this.send_height_to_parent_function); // whenever DOM changes PT1
      var config = {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      }; // PT2
      observer.observe(window.document, config); // PT3
    }
  }
}
