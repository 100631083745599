export const CATEGORY_URL_TO_BLOG_CATEGORY = new Map([
  ['ley-segunda-oportunidad', 'Ley segunda oportunidad'],
  ['succesiones', 'Sucesiones'],
  ['global-mobility', 'Global mobility'],
  ['inmobiliario', 'Inmobiliario'],
  ['extranjeria', 'Extranjería'],
  ['laboral-b2c', 'Gestoría'],
  ['arranque-empresa', 'Empresa'],
  ['propiedad-intelectual', 'Empresa'],
  ['compliance', 'Empresa'],
  ['operaciones-societarias', 'Empresa'],
  ['contractual', 'Empresa'],
  ['laboral-b2b', 'Gestoría'],
  ['gestoria', 'Gestoría'],
  ['subvenciones', 'Empresa'],
  ['concursal', 'Concursal'],
  ['reclamaciones-judiciales', 'Empresa'],
  ['familia', 'Familia'],
]);

const STATIC_REVIEWS_FAMILIA = [
  {
    reviewer: {
      displayName: 'Amina Elk',
    },
    starRating: 5,
    comment:
      'Hace unos meses empecé mi aventura en proceso de divorcio (convenio de mutuo acuerdo), en estos casos uno anada perdido y no sabe lo pasos a seguir y es muy dificil. Desde que hice la solicitud a través de la página, fue todo muy rápido y resolviendo mis dudas desde el primer momento, siempre dispuestos. Hoy terminó la gestión con Enley (Lorenzo) y ya tengo la resolución, solo puedo dar la gracias por todo el buen servicio y la profesionalidad que ofrecéis.',
  },
  {
    reviewer: {
      displayName: 'Robert Lokman',
    },
    starRating: 5,
    comment:
      'Hola, todo el trámite de divorcio ha ido exactamente como anunciado. El equipo es amable y riguroso. Van siempre por delante de cada trámite y la comunicación ha sido excelente. En cuanto al coste, es infinitamente más económico que la competencia. Los recomiendo muy mucho porque son insuperables. Un cordial saludo para todo el Equipo.',
  },
  {
    reviewer: {
      displayName: 'Dona Armas',
    },
    starRating: 5,
    comment:
      'Hola, comencé un trámite de divorcio hace muy poco y ya está concluido sin retrasos ni demoras en la fecha que acordamos alli estuve y todo perfectamente bien el personal muy profesional y eficiente te responden rápido y algo hoy en día muy importante son los honorarios nada costoso pero nada de verdad muy satisfecha con el trabajo. Sin duda cualquier trámite que tenga que hacer los haré con ellos.',
  },
  {
    reviewer: {
      displayName: 'Carmen Jurado',
    },
    starRating: 5,
    comment:
      'Un divorcio express un poco complicado ya que la parte contraria se encuentra residiendo en el extranjero. Con su esmerada atención y ayuda todo se solucionó de una manera mucho más sencilla de lo que parecía en un principio. Incluso un documento importante que envié por error al procurador, me fue devuelto por correo certificado y todo solucionado. Muchas gracias.',
  },
  {
    reviewer: {
      displayName: 'Laiacarracedo Carracedo',
    },
    starRating: 5,
    comment:
      'Recomendable totalmente, profesionales. Al principio estaba un poco duditativa,por gestionarse todo vía online,pero el resultado ha sido 100% positivo. El trámite en si,q era un convenio regulador,no se ha alargado más de tres meses. En cualquier consulta q hacía,me han respuesta con brevedad y aclaración. Si preciso otra vez de abogados,no dudaría en volverlos a contratar. Y precio muy económico.',
  },
  {
    reviewer: {
      displayName: 'Laura López López',
    },
    starRating: 5,
    comment:
      'Llevaron el papeleo de la custodia de mi hijo y fue todo super fácil sin tener que pisar el despacho de un abogado, con instrucciones claras y super económico. Lo recomiendo 100x100.',
  },
];

const STATIC_REVIEWS_SUCESIONES = [
  {
    reviewer: {
      displayName: 'Juan Garcia',
    },
    starRating: 5,
    comment:
      'Excelente, económico y expertos en la materia. Tenia un conflicto enquistado desde hace más de 10 años por una herencia familiar. Contraté sus servicios y han conseguido desbloquear el asunto, continuar con la tramitación y que las partes firmen la aceptación de herencia. Nuestra abogada, Graciela, ha hecho un trabajo impecable. Muy agradecido por la atención y la calidad del servicio',
  },
  {
    reviewer: {
      displayName: 'Rm',
    },
    starRating: 5,
    comment:
      'Aunque tenía mi reticencia por la diferencia que había de precio, he de decir que han superado con mucho mis expectativas, en todo momento acompañada, han resuelto todas mis dudas, gracias a Lorenzo por su eficacia. 100% recomendable',
  },
  {
    reviewer: {
      displayName: 'Jorge De La Lastra',
    },
    starRating: 5,
    comment:
      'Mi primera experiencia con abogados. Era por una herencia muy complicada. Tenia mis reservas porque era por internet. Pero enseguida se puso en contacto conmigo Ariadna que me llamo por telefono y me asesoro durante varias semanas. Finalmente no hemos seguido a delante con el proceso pero nos han ayudado mucho a resolver todas nuestras dudas. Muchas gracias',
  },
  {
    reviewer: {
      displayName: 'CP',
    },
    starRating: 5,
    comment:
      'Buenos días! Agradezco enormemente a Ariadna Heras el trato que recibí por un tramite hereditario. Me ayudo mucho en todas mis dudas y cuestiones sobre el tema pero sobre todo por su paciencia conmigo. Siempre muy agradable. Seguro que si en un futuro necesito vuestra ayuda, volveré a confiar en vosotros. Estoy muy contenta. Recomiendo Enley cien por cien.',
  },
  {
    reviewer: {
      displayName: 'Isabel García García',
    },
    starRating: 5,
    comment:
      'Me han tramitado una herencia complicada, con venta de inmueble por el medio, y el trato ha sido impecable. La verdad es que no confiaba mucho en un servicio online y mucho menos por el precio que indicaban, estaba segura de que habría alguna sorpresa o extras en el precio pero no, todo ha sido exactamente como me dijeron el primer día. Volvería a contar con ellos otra avez si necesito abogado sin dudar.',
  },
  {
    reviewer: {
      displayName: 'Dusan Timotijevic',
    },
    starRating: 5,
    comment:
      'He tenido que consultar sobre un asunto de herencias y la verdad que Marta me ha informado muy bien y ha resuelto todas mis dudas. Muchisimas gracias!',
  },
  {
    reviewer: {
      displayName: 'Marc Quevedo Pou',
    },
    starRating: 5,
    comment:
      'Me han gestionado una herencia y ha sido todo muy fácil. Han respondido a todas mis dudas cada vez que lo he necesitado. El trato de Montse y Marta ha sido excepcional. Muy agradecido',
  },
  {
    reviewer: {
      displayName: 'Concha Sanz Letón',
    },
    starRating: 5,
    comment:
      'Abogadas atentas, pacientes y cariñosas, que me han acompañado en un momento difícil de mi vida. Gracias infinitas Gloria y Paula.',
  },
];

const STATIC_REVIEWS_INMOBILIARIO = [
  {
    reviewer: {
      displayName: 'J St. Clair',
    },
    starRating: 5,
    comment:
      'Compré una casa y un trastero y todo se manejó perfectamente con rapidez. Recomiendo totalmente esta empresa para comprar o vender vivienda. Seguro que no hay mejor. Muchas gracias Raquel.',
  },
  {
    reviewer: {
      displayName: 'Patricia Serrano',
    },
    starRating: 5,
    comment:
      'Contratamos a enley para una compra-venta y la abogada (Hilda) ha demostrado ser muy profesional y tener una paciencia infinita, teniendo en cuenta que el abogado contrario ha sido un hueso duro de roer.',
  },
];

const STATIC_REVIEWS_LEY_SEGUNDA_OPORTUNIDAD = [
  {
    reviewer: {
      displayName: 'Esperanza Gonzalez Curiel',
    },
    starRating: 5,
    comment:
      'Inmejorable el trato y el servicio que he recibido por parte del equipo de Enley, especialmente por parte de Anna Valero y Miguel Llenín que desde un principio me han asesorado de forma inmediata y cercana. Me han hecho sentirme segura y cercana a ellos en un proceso de Ley de la Segunda Oportunidad que pensaba que sería difícil y muy complejo, pero con Enley he estado asesorada en todo momento, guiada para cada paso a realizar y sobre todo, muy cercana a los profesionales que han llevado mi caso. Agradezco enormemente la empatía y toda la paciencia que ha tenido conmigo. Gracias por todo el buen trabajo. Os recomendaré siempre.',
  },
  {
    reviewer: {
      displayName: 'Evelin Valverde',
    },
    starRating: 5,
    comment:
      'Genial, me han arreglado la vida y puedo empezar de cero. El trato de Olga y Samantha a sido genial todo el rato he estado asesorada, y te recomiendan si lo ven viable o no para gestionarlo. Sin ninguna duda, si tengo otra gestión volveré a contactar con ellos.',
  },
  {
    reviewer: {
      displayName: 'Francisco Javier Abucha Gomez',
    },
    starRating: 5,
    comment:
      'Como ya e dicho en otra ocasión es un placer trabajar co este buffet pero después de tener la resolución de un juez sobre la ley de la segunda oportunidad e tenido unos problemillas y ana Valero me lo a solucionado en un abrir y cerrar de ojos es increíble el potencial de este buffet y sobre todo de ana Valera q es con quién e tratado yo mil gracias es poco.',
  },
  {
    reviewer: {
      displayName: 'Rubén Lozano',
    },
    starRating: 5,
    comment:
      'Excelente trato desde principio a fin, todo claro, desde coste hasta cada uno de los pasos que se siguen. Constante comunicación con la abogada Samantha Contreras y su Gestora Olga. Cualquier duda es resuelta al momento. 1000% recomendable. Ahorro de costes y resultados increíblemente mejores de los aprestos. Cumplen con lo prometido. Sin duda la mejor opción.',
  },
  {
    reviewer: {
      displayName: 'Aria García',
    },
    starRating: 5,
    comment:
      'Recomendado al 1000*1000 yo mi gestion me la realizado Anna y hoy me han comunicado que estoy exonerada !! La mayor alegria de mi vida y todo gracias a ellos , a pesar de la distancia y de ser on line su profesionalidad me transmitio paz y tranquilidad y asi me lo han demostrado !',
  },
  {
    reviewer: {
      displayName: 'Iván Sánchez González',
    },
    starRating: 5,
    comment:
      'Recién exonerado de mis deudas y recibido el EPI,quiero dar las gracias al equipo de profesionales que hay detrás de Enley,en especial a la abogada Anna Valero,que es la que me ha llevado todo el procedimiento de la ley de la 2ª oportunidad. Subrayar la profesionalidad con la que ha abordado todo,la empatía y el trato cercano recibido,que en éstos casos se agradece muchísimo. El precio ha sido realmente bajo en comparación con lo que piden otros abogados,y a éso hay que sumarle la facilidad de pago que ofrecen. Se nota que tienen en cuenta la situación en la que se encuentra el cliente y éso se agradece. Gracias a ellos vuelvo a creer en la justicia. Ojalá puedan seguir ayudando a personas con ésta ley en concreto. Un abrazo enorme.',
  },
  {
    reviewer: {
      displayName: 'Diego Nieto Huerta',
    },
    starRating: 5,
    comment:
      'Todo ha sido rápido y muy bien gestionado. Me han informado perfectamente durante todo el procedimiento. Un 10 para Enley.',
  },
  {
    reviewer: {
      displayName: 'Marisa Carrion',
    },
    starRating: 5,
    comment:
      'Mi experiencia a sido maravillosa con Ana Valero experta en proceso de Ley de Segunda oportunidad, después de 20 años arrastrando deudas ,en 6 meses soy una persona libre ,ella me lo a puesto muy facil ,cada paso me a informado al instante, sin duda la mejor experiencia con este bufete de abogados ,gracias por todo.',
  },
];

const STATIC_REVIEWS_GLOBAL_MOBILITY = [
  {
    reviewer: {
      displayName: 'Mario Redondo',
    },
    starRating: 5,
    comment:
      'Trabajo excelente y siempre atentos para solucionar cualquier duda.',
  },
  {
    reviewer: {
      displayName: 'Ana Lain Soto Martínez',
    },
    starRating: 5,
    comment: 'Muy amables y muy buen servicio.',
  },
  {
    reviewer: {
      displayName: 'Toni Moreno',
    },
    starRating: 5,
    comment:
      'Encantada con el trato y el servicio son amables te atienden en todo momento y todo muy claro ningun problema con ellos.',
  },
  {
    reviewer: {
      displayName: 'Juan Carlos Saez Perez',
    },
    starRating: 5,
    comment:
      'Muy buen servicio, trasparente, económico y muy detallado. Muchas gracias equipo.',
  },
  {
    reviewer: {
      displayName: 'EDEL DEL BARCO MORILLO',
    },
    starRating: 5,
    comment:
      'Mi recomendación 100%. Profesionales y resolutivos. Atienden también tus dudas por mail. Plataforma intuitiva, sencilla e informativa de cada trámite. No dudes en solicitar su asesoramiento on-line.',
  },
  {
    reviewer: {
      displayName: 'Camilo Montiel Espinosa',
    },
    starRating: 5,
    comment:
      'Excelente atención, todo muy rápido y expedito, se dan el tiempo de explicar todo el proceso y tienen muy buen trato, lo recomiendo al 1000%.',
  },
];

const STATIC_REVIEWS_EXTRANJERIA = [
  {
    reviewer: {
      displayName: 'Bouchtla leij fakir',
    },
    starRating: 5,
    comment:
      '¡Servicio increíble! Han sido excelentes al establecer mi entidad legal española.',
  },
  {
    reviewer: {
      displayName: 'David Virgili Clarasso',
    },
    starRating: 5,
    comment:
      'Ha sido un trato cercano, aun siendo todo online, me he sentido acompañado y bien asesorado por todo el equipo de enley, recomendable 100%.',
  },
  {
    reviewer: {
      displayName: 'Fernando Pérez',
    },
    starRating: 5,
    comment:
      'Es la segunda vez que utilizo los servicios de esta empresa, son muy eficaces, además de prestar buen servicio.',
  },
  {
    reviewer: {
      displayName: 'Eva Piñera Pérez',
    },
    starRating: 5,
    comment:
      'Todo fue estupendo, lo gestionaron sin ningún tipo de incidencia y hemos quedado muy satisfechos.',
  },
  {
    reviewer: {
      displayName: 'Sara Medina Ayuso',
    },
    starRating: 5,
    comment:
      'Quedo muy satisfecha tanto con el trato como la efectividad en el proceso, todo ha sido muy fácil y rápido, lo recomiendo 100% , gracias por vuestro tiempo y trabajo ENLEY👌…',
  },
  {
    reviewer: {
      displayName: 'EDUARDO SAN JUAN',
    },
    starRating: 5,
    comment:
      'Buen servicio atentos y dando la información precisa muy recomendable. Paula muy atenta buenos profesionales.',
  },
];

const STATIC_REVIEWS_LABORAL_B2C = [
  {
    reviewer: {
      displayName: 'Wilbel Frias',
    },
    starRating: 5,
    comment: 'Todo muy fácil, económico y servicial. 100% recomendado.',
  },
  {
    reviewer: {
      displayName: 'Pilar Ferrrer Cañas',
    },
    starRating: 5,
    comment:
      'Me ha encantado los servicio la prestados, todo ha sido súper rápido, fácil y cómodo. Lo recomiendo completamente.',
  },
  {
    reviewer: {
      displayName: 'Santiago Francisco Díaz Sanz',
    },
    starRating: 5,
    comment:
      'Grandes profesionales y muy agiles en su gestión. Siempre han estado pendientes de todo detalle y una cercanía que permite estar tranquilos en todo momento. Gracias ENLEY.',
  },
  {
    reviewer: {
      displayName: 'Manuel Seijas Cortés',
    },
    starRating: 5,
    comment:
      'Un servicio estupendo, rápido, eficaz y con muy buena comunicación. Da gusto trabajar con empresas así de profesionales.',
  },
  {
    reviewer: {
      displayName: 'Veronica B V',
    },
    starRating: 5,
    comment:
      'Tranquila en todo momento con el proceso!!! Muy buen precio, amables y súper atentos. Lo recomiendo. Gracias por todo.',
  },
  {
    reviewer: {
      displayName: 'Begoña Morales Rey',
    },
    starRating: 5,
    comment:
      'Excelente servicio, trato exquisito por parte de las abogadas Gloria, Berta, Elisabet y Paula. 100% recomendable!',
  },
];

export const STATIC_REVIEWS_BY_CATEGORY = new Map([
  ['familia', STATIC_REVIEWS_FAMILIA],
  ['sucesiones', STATIC_REVIEWS_SUCESIONES],
  ['inmobiliario', STATIC_REVIEWS_INMOBILIARIO],
  ['ley-segunda-oportunidad', STATIC_REVIEWS_LEY_SEGUNDA_OPORTUNIDAD],
  ['global-mobility', STATIC_REVIEWS_GLOBAL_MOBILITY],
  ['extranjeria', STATIC_REVIEWS_EXTRANJERIA],
  ['laboral-b2c', STATIC_REVIEWS_LABORAL_B2C],
]);
