<div class="grey-out-background">
  <div class="wrapper">
    <div
      aria-label="Close"
      (click)="closePopUp()"
      href="javascript:void(0)"
      class="closeButton"
    >
      <img src="assets/img/close-notification.svg" alt="" />
    </div>
    <div class="title">PAGO FACTURA</div>
    <form (ngSubmit)="onSubmit()" [formGroup]="paymentAmount">
      <div class="input-block">
        <label class="input-title" for="amount">Cantidad con IVA:</label>
        <input type="number" id="amount" formControlName="amount" min="1" />
        <div
          *ngIf="
            paymentAmount.hasError('amountRequired') ||
            paymentAmount.get('amount').hasError('min')
          "
        >
          <small class="error-message">Debe ser mayor que zero.</small>
        </div>
      </div>
      <button type="submit">Hacer pago</button>
    </form>
  </div>
</div>
