export class Review {
  constructor(
    public name: string,
    public max_score: string,
    public score: string,
    public description: string
  ) {}

  static fromDict({ name, max_score, score, description }): Review {
    return new Review(name, max_score, score, description);
  }
}
