<label role="checkbox">
  <input
    type="radio"
    #name="ngModel"
    [(ngModel)]="storedAt"
    [value]="value"
    (click)="setValue($event)"
    required
  />
  <div role="presentation"></div>
  <span>{{ option }}</span>
</label>
