<mat-progress-bar *ngIf="!loading" mode="indeterminate"></mat-progress-bar>
<img
  src="assets/img/productcategory/generic_product_modal_header.svg"
  class="img-header"
/>
<mat-dialog-content class="container">
  <img
    src="assets/img/productcategory/close.svg"
    class="close"
    (click)="close()"
  />
  <ng-container *ngIf="!isSubmitted">
    <h1 class="wawawiwi">
      ¿Quieres más información?<br />Déjanos tus datos y te llamaremos.
    </h1>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
      <ng-container *ngIf="!user">
        <div class="form-group form-group-half">
          <label for="name">Nombre*</label>
          <input
            id="name"
            class="form-control"
            formControlName="name"
            placeholder="Nombre*"
          />
          <div
            *ngIf="
              contactForm.get('name').invalid &&
              (contactForm.get('name').dirty || contactForm.get('name').touched)
            "
          >
            <div
              class="error-message"
              *ngIf="contactForm.get('name').errors.required"
            >
              El nombre es obligatorio.
            </div>
          </div>
        </div>
        <div class="form-group form-group-half">
          <label for="surnames">Apellidos*</label>
          <input
            id="surnames"
            class="form-control"
            formControlName="surnames"
            placeholder="Apellidos*"
          />
          <div
            *ngIf="
              contactForm.get('surnames').invalid &&
              (contactForm.get('surnames').dirty ||
                contactForm.get('surnames').touched)
            "
          >
            <div
              class="error-message"
              *ngIf="contactForm.get('surnames').errors.required"
            >
              Los apellidos son obligatorios.
            </div>
          </div>
        </div>
        <div class="form-group form-group-half">
          <label for="email">Correo electrónico*</label>
          <input
            id="email"
            class="form-control"
            formControlName="email"
            placeholder="Correo electrónico*"
          />
          <div
            *ngIf="
              contactForm.get('email').invalid &&
              (contactForm.get('email').dirty ||
                contactForm.get('email').touched)
            "
          >
            <div
              class="error-message"
              *ngIf="contactForm.get('email').errors.required"
            >
              El correo electrónico es obligatorio.
            </div>
            <div
              class="error-message"
              *ngIf="contactForm.get('email').errors.email"
            >
              Dirección de correo electrónico no válida.
            </div>
          </div>
        </div>
        <div class="form-group form-group-half">
          <label for="phone_number">Número de teléfono*</label>
          <app-custom-phone-reactive-form
            class="generic-product-modal form-contact"
            required
            [custom_class]="'custom-phone-contact-form-product'"
            style="width: 100%"
            [placeholder]="'Número de teléfono*'"
            name="phone_number"
            [form]="contactForm.get('phone_number')"
          ></app-custom-phone-reactive-form>
          <div
            *ngIf="
              contactForm.get('phone_number').invalid &&
              (contactForm.get('phone_number').dirty ||
                contactForm.get('phone_number').touched)
            "
          >
            <div
              class="error-message"
              *ngIf="contactForm.get('phone_number').errors.required"
            >
              El número de teléfono es obligatorio.
            </div>
          </div>
          <small
            *ngIf="
              !contactForm.get('phone_number').valid &&
              contactForm.get('phone_number').errors != null
            "
            style="color: red"
          >
            {{ contactForm.get("phone_number").errors.message }}
          </small>
        </div>
      </ng-container>
      <div class="form-group">
        <label for="comment">Comentario</label>
        <textarea
          id="comment"
          class="form-control text-area"
          formControlName="comment"
          placeholder="Cuéntanos tu caso"
        ></textarea>
      </div>

      <div>
        <div *ngIf="!user" class="form-group form-check">
          <input
            type="checkbox"
            id="gdpr_accepted"
            class="form-check-input"
            formControlName="gdpr_accepted"
          />
          <label for="gdpr_accepted" class="form-check-label"
            >He leído y acepto la
            <a [routerLink]="['/politica-de-cookies']" target="_blank"
              >política de privacidad</a
            >.</label
          >
          <div
            *ngIf="
              contactForm.get('gdpr_accepted').invalid &&
              (contactForm.get('gdpr_accepted').dirty ||
                contactForm.get('gdpr_accepted').touched)
            "
          >
            <div
              class="error-message"
              *ngIf="contactForm.get('gdpr_accepted').errors.requiredTrue"
            >
              Debe aceptar la política de privacidad.
            </div>
          </div>
        </div>
        <div
          *ngIf="!user || user.customer.enley_accepted == false"
          class="form-group form-check"
        >
          <input
            type="checkbox"
            id="enley_accepted"
            class="form-check-input second-checkbox"
            formControlName="enley_accepted"
          />
          <label for="enley_accepted" class="form-check-label"
            >Acepto recibir comunicaciones comerciales de Enley que puedan ser
            de mi interés, con la posibilidad de revocar mi consentimiento en
            cualquier momento.</label
          >
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary submit-button"
        [disabled]="contactForm.invalid"
      >
        Contactar
      </button>
    </form>
  </ng-container>
  <ng-container *ngIf="isSubmitted">
    <h1 class="thank-you"><b>¡Gracias!</b></h1>
    <div class="thanks-div">
      <p>Te hemos <b>enviado un correo</b> a {{ email }}</p>
      <p>
        Uno de nuestros abogados se pondrá en contacto contigo lo antes posible.
      </p>
    </div>
  </ng-container>
</mat-dialog-content>
