<div
  *ngIf="information.description"
  class="info_block"
  (click)="dontToggle($event)"
>
  <div class="image-info" (click)="openModal($event)"></div>
  <ng-container *ngIf="this.open === true">
    <div id="modal">
      <div class="modal_body">
        <span (click)="closeModal($event)">
          <img src="assets/img/close-notification.svg" alt="" width="20px"
        /></span>

        <p [innerHTML]="this.information.description"></p>
        <ng-container *ngIf="this.image">
          <img
            style="max-width: 290px; max-height: 1000px"
            src="{{ this.image }}"
          />
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
