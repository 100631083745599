import {Contrato} from './contrato';
import {Good} from './goods';
import {Address, NationalId} from './user';

export class ConstitutionPerson {
  public juridical_person_id: number;
  public juridical_person: number;
  public contribution_base_table_id: number;
  public monthly_payment: number;
  public contribution_base_type: string;
  public contribution_base: number;
  public contribution_base_discount: boolean;

  constructor(
    public id?: number,
    public appellative?: string,
    public first_name?: string,
    public last_name?: string,
    public second_last_name?: string,
    public address?: Address,
    public national_id?: NationalId,
    public spouse_first_name?: string,
    public spouse_last_name?: string,
    public spouse_second_last_name?: string,
    public spouse_national_id?: NationalId,
    public spouse_appellative?: string,
    public show_detail?: boolean,
    public show_partner?: boolean,
    public show_admin?: boolean,
    public added?: boolean,
    public is_married?: boolean,
    public administrator?: boolean,
    public partner?: boolean,
    public good?: Good[],
    public society?: boolean,
    public nationality?: string,
    public birthday?: Date,
    public matrimonial_regime?: string,
    public marital_status?: string,
    public is_juridic_person?: boolean,
    public gestion_social_security?: boolean,
    public social_security_number?: string,
    public obtain_social_number?: boolean,
    public mutual?: string,
    public iban?: string,
    public autonomous_regime?: string,
    public social_security_address?: Address,
    public father_name?: string,
    public mother_name?: string,
    public show_obtain_social_number?: boolean,
    public is_eighteen?: boolean,
    public minor_representative?: MinorRepresentative,
    public CIF?: string,
    public contribution_total?: string,
    public stake?: string,
    public error?: boolean,
    public show_personal_information?: boolean,
    public show_address_information?: boolean,
    public same_address_domicilio?: boolean,
    public showInfo?: boolean,
    public showPartnerInfo?: boolean,
    public showDomicilio?: boolean,
    public enley_afiliate_autonomous?: boolean,
    public email?: string,
    public participation_number?: number,
    public phone_number?: string,
    public companies?: Company[]
  ) {}
}

export class MinorRepresentative {
  public nationality: string;
  public first_name: string;
  public last_name: string;
  public second_last_name: string;
  public national_id: NationalId;
  public appellative: string;
  public marital_status: string;
  public address: Address;
  public birthday: Date;
  public social_security_number: string;
  constructor() {
    this.address = new Address();
    this.national_id = new NationalId();
  }
}
export class JuridicalPerson {
  public certificate: File;
  public is_salable: boolean = false;
  public contract_id: number | undefined;
  public added: boolean;
  public contribution_base_table_id: number;
  public monthly_payment: number;
  public contribution_base_type: string;
  public contribution_base: number;

  constructor(
    public id?: number,
    public beneficiary_id?: number,
    public social_denomination_1?: string,
    public social_denomination_2?: string,
    public social_denomination_3?: string,
    public social_denomination_4?: string,
    public social_denomination_5?: string,
    public administration_type?: string,
    public retribution_percentage?: number,
    public retribution_type?: string,
    public description?: string,
    public CNAE_main_activity?: string,
    public CNAE?: CNAEJuridicalPerson[],
    public IAE?: IAEJuridicalPerson[],
    public activity_center?: Address,
    public registered_office?: Address,
    public same_address?: boolean,
    public social_denomination_type?: string,
    public activity_afectation?: boolean,
    public afectation_types?: string,
    public start_activity_date?: Date,
    public sign_date?: Date,
    public needs_roi?: boolean,
    public status?: string,
    public start_activity_date_type?: string,
    public constitution_date?: Date,
    public admin_gets_paid?: boolean,
    public showInfo?: boolean,
    public showPartnerInfo?: boolean,
    public showDomicilio?: boolean,
    public same_address_domicilio?: boolean,
    public iban?: string,
    public autonomous_regime?: string,
    public volume?: string,
    public sheet?: string,
    public page?: string,
    public register?: string,
    public CIF?: string,
    public brand_class?: number[],
    public name?: string,
    public logo?: string,
    public logo_file?: File,
    public origin?: string,
    public register_type?: string,
    public invoice_juridical_person_id?: number
  ) {}
}

export class PhysicalPerson {
  constructor(
    public nationality?: string,
    public first_name?: string,
    public last_name?: string,
    public second_last_name?: string,
    public national_id?: NationalId,
    public address?: Address,
    public origin?: string,
    public email?: string,
    public phone_number?: string
  ) {}
}

export class JuridicalPersonTemp {
  public juridical_person_id: number;
  public juridical_person: number;
  public contribution_base_table_id: number;
  public monthly_payment: number;
  public contribution_base_type: string;
  public contribution_base: number;
  public contribution_base_discount: boolean;

  constructor(
    public id?: number,
    public CIF?: string,
    public social_denomination?: string,
    public constitution_date?: Date,
    public registered_date?: Date,
    public social_address?: Address,
    public legal_representative?: PhysicalPerson,
    public representative_type?: string,
    public is_juridic_person?: boolean,
    public show_detail?: boolean,
    public added?: boolean,
    public good?: Good[],
    public contribution_total?: string,
    public stake?: string,
    public error?: boolean,
    public show_personal_information?: boolean,
    public show_address_information?: boolean,
    public same_address_domicilio?: boolean,
    public showInfo?: boolean,
    public administrator?: boolean,
    public social_security_number?: string,
    public obtain_social_number?: boolean,
    public mutual?: string,
    public iban?: string,
    public autonomous_regime?: string,
    public enley_afiliate_autonomous?: boolean,
    public father_name?: string,
    public mother_name?: string,
    public social_security_address?: Address,
    public gestion_social_security?: boolean,
    public show_obtain_social_number?: boolean,
    public participation_number?: number,
    public companies?: Company[]
  ) {}
}

export class Company {
  constructor(
    public id?: number,
    public social_denomination?: string,
    public CIF?: string,
    public province?: string,
    public social_capital?: number,
    public update_date?: Date,
    public juridical_person_id?: number,
    public constitution_person_id?: number,
    public belongs_enley?: boolean
  ) {}
}
export class CNAE {
  constructor(
    public code: string,
    public title: string,
    public is_main_cnae: boolean
  ) {}
}

export class IAE {
  constructor(
    public code: string,
    public denomination: string,
    public is_main_iae: boolean
  ) {}
}

export class CNAEJuridicalPerson {
  constructor(
    public CNAE?: string,
    public is_main_cnae?: boolean,
    public juridical_person_id?: number
  ) {}
}

export class IAEJuridicalPerson {
  constructor(
    public IAE?: string,
    public is_main_iae?: boolean,
    public juridical_person_id?: number
  ) {}
}

export class FilterCNAE {
  constructor(public query?: string) {}
}

export class FilterIAE {
  constructor(public query?: string) {}
}

export class RecommendationJuridicalPerson {
  constructor(public product?: string, public confidence?: number) {}
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
