/* SERVICIOS PARA ANGULAR */
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { globalApi } from '../global';
import {
  Cost,
  Payment,
  PaymentPrices,
  TransferPayment,
} from 'src/app/models/payment';
import { CurrentPaymentStep } from 'src/app/models/contrato';
import { Card } from 'src/app/models/card';
import { PartnerAdminComponent } from 'src/app/contracts/partner-admin/partner-admin.component';
import { log } from 'console';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PaymentServices {
  public url: string;
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private http: HttpClient
  ) {
    this.url = globalApi.url;
  }

  paymentService(step, contract_id): Observable<any> {
    let params = { step: step };
    return this.http.post(
      this.url + 'contract/' + contract_id + '/contracted_product_services/',
      params
    );
  }

  checkVersion(idContract, payment): Observable<Payment> {
    return this.http.post<Payment>(
      this.url + 'contract/' + idContract + '/payment/',
      payment
    );
  }

  initiateAuthentication(idContract, payment: Payment): Observable<Payment> {
    return this.http.put<Payment>(
      this.url + 'contract/' + idContract + '/payment_auth/' + payment.id + '/',
      payment
    );
  }

  transferPayment(
    idContract: Number,
    transferPayment: TransferPayment
  ): Observable<TransferPayment> {
    return this.http.post<TransferPayment>(
      this.url + 'contract/' + idContract + '/payment/transfer',
      transferPayment
    );
  }

  putFile(
    transferPayment: TransferPayment,
    fileToUpload?: File
  ): Observable<TransferPayment> {
    const endpoint = this.url + 'payment_transfer/' + transferPayment.id + '/';
    let headers = new HttpHeaders().set('Accept-Language', 'en');

    var formData: FormData = new FormData();
    formData = this.createFormData(transferPayment, formData);
    if (fileToUpload) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }
    return this.http.patch<TransferPayment>(endpoint, formData, {
      headers: headers,
    });
  }

  private createFormData(object, formData) {
    const excludeKeys = [
      'file',
      'amount',
      'step',
      'payment_date',
      'concept',
      'type',
      'physical_person.first_name',
      'physical_person.last_name',
      'physical_person.second_last_name',
      'physical_person.nationality',
    ];

    const appendFormData = (key, value) => {
      if (value instanceof Object && !(value instanceof Date)) {
        for (let nestedKey in value) {
          if (value[nestedKey] != undefined) {
            const fullKey = `${key}.${nestedKey}`;
            if (!excludeKeys.includes(fullKey)) {
              formData.append(fullKey, value[nestedKey].toString());
            }
          }
        }
      } else if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else {
        if (value != undefined && !excludeKeys.includes(key)) {
          formData.append(key, value.toString());
        }
      }
    };

    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        appendFormData(key, object[key]);
      }
    }

    return formData;
  }

  getTransferPayment(idContract: Number): Observable<PaymentPrices> {
    return this.http.get<PaymentPrices>(
      this.url + 'contract/' + idContract + '/payment/transfer'
    );
  }

  getCurrentStep(idContract: Number): Observable<CurrentPaymentStep> {
    return this.http.get<CurrentPaymentStep>(
      this.url + 'contract/' + idContract + '/payment_step/'
    );
  }

  patchTransferPayment(
    idContract: Number,
    transferPayment: TransferPayment
  ): Observable<TransferPayment> {
    return this.http.patch<TransferPayment>(
      this.url +
        'contract/' +
        idContract +
        '/payment/transfer/' +
        transferPayment.id +
        '/',
      transferPayment
    );
  }

  postCost(idContract: Number, cost: Cost): Observable<Cost> {
    return this.http.post<Cost>(
      this.url + 'contract/' + idContract + '/cost/',
      cost
    );
  }

  getCosts(idContract: Number): Observable<Cost[]> {
    return this.http.get<Cost[]>(
      this.url + 'contract/' + idContract + '/cost/'
    );
  }

  putCost(idContract: Number, cost: Cost): Observable<Cost> {
    return this.http.patch<Cost>(
      this.url + 'contract/' + idContract + '/cost/' + cost.id + '/',
      cost
    );
  }

  deleteCost(idContract: Number, idCost: Number): Observable<any> {
    return this.http.delete<any>(
      this.url + 'contract/' + idContract + '/cost/' + idCost + '/'
    );
  }

  getCostById(idCost: Number): Observable<Cost> {
    return this.http.get<Cost>(this.url + 'cost/' + idCost + '/');
  }

  getPayedPayment(idContract: Number): Observable<Payment[]> {
    return this.http.get<Payment[]>(
      this.url + 'contract/' + idContract + '/payment/'
    );
  }

  getAppleSesion(url) {
    return this.http.get<any>(this.url + 'apple/get_session?url=' + url);
  }

  authorizeApplePay(contract_id, payment_id, token) {
    return this.http.post<any>(
      this.url +
        'contract/' +
        contract_id +
        '/payment/' +
        payment_id +
        '/apple/authorize/',
      { token: token }
    );
  }

  getSuscriptions(idContract: Number) {
    return this.http.get<any>(
      this.url + 'contract/' + idContract + '/subscriptions/',
      {}
    );
  }

  getRecurrentPaymentById(id: string) {
    return this.http.get<any>(this.url + 'recurrent_payment/' + id + '/', {});
  }

  getScheduledPaymentsExecutions(idContract: Number) {
    return this.http.get<any>(
      this.url + 'contract/' + idContract + '/scheduled_payments_executions/',
      {}
    );
  }

  getCustomerCards(idCustomer: number) {
    return this.http.get<any>(
      this.url + 'customer/' + idCustomer + '/cards/',
      {}
    );
  }

  authorizeSinglePayment(params: {}) {
    return this.http.post<any>(
      this.url + 'payments/authorize_single_payment/',
      params
    );
  }

  initiateAuthenticationFlow(
    payment: Payment,
    contract_id: number,
    product_id: number
  ) {
    //TODO: el payment ha de tenir informat: id, step
    payment.action = 'initiate_auth';
    return this.initiateAuthentication(contract_id, payment).pipe(
      map((response_payment) => {
        if (response_payment.status == 'CHALLENGE_REQUIRED') {
          var form = document.createElement('form');
          form.setAttribute('method', 'POST');
          form.setAttribute('action', response_payment.challenge_request_url);
          form.setAttribute('target', 'hidden_iframe');

          var creqData = document.createElement('input');
          creqData.setAttribute('type', 'hidden');
          creqData.setAttribute('name', 'creq');
          //add creq object obtained from the server
          creqData.setAttribute('value', response_payment.encoded_creq);
          form.appendChild(creqData);
          document.body.appendChild(form);

          form.submit();
        } else {
          if (isPlatformBrowser(this._platformId)) {
            if (payment.step) {
              top.location.href =
                'contrato/' +
                contract_id +
                '/postpago/' +
                payment.step +
                '/' +
                product_id;
            } else {
              top.location.href =
                'contrato/' + contract_id + '/postpago/pago/' + product_id;
            }
          }
        }
      }),
      catchError((error) => {
        if (isPlatformBrowser(this._platformId)) {
          if (payment.step) {
            top.location.href =
              '/contrato/' +
              contract_id +
              '/pre-pago/' +
              payment.step +
              '/pago-error/' +
              Object.keys(error.error)[0];
          } else {
            top.location.href =
              '/contrato/' +
              contract_id +
              '/pre-pago/pago/' +
              payment.id +
              '/pago-error/' +
              Object.keys(error.error)[0];
          }
          return throwError(error);
        }
      })
    );
  }

  public acsMethodUrl(acs_data: any) {
    var form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', acs_data.method_url);
    form.setAttribute('target', 'hidden_iframe');

    var threeDSMethodData = document.createElement('input');
    threeDSMethodData.setAttribute('type', 'hidden');
    threeDSMethodData.setAttribute('name', 'threeDSMethodData');
    // add the JSON object returned by Global Payments
    threeDSMethodData.setAttribute('value', acs_data.method_data);
    form.appendChild(threeDSMethodData);
    document.body.appendChild(form);

    form.submit();
  }

  updateRecurrentPayment(
    idContract: Number,
    idRecurrentPayment: string,
    params: {}
  ) {
    return this.http.put<any>(
      this.url +
        'contract/' +
        idContract +
        '/recurrent_payment/' +
        idRecurrentPayment +
        '/',
      params
    );
  }

  renewSubscription(idSubscription: string, data: {}) {
    return this.http.post<any>(
      this.url + 'renew_subscription/' + idSubscription + '/',
      data
    );
  }

  getConfiguredDisbursements(idContract: Number) {
    return this.http.get<any>(this.url + 'get_disbursements/' + idContract, {});
  }

  postDisbursement(cost_id: number, payment_id: String) {
    return this.http.post<any>(this.url + 'disbursement/', {
      cost_id: cost_id,
      payment_id: payment_id,
    });
  }

  download_prof_of_payment(transfer) {
    this.get_proof_of_payment_file(transfer).subscribe((blob) => {
      const parts = transfer.file.split('.');
      let extension = parts.length > 1 ? parts.pop() || '' : '';

      this.getMimeType('.' + extension).subscribe((response) => {
        console.log(extension);
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: response });

        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = transfer.file.split('/').pop([0]);
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
    });
  }

  get_proof_of_payment_file(transfer) {
    return this.http.get(this.url + 'payment/' + transfer.id + '/file/', {
      responseType: 'blob',
    });
  }

  getMimeType(extension): Observable<any> {
    return this.http.get('/assets/file-extension-to-mime-types.json').pipe(
      map((item) => {
        return item[extension];
      })
    );
  }
}
