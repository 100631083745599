import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductCategoryGenericProductModalComponent } from '../../../generic-product-modal/generic-product-modal.component';

@Component({
  selector: 'app-productcategory-agency-table-th',
  templateUrl: './th.component.html',
  styleUrl: './th.component.css',
})
export class ProductCategoryAgencyTableThComponent {
  @Input() tier = {};

  constructor(private dialog: MatDialog) {}

  openGenericProductModal() {
    //TODO: ADD PRODUCT
    this.dialog.open(ProductCategoryGenericProductModalComponent, {
      panelClass: 'dialog-with-overflow',
      maxWidth: '100vw',
      maxHeight: '70vh',
      data: { product: null },
    });
  }
}
