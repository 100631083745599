import {Component, Input, OnInit} from '@angular/core';
import {Product} from 'src/app/models/products';

@Component({
  selector: 'app-product-includes',
  templateUrl: './product-includes.component.html',
  styleUrls: ['./product-includes.component.css'],
})
export class ProductIncludesComponent implements OnInit {
  public productTitle: string;
  @Input() product: Product;

  ngOnInit(): void {
    this.productTitle = INCLUDES_TITLE_PRODUCT_MAPPING[this.product.url];
  }
}

const INCLUDES_TITLE_PRODUCT_MAPPING = {
  ['divorcios-express-online']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos tu <b>divorcio online</b>',
  ['gestion-de-herencias']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos tu <b>herencia</b>',
  ['concursos-de-acreedores']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos tu <b>concurso de acreedores</b>',
  ['compraventa-inmuebles']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos tu <b>contrato de compraventa</b>',
  ['constitucion-sociedad']:
    'Desde <span style="font-family: quicksand;">enley</span>, constituimos tu <b>sociedad</b>',
  ['compra-sociedades']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos tu <b>venta de sociedades</b>',
  ['testamento-notarial']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos tu <b>testamento notarial</b>',
  ['pre-concursos-acreedores']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos tu <b>preconcurso de acreedores</b>',
  ['ley-segunda-oportunidad']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos la <b>cancelación de tus deudas</b>',
  ['patentes-y-marcas']:
    'Desde <span style="font-family: quicksand;">enley</span>, <b>registramos tu marca en España y Europa,</b> estés donde estés',
  ['tramitacion-nie']:
    'Desde <span style="font-family: quicksand;">enley</span>, gestionamos la <b>obtención de tu NIE</b>',
};
