import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductsServices } from '../services/products.services';

@Injectable({
  providedIn: 'root',
})
export class PrivateAreaResolver {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (isPlatformBrowser(this.platformId) && environment.production) {
      window.location.href = '/area-privada';
    }
    return 'area-privada';
  }
}
