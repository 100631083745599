import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceSubscriptions } from 'src/app/models/invoice';
import { InvoiceService } from 'src/app/services/invoices.service';
import { FilterInvoiceFormService } from 'src/app/services/invoice-filter.service';
import { ScheduledServices } from 'src/app/services/payments/scheduled.services';

@Component({
  selector: 'app-invoice-payment-price',
  templateUrl: './invoice-payment-price.component.html',
  styleUrls: ['./invoice-payment-price.component.css'],
})
export class InvoicePaymentPriceComponent implements OnInit {
  @Input() invoice: InvoiceSubscriptions;
  @Input() payment_already_paid: boolean;
  @Output() public outputEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public outputResponse: EventEmitter<any> = new EventEmitter<any>();
  public paymentAmount: FormGroup;
  constructor(
    private fb: FormBuilder,
    private scheduledService: ScheduledServices,
    private invoiceService: InvoiceService,
    private invoiceFilterService: FilterInvoiceFormService
  ) {}

  ngOnInit(): void {
    this.paymentAmount = this.fb.group({
      amount: [this.invoice.amount, [Validators.required]],
    });
  }

  closePopUp(): void {
    this.outputEvent.emit(false);
  }

  sendResponse(response): void {
    this.outputResponse.emit(response);
  }

  onSubmit() {
    if (this.paymentAmount.valid) {
      const filter = this.invoiceFilterService.filterInvoiceForm.getRawValue();
      this.scheduledService
        .authorizeScheduledPayment(
          this.invoice.id,
          this.payment_already_paid,
          this.paymentAmount.value.amount
        )
        .subscribe((response) => {
          if (
            (response && response.result == '00') ||
            this.payment_already_paid
          ) {
            console.log('response', response);
            this.invoiceService.getInvoices(filter).subscribe((invoices) => {
              this.invoiceService.setInvoices(invoices);
            });
          } else {
            console.log(response.message);
          }
          this.sendResponse(response);
          this.closePopUp();
        });
    }
  }
}
