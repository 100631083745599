import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { globalApi } from '../global';
import { Observable } from 'rxjs';
import { ContractedService, Contrato } from '../../models/contrato';

@Injectable()
export class PrepagoService {
  public url: string;
  public token: {};
  constructor(private _http: HttpClient) {
    this.url = globalApi.url;
  }

  createContractedService(
    idContract: number,
    contractedService: ContractedService
  ): Observable<ContractedService> {
    return this._http.post<ContractedService>(
      this.url + 'contract/' + idContract + '/contract_service/',
      contractedService
    );
  }

  deleteContractedService(idContract: number, idContractedService) {
    return this._http.delete<void>(
      this.url +
        'contract/' +
        idContract +
        '/contract_service/' +
        idContractedService +
        '/'
    );
  }
}
