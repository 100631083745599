<div id="header" #header></div>
<div class="loading" *ngIf="error500">
  <div class="serverErrorDiv">
    <span class="material-icons closePopUp" (click)="closePopUpServerError()">
      clear
    </span>
    <p class="closePopUpTitle">¡Lo sentimos, ha ocurrido un error!</p>
    <p class="closePopUpText">
      Ponte en contacto con nosotros en el <br />
      <a href="tel:91 417 25 85"><b>91 417 25 85</b></a> o
      <a href="mailto:info@enley.com"><b>info&#64;enley.com</b></a
      >. Gracias
    </p>
  </div>
</div>
<!--End Server Error pop up -->
