<ng-container
  *ngIf="
    config_pop_up &&
    (!is_coockies_accepted || is_coockies_accepted['mandatory'] !== 'yes')
  "
>
  <app-coockies-popup
    (closePopUpEvent)="closePopUp($event)"
    (closeFooterPopUpEvent)="closeFooterPopUp($event)"
  ></app-coockies-popup>
</ng-container>
<section
  id="showSeccion1"
  *ngIf="!is_coockies_accepted || is_coockies_accepted['mandatory'] !== 'yes'"
  class="seccionClose1"
  style="
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #00768b !important;
    z-index: 9000;
    color: white;
    padding: 10px;
    padding-bottom: 15px;
  "
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-10">
        <p class="cookies-text">
          Utilizamos cookies propias y de terceros para configurar y
          personalizar nuestra web, optimizar nuestros servicios, así como
          mostrarte publicidad personalizada basada en tus hábitos de navegación
          (por ejemplo, páginas visitadas). Puedes aceptar todas las cookies o
          gestionar tus preferencias en el panel de configuración. Consulta más
          información en
          <a
            href="politica-de-cookies"
            aria-label="Política de cookies"
            [routerLink]="['/politica-de-cookies']"
            class="cookies-link"
            >Política de Cookies
          </a>
        </p>
      </div>

      <div class="contentbuttonCookies" style="text-align: end">
        <button
          (click)="acceptCoockies()"
          type="button"
          class="accept-cookies-button"
        >
          ACEPTAR
        </button>
        <button
          class="config"
          type="button"
          (click)="configurationPopUp()"
          aria-label="Configurar"
          style="
            text-decoration: underline;
            color: white;
            cursor: pointer;
            background-color: initial;
            border: none;
          "
        >
          CONFIGURAR
        </button>
      </div>
    </div>
  </div>
</section>
<div id="footer" #footer></div>
