<div class="opinion">
  <app-stars [stars]="review.starRating"></app-stars>
  <div class="comment">"{{ review.comment }}"</div>
  <div class="published">
    <img
      alt="Google"
      width="25"
      height="25"
      src="./web-components-assets/img/google-logo.svg"
    />
    <div class="name">
      <span>Publicado en Google por</span>
      <b>{{ review.reviewer.displayName }}</b>
    </div>
  </div>
</div>
