import { Component, OnInit } from '@angular/core';
import { ProductsServices } from 'src/app/services/products.services';

@Component({
  selector: 'app-productcategory-agency-sociedades',
  templateUrl: './sociedades.component.html',
  styleUrl: './sociedades.component.css',
})
export class ProductCategoryAgencySociedadesComponent implements OnInit {
  public featuresByTier = [];
  public priceByTier = [];

  constructor(private productService: ProductsServices) {}
  ngOnInit(): void {
    this.productService.getProductServicesByTier('agency').subscribe((x) => {
      this.featuresByTier = x as any;
    });
    this.productService
      .getProductPricesSimpleByName('agency')
      .subscribe((x) => {
        x.forEach((tier) => {
          tier['price'] = (
            parseFloat(tier['total_price'].toString()) / 1.21
          ).toFixed(); //Need to rename and substract iva
          tier['name'] = tier['tier'].toLowerCase(); //Need to rename and set to lowercase
        });
        this.priceByTier = x as any;
      });
  }
}
