import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.sass'],
})
export class ToggleSwitchComponent {
  @Input() checked: boolean;
  @Output() stateSet = new EventEmitter<boolean>();

  setState(event: MouseEvent) {
    if (event.target instanceof HTMLInputElement) {
      this.stateSet.emit(event.target.checked);
    }
  }
}
