import {
  Component,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
  ElementRef,
  QueryList,
  ViewChildren,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { BlogPost } from 'src/app/models/blog-post';
import { ScreenSizeService } from 'src/app/services/screen-size.service';

@Component({
  selector: 'app-articles-home',
  templateUrl: './articles-home.component.html',
  styleUrls: ['./articles-home.component.css'],
})
export class ArticlesHomeComponent implements OnDestroy, AfterViewInit, OnInit {
  @Input() public backgroundColor: string = '';
  @Input() articles: BlogPost[] = [];
  public slideIndex = 1;
  public isMobile = false;
  private slideInterval: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private screenService: ScreenSizeService
  ) {}
  @ViewChildren('dot') dots!: QueryList<ElementRef<HTMLElement>>;
  @ViewChild('carouselContainer') carouselContainer!: ElementRef<HTMLElement>;

  truncate(value: string, limit: number = 25) {
    const words = value.split(/\s+/);
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    } else {
      return value;
    }
  }

  ngOnInit() {
    this.screenService.screenWidth.subscribe((screenWith) =>
      screenWith > 600 ? (this.isMobile = false) : (this.isMobile = true)
    );
  }

  ngAfterViewInit() {
    this.showSlides(this.slideIndex);
  }

  ngOnDestroy() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }

  currentSlide(n: number) {
    this.showSlides((this.slideIndex = n));
  }

  nextSlide() {
    console.log('next slide');
    console.log(this.slideIndex);
    this.slideIndex += 1;
    this.showSlides(this.slideIndex);
  }

  previousSlide() {
    console.log('previous slide');
    console.log(this.slideIndex);
    this.slideIndex -= 1;

    this.showSlides(this.slideIndex);
  }

  showSlides(n: number) {
    if (n > 6) {
      this.slideIndex = 6;
    }
    if (n < 1) {
      this.slideIndex = 1;
    }

    const offset = (this.slideIndex - 1) * 100;

    this.dots.forEach((dot) => {
      dot.nativeElement.classList.remove('active');
    });
    const container = this.carouselContainer.nativeElement;
    container.style.transform = `translateX(-${offset}%)`;

    this.dots.forEach((dot, index) => {
      if (index === this.slideIndex - 1) {
        dot.nativeElement.classList.add('active');
      }
    });
  }
}
