import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';
import { Contrato } from 'src/app/models/contrato';
import { Address, Customer } from 'src/app/models/user';
import { UsuarioServices } from 'src/app/services/usuario.services';
import { UtilsServices } from 'src/app/services/utils.services';

@Component({
  selector: 'app-registration-address',
  templateUrl: './registration-address.component.html',
  styleUrls: ['./registration-address.component.css'],
  providers: [UsuarioServices],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class RegistrationAddressComponent implements OnChanges {
  @Input() public control: any = null;
  @Input() public customer: Customer = null;
  @Input() public third_person: boolean = false;
  @Input() public contract: Contrato;

  public dataRegistrationProvinces: string[];
  public dataRegistrationMunicipality: string[] = [];
  public isLoadingRegistrationMunicipalities: boolean = false;

  @ViewChild('autoRegistrationMuncipalities') autoRegistrationMuncipalities;

  constructor(
    private _usuariosv: UsuarioServices,
    private _utilsv: UtilsServices
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this._usuariosv.getProvinces().subscribe((resp) => {
      if (resp) {
        this.dataRegistrationProvinces = resp;
      }
    });
    if (
      this.customer.registration_province &&
      this.customer.registration_municipality &&
      this.customer.address.province &&
      this.customer.address.municipality
    ) {
      if (
        this.customer.registration_municipality !=
        this.customer.address.municipality
      ) {
        this.customer.same_registration = false;
      } else {
        this.customer.same_registration = true;
      }
    }
  }

  selectEventRegistrationProvince() {
    if (this.customer.registration_municipality != undefined) {
      this.autoRegistrationMuncipalities.clear();
      this.customer.registration_municipality = undefined;
      this.dataRegistrationMunicipality = [];
    }
  }

  loadRegistrationMunicipalities(val) {
    this.isLoadingRegistrationMunicipalities = true;
    this._usuariosv
      .getMunicipalityWithP(this.customer.registration_province, val)
      .subscribe(
        (resp) => {
          this.dataRegistrationMunicipality = resp;
          this.isLoadingRegistrationMunicipalities = false;
        },
        (error) => {
          console.log('error');
          this.autoRegistrationMuncipalities.close();
          this.isLoadingRegistrationMunicipalities = false;
        }
      );
  }

  updateRegistration() {
    if (this.customer.same_registration) {
      this.customer.registration_municipality =
        this.customer.address.municipality;
      this.customer.registration_province = this.customer.address.province;

      if (this.contract && !this.customer.same_registration) {
        this.contract.court = this.customer.registration_province;
      }
    } else {
      this.customer.registration_municipality = null;
      this.customer.registration_province = null;
      setTimeout(() => {
        this._utilsv.ngAutocomplteUpdatePlaceholders();
      });
    }
  }
}
