/* SERVICIOS PARA ANGULAR */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalApi } from './global';
import { ContractedService, Contrato } from '../models/contrato';
import { User } from '../models/user';
import { Tracking } from '../models/tracking';
import { CvContact } from '../models/formcontacto';
@Injectable()
export class ContactoServices {
  public url: string;
  public token: {};
  constructor(private _http: HttpClient) {
    this.url = globalApi.url;
  }

  getAllContracts(): Observable<Contrato[]> {
    return this._http.get<Contrato[]>(this.url + 'contract/');
  }

  getUser(): Observable<User> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get<User>(this.url + 'user/', { headers: headers });
  }

  postContact(contact): Observable<Object> {
    let params = contact;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', 'es-es');
    return this._http.post(this.url + 'contact/', params, { headers: headers });
  }

  postCvContac(cvContact, cvFile?: File): Observable<CvContact> {
    const formData: FormData = new FormData();
    if (cvFile) {
      formData.append('cv', cvFile, cvFile.name);
    }
    formData.append('name', cvContact.name);
    formData.append('phone_number', cvContact.phone_number);
    if (cvContact.email) {
      formData.append('email', cvContact.email);
    }
    formData.append('message', cvContact.message);
    formData.append('gdpr_accepted', cvContact.gdpr_accepted);
    formData.append('enley_accepted', cvContact.enley_accepted);

    formData.forEach((value, key) => {
      console.log(key + ' ' + value);
    });

    return this._http.post<CvContact>(this.url + 'contact_cv/', formData);
  }
}
