import {isPlatformBrowser} from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  NgForm,
  NgModelGroup,
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ProductsID} from 'src/app/models/contrato';
import {Address, Customer} from 'src/app/models/user';
import {UsuarioServices} from 'src/app/services/usuario.services';
import {UtilsServices} from 'src/app/services/utils.services';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
  providers: [UsuarioServices],
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class AddressComponent implements OnInit, OnChanges {
  @Input() address: Address;
  @Input() public control: any = null;
  @Input() public nacionalidad: boolean = false;
  @Input() public customer: Customer = null;
  @Input() public label: string = 'Dirección actual';
  @Input() public postal_code_required: boolean;
  @Input() public numbers: boolean;
  @Input() public third_person: boolean;
  @Input() public idContract: number;
  @Input() public type: string = '';
  @Input() public information: string = '';
  @Input() public productId: number = ProductsID.divorce_product_id;
  @Input() public spanish: boolean = false;
  @Input() public six_month_resident: boolean = false;
  @Input() public surface: boolean = false;
  @Input() public invoice: boolean;
  @Input() public catastral_register_required: boolean;
  @Input() public show_street_type: boolean = true;
  @Input() public show_street: boolean = true;
  @Input() public show_choices: boolean = true;
  @Input() public show_catastral_boolean: boolean;
  @Input() public enabled: boolean = true;
  @Input() public partners_model: boolean = false;
  @Input() public show_country: boolean = true;
  @Input() public changeDistance: boolean = false;
  @Input() public agency_crm: boolean = false;
  @Input() public submitted: boolean = false;

  public dataCountry: string[];
  public dataProvinces: string[];
  public dataMunicipality: string[];
  public dataTypeStreet: any;
  public dataStreet: string[];
  public keyword: string;
  public objectDataStreet: any = new Object();
  public isLoadingMunicipalities: boolean = false;
  public disabled: boolean = false;
  public show_fields: boolean = false;
  public contract_addresses: Address[];
  public divorce_product_id: number = ProductsID.divorce_product_id;
  public show_direction: boolean = false;
  public streetTypeError: boolean = false;

  private lastMunicipalitySubscription: Subscription = null;
  private lastKeyStreetSubscription: Subscription = null;
  private lastZipCodeSubscription: Subscription = null;

  @ViewChild('autoCountries') autoCountries;
  @ViewChild('autoProvinces') autoProvinces;
  @ViewChild('autoStreetTypes') autoStreetTypes;
  @ViewChild('autoStreets') autoStreets;
  @ViewChild('autoMuncipalities') autoMuncipalities;

  constructor(
    private _usuariosv: UsuarioServices,
    private _utilsv: UtilsServices,
    private _el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      let w: any = window;
      let isChrome = w.chrome;
      if (isChrome) {
        this._el.nativeElement.setAttribute('autocomplete', 'off');
        this._el.nativeElement.setAttribute('autocorrect', 'off');
        this._el.nativeElement.setAttribute('autocapitalize', 'none');
        this._el.nativeElement.setAttribute('spellcheck', 'false');
      }
    }
    if (this.partners_model) {
      this.onClickNewAddress();
    } else {
      if (this.idContract) {
        this._usuariosv
          .getContractAddresses(this.idContract)
          .subscribe((result) => {
            this.contract_addresses = result; //.filter(item=>item.type==this.type)
            if (this.type == 'customer_2') {
              this.contract_addresses = this.contract_addresses.filter(
                (item) => item.type == 'customer'
              );
            } else if (this.type == 'coexistence_address') {
              this.contract_addresses = this.contract_addresses.filter(
                (item) => item.type == 'customer' || item.type == 'customer_2'
              );
            }
            this.contract_addresses.forEach((item, i) => {
              if (this.address && this.address.pk == item.pk) {
                this.address.choice = i;
              }
              if (item.type == 'coexistence_address') {
                item.alias = 'Dirección de convivencia';
              }
            });

            if (
              this.contract_addresses.filter(
                (item) => item.pk == this.address.pk
              ).length == 0 &&
              this.address.pk
            ) {
              this.address.choice = -1;
            }

            this.customer;
            if (this.address.choice == -1) {
              this.show_direction = true;
            }
          });
      }
    }
    if (!this.show_country) {
      this.address.country = 'España';
      this._usuariosv.getProvinces().subscribe((resp) => {
        this.dataProvinces = resp;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.address == null) {
      this.address = new Address();
    }

    if (this.spanish) {
      this.address.country = 'España';
    } else if (!this.show_country) {
      this.address.country = 'España';
    }
    this.customer;

    this._usuariosv.getCountries().subscribe((resp) => {
      this.dataCountry = ['España'].concat(
        resp.filter((country) => country != 'España').sort()
      );
    });
    // CARGA LA DATA DE LAS PROVINCIAS
    this._usuariosv.getProvinces().subscribe((resp) => {
      if (resp) {
        this.dataProvinces = resp;
      }
    });
    // CARGA LA DATA TIPO DE CALLE SE HACE UN FOR YA QUE EL RETORNO ES UN OBJETO JSON
    this._usuariosv.getTypeStreet().subscribe((resp) => {
      this.keyword = 'name';
      let propiedades = [];
      for (const propiedad in resp) {
        propiedades.push({id: propiedad, name: resp[propiedad]});
        this.dataTypeStreet = propiedades;
      }
      this.objectDataStreet = propiedades.filter(
        (item) => item.id == String(this.address.street_type)
      )[0];
    });

    if (this.address.province) {
      this._usuariosv
        .getMunicipality(this.address.province)
        .subscribe((resp) => {
          this.dataMunicipality = resp;
        });
    }
    if (
      this.address.street &&
      this.address.province &&
      this.address.municipality
    ) {
      this._usuariosv
        .getStreet(
          this.address.province,
          this.address.municipality,
          this.address.street_type
        )
        .subscribe((resp) => {
          this.dataMunicipality = resp;
        });
    }

    if (this.idContract && this.contract_addresses) {
      this.contract_addresses.forEach((item, i) => {
        if (this.address && this.address.pk == item.pk) {
          this.address.choice = i;
        }
        if (item.type == 'coexistence_address') {
          item.alias = 'Dirección de convivencia';
        }
      });

      if (
        this.contract_addresses.filter((item) => item.pk == this.address.pk)
          .length == 0 &&
        this.address.pk
      ) {
        this.address.choice = -1;
      }
    }
  }

  selectEventCountry(event) {
    if (this.address.province != undefined) {
      this.address.province = undefined;
      this.autoProvinces.clear();
      this.autoMuncipalities.clear();
      this.address.municipality = undefined;
      this.autoStreets.clear();
      this.address.street_type = undefined;
      this.autoStreetTypes.clear();
      this.autoStreets.clear();
      this.address.street = undefined;
      this.dataMunicipality = [];
      this.dataStreet = [];
    }
    if (event != 'España') {
      this.dataMunicipality = [];
      this.dataProvinces = [];
      this.dataMunicipality = [];
      this.dataStreet = [];
      this.customer.six_month_resident_spain = undefined;
    } else {
      this._usuariosv.getProvinces().subscribe((resp) => {
        this.dataProvinces = resp;
      });
    }
  }

  /*EVENTOS CLICK PARA LAS PROVINCIAS*/
  selectEventProvincia() {
    if (this.address.municipality != undefined) {
      this.autoMuncipalities.clear();
      this.address.municipality = undefined;
      this.autoStreets.clear();
      this.address.street_type = undefined;
      this.autoStreetTypes.clear();
      this.dataMunicipality = [];
      this.dataStreet = [];
      this.autoStreets.clear();
      this.address.street = undefined;
      this.dataStreet = [];
    }
  }

  loadMunicipie(val) {
    this.isLoadingMunicipalities = true;

    if (
      this.lastMunicipalitySubscription !== null &&
      typeof this.lastMunicipalitySubscription.unsubscribe === 'function'
    ) {
      this.lastMunicipalitySubscription.unsubscribe();
    }

    if (this.address.street_type != undefined) {
      this.autoStreets.clear();
      this.address.street_type = undefined;
      this.autoStreetTypes.clear();
      this.dataMunicipality = [];
      this.autoStreets.clear();
      this.address.street = undefined;
      this.dataStreet = [];
    }
    if (this.address.country == 'España') {
      this.lastMunicipalitySubscription = this._usuariosv
        .getMunicipalityWithP(this.address.province, val)
        .subscribe(
          (resp) => {
            this.dataMunicipality = resp;
            this.isLoadingMunicipalities = false;
          },
          (error) => {
            this.autoMuncipalities.close();
            this.isLoadingMunicipalities = false;
          }
        );
    }
  }

  selectedTypeStreet(val) {
    let street_type = val.id;
    this.address.street_type = street_type;
    if (this.address.street != undefined) {
      this.autoStreets.clear();
      this.dataStreet = [];
      this.address.street = undefined;
    } else {
      setTimeout(() => {}, 500);
    }
  }

  selectKeyStreet(val?) {
    if (val == undefined) {
      val = '';
    }

    if (
      this.lastKeyStreetSubscription !== null &&
      typeof this.lastKeyStreetSubscription.unsubscribe === 'function'
    ) {
      this.lastKeyStreetSubscription.unsubscribe();
    }

    if (this.address.country == 'España') {
      //Todas las opciones no solo navarra
      this.lastKeyStreetSubscription = this._usuariosv
        .getStreetForNavarra(
          this.address.province,
          this.address.municipality,
          this.objectDataStreet.id,
          val
        )
        .subscribe(
          (resp) => {
            this.dataStreet = resp;
            this.address.street_type = this.objectDataStreet.id;
          },
          (error) => {
            this.autoStreets.close();
          }
        );
    }
  }

  onChangeNumber(val) {
    if (
      this.lastZipCodeSubscription !== null &&
      typeof this.lastZipCodeSubscription.unsubscribe === 'function'
    ) {
      this.lastZipCodeSubscription.unsubscribe();
    }

    this.lastZipCodeSubscription = this._usuariosv
      .getZipCode(
        this.address.country,
        this.address.province,
        this.address.municipality,
        this.address.street_type,
        this.address.street,
        this.address.number
      )
      .subscribe(
        (response) => {
          if (response && !isNaN(response)) {
            this.address.postal_code = response;
          }
        },
        (error) => {
          console.log('error');
        }
      );
  }

  onClickAddress(address, index) {
    this.show_direction = false;
    if (this.address.choice == index) {
      for (var prop in this.address) {
        if (prop != 'choice') {
          this.address[prop] = address[prop];
        }
      }
      this.show_fields = true;

      this._usuariosv.getTypeStreet().subscribe(
        (resp) => {
          this.keyword = 'name';
          let propiedades = [];
          for (const propiedad in resp) {
            propiedades.push({id: propiedad, name: resp[propiedad]});
            this.dataTypeStreet = propiedades;
          }
          this.objectDataStreet = propiedades.filter(
            (item) => item.id == String(this.address.street_type)
          )[0];
        },
        (error) => {
          if (error) {
            console.log('error');
          }
        }
      );
    }
  }

  onClickNewAddress() {
    this.show_direction = true;

    if (this.autoCountries) {
      this.autoCountries.clear();
      this.address.country = undefined;
      this.autoProvinces.clear();
      this.address.province = undefined;
      this.autoMuncipalities.clear();
      this.address.municipality = undefined;
      this.autoStreets.clear();
      this.address.street_type = undefined;
      this.autoStreetTypes.clear();
      this.autoStreets.clear();
      this.address.street = undefined;
      this.dataMunicipality = [];
      this.dataStreet = [];
    }
    this.objectDataStreet = undefined;

    for (var prop in this.address) {
      if (prop != 'choice') {
        this.address[prop] = undefined;
      }
    }
    if (this.address.choice == -1) {
      this.show_fields = true;
      setTimeout(() => {}, 500);
    } else {
      this.show_fields = false;
    }
  }

  showDirectionInfo() {
    if (this.show_direction) {
      this.show_direction = false;
    } else {
      this.show_direction = true;
    }
  }

  preventNumberInputScroll(event: WheelEvent): void {
    event.preventDefault();
  }

  preventNumberInputArrows(event: KeyboardEvent): void {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }
}
