<section
  class="why-enley"
  [ngClass]="backgroundColor == 'gray' ? 'background-gray' : ''"
>
  <div>
    <h2 class="why-enley__main-title">¿Por qué elegir Enley?</h2>
    <p class="why-enley__subtitle">
      Unimos el conocimiento de abogados expertos con la rapidez y comodidad de
      la tecnología.
    </p>
    <app-why-enley-reason></app-why-enley-reason>
  </div>
</section>
