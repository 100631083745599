import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Pipe,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Product } from 'src/app/models/products';

@Component({
  selector: 'app-product-tier',
  templateUrl: './product-tier.component.html',
  styleUrls: ['./product-tier.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductTierComponent implements OnInit {
  @Input() product: Product;
  public showBasicContent: boolean = true;
  public showAdvancedContent: boolean = true;
  public showPremiumContent: boolean = true;
  public url: string = '/formulario-registro-ind-b/';
  public urlBasic: string;
  public urlAdvanced: string;
  public urlPremium: string;

  constructor(private sanitized: DomSanitizer) {}

  ngOnInit(): void {
    this.url += this.product.id;
    this.urlBasic = this.url + '?tier=basic';
    this.urlAdvanced = this.url + '?tier=advance';
    this.urlPremium = this.url + '?tier=premium';
  }

  transform(value) {
    if (value != undefined) {
      return this.sanitized.bypassSecurityTrustHtml(
        value
          .replace(/\{{productId}}/gi, this.product.id)
          .replace(
            /\{{product_price_without_tax}}/gi,
            (this.product.price / 1.21).toLocaleString('de-DE')
          )
          .replace(/\{{urlBasic}}/gi, this.urlBasic)
          .replace(/\{{urlAdvanced}}/gi, this.urlAdvanced)
          .replace(/\{{urlPremium}}/gi, this.urlPremium)
      );
    } else {
      return undefined;
    }
  }

  openDropwDown(type: string) {
    if (type == 'basic') {
      var element = document.getElementById('basic-details');
      this.showBasicContent = !this.showBasicContent;
      if (this.showBasicContent) {
        element.removeAttribute('open');
      } else {
        element.setAttribute('open', this.showBasicContent.toString());
      }
    } else if (type == 'advanced') {
      var element = document.getElementById('advanced-details');
      this.showAdvancedContent = !this.showAdvancedContent;
      if (this.showAdvancedContent) {
        element.removeAttribute('open');
      } else {
        element.setAttribute('open', this.showAdvancedContent.toString());
      }
    } else {
      var element = document.getElementById('premium-details');
      this.showPremiumContent = !this.showPremiumContent;
      if (this.showPremiumContent) {
        element.removeAttribute('open');
      } else {
        element.setAttribute('open', this.showPremiumContent.toString());
      }
    }
  }
}
