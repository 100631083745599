import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/models/products';
import { ContentType, setRegisterAnalytics } from 'src/app/models/register';
import { HeadService } from 'src/app/services/head.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Subscription } from 'rxjs';
import { BlogPost } from 'src/app/models/blog-post';
import { BlogRssService } from 'src/app/services/blog-rss.service';
import { OgConfigSeo } from 'src/app/models/seo/og-seo-config';
import { OgSeoService } from 'src/app/services/seo/og-seo.service';

@Component({
  selector: 'app-home-update',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [HeadService],
})
export class HomeUpdateComponent implements OnInit {
  public innerWidth: number;
  public divorceProduct: Product;
  public articlesSubscription: Subscription;
  public articles: BlogPost[] = [];
  @ViewChild('servicesComponent') servicesComponent!: ElementRef;

  faqs = FAQS_HOME;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private route: ActivatedRoute,
    private _blogService: BlogRssService,
    renderer: Renderer2,
    headService: HeadService,
    private analyticsService: AnalyticsService,
    private ogSeoService: OgSeoService
  ) {
    const head = headService.forRenderer(renderer);
    head.createElement('link', {
      rel: 'preload',
      as: 'image',
      href: 'assets/img/servicios-legales-online-mobile.webp',
    });
  }

  ngOnInit(): void {
    setRegisterAnalytics(
      this.route,
      this._platformId,
      this.analyticsService.getReferer(),
      ContentType.HOME
    );
    this.articlesSubscription = this._blogService
      .getProductPosts('')
      .subscribe((articles: BlogPost[]) => {
        this.articles = articles;
        (error) => {
          console.error('Error fetching articles:', error);
        };
      });

    this.setOgSeo();
  }

  setOgSeo() {
    const ogSeoConfig: OgConfigSeo = {
      title: 'Enley - abogados online',
      description:
        'Nuestro equipo de abogados expertos te asesora en tus trámites y problemas legales.',
    };

    this.ogSeoService.setOgSeoTitleAndDescription(ogSeoConfig);
  }
}

const FAQS_HOME = [
  {
    title: '¿Qué es Enley?',
    text_html:
      'Somos un <b>equipo multidisciplinar</b>, liderado por un grupo de abogados con una amplia experiencia profesional. Nuestro objetivo es <b>facilitar el acceso a los servicios legales</b> y hacerlos más rápidos, fáciles y sencillos.',
  },
  {
    title: '¿Cómo funciona Enley?',
    text_html:
      'Nuestro servicio es <b>fundamentalmente online</b>. A través de nuestros formularios y el asesosarmiento online con un abogado, recabamos la información esencial con la que empezar a trabajar en tu trámite.',
  },
  {
    title: '¿Por qué es tan barato respecto a los bufetes clásicos?',
    text_html:
      'Porque hemos desarrollado una <b>tecnología propia que nos permite automatizar los procesos</b> y, por tanto, hacerlos más baratos. ¿Por qué pagar a un abogado para que anote una información que puedes indicar tú por internet en un formulario?',
  },
  {
    title: '¿Vuestros abogados están colegiados?',
    text_html:
      'Sí. <b>Todos nuestros abogados están debidamente colegiados</b>.',
  },
  {
    title: '¿Cómo puedo saber cómo va mi trámite?',
    text_html:
      'Una vez que hayas iniciado tu trámite con nosotros, podrás seguir todo el proceso <b>desde tu Área Privada</b>. Además, siempre que se produzca algún cambio en el estado de tu trámite <b>te enviaremos un correo</b>. Igualmente, puedes contactarnos siempre que quieras a través de correo electrónico, teléfono o whatsapp.',
  },
  {
    title: 'Yo prefiero que me atiendan personalmente ¿podéis hacerlo?',
    text_html:
      'Sí. Si prefieres ser atendido por nuestros abogados de manera presencial, actualmente contamos con <b>oficinas en Madrid y Barcelona</b>. Si no te encuentras en ninguno de estos sitios, no te preocupes, siempre tendrás la posibilidad de concertar una videollamada con nosotros.',
  },
  {
    title: '¿Qué servicios incluyen vuestras tarifas?',
    text_html:
      '<b>Siempre tendrás indicado lo que incluyen nuestras tarifas</b>. No obstante, no te preocupes, en caso de que haya algún gasto adicional siempre te avisaremos antes de contratar el servicio.',
  },
  {
    title: '¿Puedo fraccionar los pagos?',
    text_html:
      '<b>Sí</b>. En Enley lo más importante eres tú y por eso queremos facilitarte las cosas adaptándonos a tus necesidades.',
  },
];
