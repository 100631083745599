<!-- gif -->
<div *ngIf="loadingContent">
  <div class="gif">
    <img
      class=""
      width="125px"
      height="125px"
      src="assets/img/loading-gif.gif"
      alt=""
    />
    <p class="cargandoText">Realizando el pago...</p>
  </div>
</div>
<!-- gif -->
<iframe
  id="iframe"
  name="iframe"
  frameborder="0"
  style="overflow: hidden; height: 100%; width: 100%"
  height="100%"
  width="100%"
></iframe>
