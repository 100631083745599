import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-stars-home',
  templateUrl: './stars-home.component.html',
  styleUrls: ['./stars-home.component.css'],
})
export class StarsHomeComponent implements OnInit, OnChanges {
  @Input() stars: number = 0;
  public max_stars: number = 5;
  public starsArray: {
    offsetFill: string;
    offsetEmpty: string;
    offsetFillEnd: number;
  }[] = [];
  private animationInterval: number = 20;

  ngOnInit(): void {
    this.initializeStarsArray();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stars && this.starsArray) {
      this.updateStars(this.stars);
    }
  }

  initializeStarsArray(): void {
    if (this.starsArray.length === 0) {
      for (let i = 0; i < this.max_stars; i++) {
        this.starsArray.push({
          offsetFill: '0%',
          offsetEmpty: '0%',
          offsetFillEnd: 100,
        });
      }
    }
  }

  async updateStars(starsToShow: number) {
    this.initializeStarsArray();

    const fullStars = Math.floor(starsToShow);
    const decimalPart = starsToShow - fullStars;

    if (fullStars < this.starsArray.length && decimalPart > 0) {
      this.starsArray[fullStars].offsetFillEnd = decimalPart * 100;
    }

    for (let i = 0; i < fullStars; i++) {
      if (this.starsArray[i]) {
        await this.animateStar(this.starsArray[i]);
      } else {
        console.log(`Star at index ${i} is undefined`);
      }
    }

    if (fullStars < this.starsArray.length && decimalPart > 0) {
      if (this.starsArray[fullStars]) {
        await this.animateStar(this.starsArray[fullStars]);
      } else {
        console.log(`Star at index ${fullStars} is undefined`);
      }
    }
  }

  animateStar(star): Promise<void> {
    return new Promise((resolve) => {
      if (!star) {
        console.log('Star is undefined');
        resolve();
        return;
      }

      let fill = 0;
      let empty = 20;
      const step = 5;

      star.offsetFill = `${fill}%`;
      star.offsetEmpty = `${empty}%`;

      const timer = setInterval(() => {
        fill += step;
        empty += step;

        star.offsetFill = `${fill}%`;
        star.offsetEmpty = `${empty}%`;

        if (fill >= star.offsetFillEnd) {
          clearInterval(timer);
          resolve();
        }
      }, this.animationInterval);
    });
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
