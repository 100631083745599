import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  InjectionToken,
  Optional,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { Title } from '@angular/platform-browser';
import { APP_URL } from './apptemplate/header/url';
import { environment } from 'src/environments/environment';

declare let gtag: Function;
declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'projectjudicial';
  private meta: any;

  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public updates: SwUpdate,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document,
    private authService: AuthService,
    private titleService: Title,
    @Optional() @Inject(APP_URL) private url
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.dataLayer = window.dataLayer || [];
      if (this.authService.getCookie('is_coockies_accepted')) {
        try {
          var cookies = JSON.parse(
            this.authService.getCookie('is_coockies_accepted')
          );
        } catch (error) {
          console.error('Error parsing cookies');
          this.authService.delete_cookie('is_coockies_accepted');
        }
        if (cookies) {
          if (
            cookies['marketing'] == 'yes' &&
            cookies['analytic'] == 'yes' &&
            cookies['mandatory'] == 'yes'
          ) {
            gtag('consent', 'default', {
              ad_storage: 'granted',
              analytics_storage: 'granted',
              ad_user_data: 'granted',
              ad_personalization: 'granted',
            });
          } else if (
            cookies['marketing'] == 'yes' &&
            cookies['analytic'] == 'no' &&
            cookies['mandatory'] == 'yes'
          ) {
            gtag('consent', 'default', {
              ad_storage: 'granted',
              analytics_storage: 'denied',
              ad_user_data: 'granted',
              ad_personalization: 'granted',
            });
          } else if (
            cookies['marketing'] == 'no' &&
            cookies['analytic'] == 'yes' &&
            cookies['mandatory'] == 'yes'
          ) {
            gtag('consent', 'default', {
              ad_storage: 'denied',
              analytics_storage: 'granted',
              ad_user_data: 'denied',
              ad_personalization: 'denied',
            });
          } else {
            gtag('consent', 'default', {
              ad_storage: 'denied',
              analytics_storage: 'denied',
              ad_user_data: 'denied',
              ad_personalization: 'denied',
            });
          }
        }
      }

      this.injectScripts();
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check hostname !
        if (
          isPlatformBrowser(this.platformId) &&
          document.location.hostname.search('www.enley.com') !== -1
        ) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'pageview',
          });
        }
      }
    });

    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`
          );
          this.updateApp();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.error(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        filter((event: ActivationEnd) => event.snapshot.firstChild == null),
        map((event: ActivationEnd) => event.snapshot.data)
      )
      .subscribe(({ data, titulo }) => {
        if (data == undefined) {
          data = { titulo: 'Enley', description: '' };
        }
        this.titleService.setTitle(`${data.titulo} - Enley`);

        this.renderer.setAttribute(
          _document.querySelector('meta[name="description"]'),
          'content',
          data.description
        );

        this.renderer.setAttribute(
          _document.querySelector('meta[rel="canonical"]'),
          'href',
          'https://' + environment.frontUrl + this.router.url
        );

        // if the crawler reaches a page with noindex it stops
        // therefore, it is not necessary to remove the noindex tag
        if (data.noindex) {
          this.meta = this.renderer.createElement('meta');
          this.renderer.setAttribute(this.meta, 'name', 'robots');
          this.renderer.setAttribute(this.meta, 'content', 'noindex');
          this.renderer.appendChild(this._document.head, this.meta);
        }
      });
  }

  updateApp() {
    this.updates.activateUpdate().then(() => {
      console.log('New version activated');
      document.location.reload();
    });
  }

  injectScripts() {
    if (
      document.location.hostname.search('www.enley.com') !== -1 ||
      document.location.hostname.search('abogados.enley.com') !== -1
    ) {
      // Google Tag Manager
      const gtagInitScript = this.renderer.createElement('script');
      gtagInitScript.defer = ' ';
      gtagInitScript.async = ' ';
      gtagInitScript.text = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5X6M479');
  `;
      this.renderer.appendChild(this._document.head, gtagInitScript);
    }
  }
}
