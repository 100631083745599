/* SERVICIOS PARA ANGULAR */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalApi } from './global';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  stepTracking,
  SubStepTrackingAudit,
  Tracking,
} from '../models/tracking';
import { filter, map } from 'rxjs/operators';
import { Document } from '../models/document';

@Injectable()
export class DocumentsService {
  public url: string;
  public token: {};
  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.url = globalApi.url;
  }

  getFiles(idContract): Observable<Document[]> {
    const endpoint = this.url + 'contract/' + idContract + '/document/';
    return this._http.get<Document[]>(endpoint);
  }
  donwnloadFile(idContract, idDocument): Observable<any> {
    const endpoint =
      this.url + 'contract/' + idContract + '/document/' + idDocument + '/ ';
    let headers = new HttpHeaders()
      .set('Accept-Language', 'es-es')
      .set('Authorization', `Bearer ${this.token}`);
    return this._http.get(endpoint, { responseType: 'blob' });
  }

  getMimeType(extension): Observable<any> {
    return this._http.get('/assets/file-extension-to-mime-types.json').pipe(
      map((item) => {
        return item[extension];
      })
    );
  }
}
