import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {globalApi, globalFront} from './global';
import {ControlContainer, NgForm} from '@angular/forms';
import {ProductsID} from '../models/contrato';
import {DomSanitizer} from '@angular/platform-browser';
import {ConstitutionPerson, getAge} from 'src/app/models/constitution';
import * as e from 'express';
import {type} from 'os';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class UtilsServices {
  public url: string;
  public frontUrl: string;
  public token: {};
  private sanitized: DomSanitizer;
  constructor(
    private _http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.url = globalApi.url;
    this.frontUrl = globalFront.url;
  }

  scrollTo(el: Element): void {
    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset - 150;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }

  scrollToError(): void {
    let firstElementWithError = document.querySelectorAll(
      'input.ng-invalid, select.ng-invalid, ng-autocomplete.ng-invalid'
    )[0];
    this.scrollTo(firstElementWithError);
  }

  async scrollIfFormHasErrors(form: NgForm): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }

  parsePhoneNumber(phoneNumber: string): any {
    console.log('parsePhoneNumber: ', 1);

    var rx =
      /\+(?:998|996|995|994|993|992|977|976|975|974|973|972|971|970|968|967|966|965|964|963|962|961|960|886|880|856|855|853|852|850|692|691|690|689|688|687|686|685|683|682|681|680|679|678|677|676|675|674|673|672|670|599|598|597|595|593|592|591|590|509|508|507|506|505|504|503|502|501|500|423|421|420|389|387|386|385|383|382|381|380|379|378|377|376|375|374|373|372|371|370|359|358|357|356|355|354|353|352|351|350|299|298|297|291|290|269|268|267|266|265|264|263|262|261|260|258|257|256|255|254|253|252|251|250|249|248|246|245|244|243|242|241|240|239|238|237|236|235|234|233|232|231|230|229|228|227|226|225|224|223|222|221|220|218|216|213|212|211|98|95|94|93|92|91|90|86|84|82|81|66|65|64|63|62|61|60|58|57|56|55|54|53|52|51|49|48|47|46|45|44\D?1624|44\D?1534|44\D?1481|44|43|41|40|39|36|34|33|32|31|30|27|20|7|1\D?939|1\D?876|1\D?869|1\D?868|1\D?849|1\D?829|1\D?809|1\D?787|1\D?784|1\D?767|1\D?758|1\D?721|1\D?684|1\D?671|1\D?670|1\D?664|1\D?649|1\D?473|1\D?441|1\D?345|1\D?340|1\D?284|1\D?268|1\D?264|1\D?246|1\D?242|1)\D?/;
    return {
      number: phoneNumber.replace(rx, ''),
      dialCode: rx.exec(phoneNumber)[0],
    };
  }

  ngAutocomplteUpdatePlaceholders(not_apply?) {
    if (isPlatformBrowser(this.platformId)) {
      if (!not_apply) {
        let autocomplete = document.getElementsByTagName('ng-autocomplete');
        for (var i = 0; i < autocomplete.length; i++) {
          let div = autocomplete[i].children[0].children[0];
          if (autocomplete[i].children[0].children[0].children.length < 2) {
            let input = autocomplete[i].children[0].children[0].children[0];
            div.classList.add('form-floating');
            input.setAttribute('name', autocomplete[i].id);
            input.setAttribute('placeholder', ' ');
            input.setAttribute('autocomplete', 'nothing');
            input.classList.add('form-control');
            input.insertAdjacentHTML(
              'afterend',
              '<label for=' +
                autocomplete[i].id +
                '" class="floating-label">' +
                autocomplete[i].id +
                '</label>'
            );
          }
        }
      }
    }
  }

  ngAutocompltePlaceholders() {
    if (isPlatformBrowser(this.platformId)) {
      let autocomplete = document.getElementsByTagName('ng-autocomplete');
      for (var i = 0; i < autocomplete.length; i++) {
        let div = autocomplete[i].children[0].children[0];
        let input = autocomplete[i].children[0].children[0].children[0];
        div.classList.add('form-floating');
        input.setAttribute('name', autocomplete[i].id);
        input.setAttribute('placeholder', ' ');
        input.setAttribute('autocomplete', 'nothing');
        input.classList.add('form-control');
        input.insertAdjacentHTML(
          'afterend',
          '<label for=' +
            autocomplete[i].id +
            '" class="floating-label">' +
            autocomplete[i].id +
            '</label>'
        );
      }
    }
  }

  ngAutocompltePlaceholdersBoxRegister() {
    if (isPlatformBrowser(this.platformId)) {
      let autocomplete = document.getElementsByTagName('ng-autocomplete');
      for (var i = 0; i < autocomplete.length; i++) {
        let div = autocomplete[i].children[0].children[0];
        let div_autocomplete = autocomplete[i].children[0].setAttribute(
          'style',
          'max-width: 100%!important;'
        );

        let input = autocomplete[i].children[0].children[0].children[0];
        div.classList.add('form-floating');
        input.setAttribute('name', autocomplete[i].id);
        input.setAttribute('placeholder', ' ');
        input.setAttribute(
          'style',
          'max-width: 100%!important; border: 0px!important'
        );
        input.setAttribute('autocomplete', 'nothing');
        input.classList.add('form-control');
        input.insertAdjacentHTML(
          'afterend',
          '<label for=' +
            autocomplete[i].id +
            '" class="floating-label box-register-label">' +
            autocomplete[i].id +
            '</label>'
        );
        let label = autocomplete[i].children[0].children[0].children[1];
        label.setAttribute(
          'style',
          'font-size: 14px!important; font-weight: 400!important; color: #5890bd!important; display: flex; align-items: center; padding-top: 0px; padding-bottom: 0px;'
        );
      }
    }
  }

  generateFormUrl(contractId, token, productId, email) {
    // TODO get url from db
    let generated_url;
    if (
      productId == ProductsID.divorce_product_id ||
      productId == ProductsID.notarized_divorce
    ) {
      generated_url =
        'https://' +
        this.frontUrl +
        '/contrato/' +
        contractId +
        '/formulario-convenio-regulador?token=' +
        token +
        '&email=' +
        email;
    } else if (productId == ProductsID.down_payment_id) {
      generated_url =
        'https://' +
        this.frontUrl +
        '/contrato/' +
        contractId +
        '/datos-compradores-vendedores?token=' +
        token +
        '&email=' +
        email;
    } else if (productId == ProductsID.buy_sell_contract) {
      generated_url =
        'https://' +
        this.frontUrl +
        '/contrato/' +
        contractId +
        '/datos-compradores-vendedores?token=' +
        token +
        '&email=' +
        email;
    } else if (productId == ProductsID.limited_society) {
      generated_url =
        'https://' +
        this.frontUrl +
        '/contrato/' +
        contractId +
        '/denominacion-social?token=' +
        token +
        '&email=' +
        email;
    } else if (productId == ProductsID.patents_and_trademarks) {
      generated_url =
        'https://' +
        this.frontUrl +
        '/contrato/' +
        contractId +
        '/registro-marca?token=' +
        token +
        '&email=' +
        email;
    }
    return generated_url;
  }

  is_dni_valid(dniInput) {
    dniInput = dniInput.toUpperCase();
    var tabla = 'TRWAGMYFPDXBNJZSQVHLCKE';
    var dig_ext = 'XYZ';
    var cif_ext = 'ABCDEFGHJNPQRSUVW';
    var cif_special_type = 'PX'; // Those CIFs that beggin with PX are referenced to city councils or foreigners.
    var reemp_dig_ext = {X: '0', Y: '1', Z: '2'};
    var reemp_dig_ext_cif = {
      A: '1',
      B: '2',
      C: '3',
      D: '4',
      E: '5',
      F: '6',
      G: '7',
      H: '8',
      I: '9',
      J: '0',
    };
    var numbers = '1234567890';
    if (dniInput != null && dniInput.length == 9) {
      var dig_control = dniInput[8];
      var dni = dniInput.substring(0, 8);
      if (dig_ext.includes(dni[0])) {
        dni = dni.replace(dni[0], reemp_dig_ext[dni[0]]);
      } else {
        if (cif_ext.includes(dni[0])) {
          if (dig_control in reemp_dig_ext_cif) {
            dig_control = reemp_dig_ext_cif[dig_control];
          }
          if (!Number(dni.substring(1, 8))) {
            return false;
          }

          numbers = dni.substring(1, 8);
          var sum = 0;

          // Sum even numbers
          for (var i = 1; i < numbers.length; i += 2) {
            sum = sum + +numbers[i];
          }
          // Multiply odd numbers by two, get each index and sum number value
          for (var i = 0; i < numbers.length; i += 2) {
            let number = (+numbers[i] * 2).toString();
            for (var m = 0; m < number.length; ++m) {
              sum = sum + +number[m];
            }
          }

          if (cif_special_type.includes(dni[0])) {
            var dig_check = 10 - ((64 + sum) % 10);
          } else {
            var dig_check = 10 - (sum % 10);
          }

          dig_check %= 10;
          return dig_control == dig_check.toString();
        }
      }
      var dni_numbers = 0;
      for (var s = 0; s < dni.length; s = s + 1) {
        if (numbers.includes(dni[s])) {
          dni_numbers = dni_numbers + 1;
        }
      }
      return tabla[dni % 23] == dig_control && dni.length == dni_numbers;
    } else {
      if (dniInput != null && dniInput.length == 10) {
        return this.is_pasaport_valid(dniInput);
      } else {
        return false;
      }
    }
  }

  is_pasaport_valid(pasaport) {
    var numbers = '1234567890';
    if (pasaport.length == 10 || pasaport.length == 9) {
      for (var k = 0; k < 3; k = k + 1) {
        if (Number(pasaport.substring(0, 3)[k])) {
          return false;
        }
      }
      for (var p = 0; p < 6; p = p + 1) {
        if (!numbers.includes(pasaport.substring(3, 9)[p])) {
          return false;
        }
      }
      if (pasaport.length == 10) {
        if (Number(pasaport[9])) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  transform(value, product, sanitized) {
    if (value != undefined && product != undefined) {
      if (value.includes('{{product_price}}')) {
        return sanitized.bypassSecurityTrustHtml(
          value.replace(
            /{{product_price}}/,
            product.price.toLocaleString('de-DE')
          )
        );
      } else {
        return sanitized.bypassSecurityTrustHtml(
          value.replace(
            /{{product_price_without_tax}}/,
            (product.price / 1.21).toLocaleString('de-DE')
          )
        );
      }
    } else {
      return undefined;
    }
  }

  transformText(value, product) {
    if (value != undefined) {
      if (value.includes('{{product_price}}')) {
        return value.replace(
          /{{product_price}}/,
          product.price.toLocaleString('de-DE')
        );
      } else {
        return value.replace(
          /{{product_price_without_tax}}/,
          (product.price / 1.21).toLocaleString('de-DE')
        );
      }
    } else {
      return undefined;
    }
  }

  transformTextPrice(value, product_price) {
    if (value != undefined) {
      if (value.includes('{{product_price}}')) {
        return value.replace(
          /{{product_price}}/,
          product_price.toLocaleString('de-DE')
        );
      } else {
        return value.replace(
          /{{product_price_without_tax}}/,
          (product_price / 1.21).toLocaleString('de-DE')
        );
      }
    } else {
      return undefined;
    }
  }

  is_contribution_base_valid(contribution_base, company_person) {
    if (company_person.birthday && contribution_base) {
      var age = getAge(company_person.birthday);
      if (
        age >= 48 &&
        (contribution_base < 1035.9 || contribution_base > 2113.2)
      ) {
        return 'El valor del campo Base Cotización debe de estar entre 1035,9 y 2113,2 para personas de 48 años o mayores.';
      }
    } else if (
      age < 48 &&
      (contribution_base < 960.6 || contribution_base > 4139.4)
    ) {
      return 'El valor del campo Base Cotización debe de estar entre 960,6 y 4139,4 para personas menores de 48.';
    } else {
      return '';
    }
  }

  isDateInCurrentMonth(date: Date): boolean {
    const currentDate = new Date();
    return (
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    );
  }
}
