import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-datos-usuario',
  templateUrl: './datos-usuario.component.html',
  styleUrls: ['./datos-usuario.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DatosUsuarioComponent {
  @Input() user: User;
  @Input() public control: any = null;
  @Input() public showEmail: boolean = true;
  @Input() public showPhoneNumber: boolean = true;
  @Input() public sixMonthResident: boolean = true;
  @Input() public information: string = '';

  constructor() {}
}
