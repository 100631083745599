<div class="custom-phone-div" [ngClass]="custom_class" *ngIf="country_code">
  <div class="phone-number-div">
    <div
      class="country-code-drop-down"
      (click)="showCountrys($event)"
      (input)="setPhoneNumber($event.target.value)"
    >
      <p>
        {{ country_code[0] ? country_code[0].dialCode : country_code.dialCode }}
      </p>
    </div>
    <input
      id="phone"
      type="text"
      placeholder="{{ placeholder }}"
      pattern="[0-9]*"
      [value]="phone_number"
      (blur)="onTouched()"
      (input)="setPhoneNumber($event.target.value)"
      required
    />
  </div>
  <div
    *ngIf="show_countrys === true"
    class="search-input-div"
    (click)="dontToggle($event)"
  >
    <input
      type="text"
      class="search-input"
      placeholder="Busca el país"
      (input)="filterCountryCodes($event.target.value)"
    />
    <div class="country-codes-list">
      <div
        class="spain-country-code"
        (click)="setCountrCode(spain_country_code[0])"
      >
        <p>
          {{ spain_country_code[0].name }},
          <span class="dial-code">{{ spain_country_code[0].dialCode }}</span>
        </p>
      </div>

      <div
        class="country-codes"
        *ngFor="let country_code of country_codes; let i = index"
        (click)="setCountrCode(country_code)"
      >
        <p>
          {{ country_code.name }},
          <span class="dial-code">{{ country_code.dialCode }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
