<section>
  <div class="container container-product">
    <div class="paragraph-product">
      <h1 class="product-title">{{ product.home_title }}</h1>
      <p
        class="product-text"
        [innerHtml]="
          _utilssv.transform(product.home_paragraph, product, sanitized)
        "
      ></p>
      <a
        *ngIf="product.id != 12"
        class="product-sections-button product-interested-button"
        [routerLink]="['/formulario-registro/', product.id]"
        queryParamsHandling="merge"
        >ME INTERESA</a
      >
    </div>
    <div>
      <picture>
        <source srcset="{{product.home_image}}" media="(min-width: 440px)" />
        <source srcset="{{product.home_image_mobile}}" />
        <img
          class="productImage"
          src="{{ product.home_image_mobile }}"
          alt="{{ product.home_image_alt }}"
          loading="lazy"
          [ngStyle]="
            product.image_height < 300 && innerWidth > 440
              ? { 'max-width': '350px', margin: '245px 0px 50px 100px' }
              : product.image_height < 300 && innerWidth < 440
              ? { margin: '30px 10px 30px 10px' }
              : {}
          "
        />
      </picture>
    </div>
  </div>
</section>
<app-product-includes [product]="product"></app-product-includes>
<app-product-tier [product]="product"></app-product-tier>
<app-steps [product]="product"></app-steps>
<app-product-sections [product]="product"></app-product-sections>
<app-product-options [product]="product"></app-product-options>
<app-product-articles
  *ngIf="product"
  [product]="product"
></app-product-articles>
<app-product-reviews [product]="product"></app-product-reviews>
<app-faqs
  [productId]="product.id"
  [price]="product.price"
  [color]="product.color_html"
  [product]="product"
></app-faqs>
