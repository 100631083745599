/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { setBase } from '@enley-es/web-components/es/utils/asset';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { HttpErrorResponse } from '@angular/common/http';
import 'hammerjs';

setBase('./web-components-assets/');

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', environment.apiUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
