import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Product } from 'src/app/models/products';
import { UtilsServices } from 'src/app/services/utils.services';

@Component({
  selector: 'app-product-sections',
  templateUrl: './product-sections.component.html',
  styleUrls: ['./product-sections.component.css'],
  providers: [UtilsServices],
})
export class ProductSectionsComponent {
  @Input() product: Product;
  constructor(public _utilssv: UtilsServices, public sanitizer: DomSanitizer) {}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
