export class Notificacion {
  public order: number;
  public id: number;
  public button_text: string;
  public url: string;
  public type: string;
  public contract_id: number;
  public viewed?: boolean;
  public created_at?: Date;
  public product_price?: number;
  constructor() {}
}
