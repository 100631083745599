import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsDescriptionComponent } from './products-description/products-description.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NosotrosComponent, ProductsDescriptionComponent],
  imports: [CommonModule, RouterModule],
  exports: [NosotrosComponent, ProductsDescriptionComponent],
})
export class SharedHomeModule {}
