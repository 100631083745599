import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Address } from 'src/app/models/user';
import { UsuarioServices } from 'src/app/services/usuario.services';

@Component({
  selector: 'app-address-reactive',
  templateUrl: './address-reactive.component.html',
  styleUrls: [
    './styles/address-reactive.component.css',
    './styles/planned-payments-popup-add-payment-popup.scss',
  ],
  providers: [UsuarioServices],
})
export class AddressReactiveComponent implements OnInit {
  @Input() superForm: FormGroup;
  @Input() blackLabels: boolean;
  isInit = false;

  // Done as follows because @Input setters and onInit are asynchronous
  _address = new Address();
  @Input() set address(address: Address) {
    this._address = address;
    if (this.isInit) this.setAddress();
  }
  setAddress() {
    this.superForm.markAsPristine();
    this.superForm.patchValue(this._address);
  }

  constructor(
    private fb: FormBuilder,
    private usuarioServices: UsuarioServices
  ) {}

  countriesOptions = [];
  provincesSpainOptions = [];
  streetTypesOptions = [];
  municipalityOptions = [];
  streetOptions = [];

  ngOnInit() {
    this.initializeOptions();
    this.initializeForm();

    this.superForm.valueChanges.subscribe((x) => {
      if (this.superForm.dirty && this.superForm.get('pk').value !== null) {
        this.superForm.get('pk')?.setValue(null);
      }
    });
  }

  initializeOptions() {
    this.usuarioServices.getCountries().subscribe(
      (response) => {
        this.countriesOptions = response;
        this.countriesOptions.splice(
          this.countriesOptions.indexOf('España'),
          1
        );
        this.countriesOptions.unshift('España');
      },
      (error) => {
        console.error(error);
      }
    );

    this.usuarioServices.getProvinces().subscribe(
      (response) => {
        this.provincesSpainOptions = response.map((province) =>
          this.toTitleCase(province)
        );
      },
      (error) => {
        console.error(error);
      }
    );

    this.usuarioServices.getTypeStreet().subscribe(
      (response) => {
        const streetTypesOptions = Object.entries(response).map(
          ([id, name]) => ({ id, name: this.toTitleCase(name) })
        );
        this.streetTypesOptions = streetTypesOptions.filter(
          (item) => item.id !== 'CL'
        );
        this.streetTypesOptions.unshift({ id: 'CL', name: 'Calle, Carrer' });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  initializeForm() {
    this.superForm.addControl(
      'country',
      new FormControl(null, [Validators.required])
    );
    this.superForm.addControl(
      'province',
      new FormControl(null, [Validators.required])
    );
    this.superForm.addControl(
      'municipality',
      new FormControl(null, [Validators.required])
    );
    this.superForm.addControl(
      'street_type',
      new FormControl(null, [Validators.required])
    );
    this.superForm.addControl(
      'street',
      new FormControl(null, [Validators.required])
    );
    this.superForm.addControl(
      'number',
      new FormControl(null, [Validators.required])
    );
    this.superForm.addControl(
      'postal_code',
      new FormControl(null, [Validators.required])
    );
    this.superForm.addControl('block', new FormControl(null));
    this.superForm.addControl('stairway', new FormControl(null));
    this.superForm.addControl('floor', new FormControl(null));
    this.superForm.addControl('door', new FormControl(null));
    this.superForm.addControl('pk', new FormControl(null));

    this.isInit = true;
    this.setAddress();

    this.autoCompleteOptions();
  }

  autoCompleteOptions() {
    this.superForm
      .get('province')
      ?.valueChanges.pipe(
        switchMap((value) => {
          if (this.superForm.get('country').value !== 'España' || !value) {
            this.municipalityOptions = [];
            return EMPTY;
          }

          return this.usuarioServices.getMunicipality(value);
        })
      )
      .subscribe(
        (response) => {
          this.municipalityOptions = response.map((municipality) =>
            this.toTitleCase(municipality)
          );
          console.log(this.municipalityOptions);
        },
        (error) => {
          console.error(error);
        }
      );

    //------------------------------------------------------------------

    this.superForm
      .get('municipality')
      ?.valueChanges.pipe(
        switchMap((municipality) => {
          if (
            this.superForm.get('country').value !== 'España' ||
            !this.superForm.get('province').value ||
            !this.superForm.get('street_type').value ||
            !municipality
          ) {
            this.streetOptions = [];
            return EMPTY;
          }

          return this.usuarioServices.getStreet(
            this.superForm.get('province').value,
            municipality,
            this.superForm.get('street_type').value
          );
        })
      )
      .subscribe(
        (response) => {
          this.streetOptions = response.map((street) =>
            this.toTitleCase(street)
          );
          console.log(this.streetOptions);
        },
        (error) => {
          console.error(error);
        }
      );

    //------------------------------------------------------------------

    this.superForm
      .get('street_type')
      ?.valueChanges.pipe(
        switchMap((street_type) => {
          if (
            this.superForm.get('country').value !== 'España' ||
            !this.superForm.get('province').value ||
            !this.superForm.get('municipality').value ||
            !street_type
          ) {
            this.streetOptions = [];
            return EMPTY;
          }

          return this.usuarioServices.getStreet(
            this.superForm.get('province').value,
            this.superForm.get('municipality').value,
            street_type
          );
        })
      )
      .subscribe(
        (response) => {
          this.streetOptions = response.map((street) =>
            this.toTitleCase(street)
          );
          console.log(this.streetOptions);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  toTitleCase(phrase) {
    return phrase
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .split('/')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('/');
  }
}
