<form>
  <div
    class="address-option"
    *ngFor="let address of addressesOptions"
    [ngClass]="addressesSelection.value === address.pk ? 'is-selected' : ''"
  >
    <label>
      <input
        type="radio"
        [value]="address.pk"
        [formControl]="addressesSelection"
        name="addressesSelection"
        (change)="selectAddress(address)"
      />
      <p>{{ address.to_string() }}</p>
    </label>
  </div>
  <div
    class="address-option"
    [ngClass]="addressesSelection.value === '-1' ? 'is-selected' : ''"
  >
    <label>
      <input
        type="radio"
        value="-1"
        [formControl]="addressesSelection"
        name="addressesSelection"
        (change)="selectAddressNew()"
      />
      <p>Añadir nueva dirección</p>
    </label>
  </div>
</form>
<app-address-reactive
  [superForm]="superForm"
  [address]="addressSelected"
  class="planned-payments-popup-add-payment-popup"
  [ngClass]="addressesSelection.value !== '-1' ? 'form-hidden' : ''"
></app-address-reactive>
