<div class="grey-out-background">
  <div class="wrapper">
    <div
      aria-label="Close"
      (click)="closePopUp()"
      href="javascript:void(0)"
      class="closeButton"
    >
      <img src="assets/img/close-notification.svg" alt="" />
    </div>
    <div class="title">HACER ABONO</div>
    <div class="input-block">
      <label class="input-title"
        >¿Realizar abono de la factura {{ invoice.invoice_id }}?</label
      >
      <div class="button-display">
        <button (click)="sendResponse()">SÍ</button>
        <button (click)="closePopUp()">NO</button>
      </div>
    </div>
  </div>
</div>
