import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { REFERER } from '../apptemplate/header/url';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private REFERER_KEY = makeStateKey<string>('referer');

  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(REFERER) public referer: string
  ) {}

  getReferer() {
    if (isPlatformBrowser(this.platformId)) {
      return this.transferState.get(this.REFERER_KEY, undefined);
    } else {
      this.transferState.set(this.REFERER_KEY, this.referer);
      return this.referer;
    }
  }
}
