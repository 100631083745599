import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {
  GoogleAllReviewsService,
  GoogleReviewService,
  GoogleTextReviewService,
} from 'src/app/services/googlereview.services';

@Component({
  selector: 'app-why-enley',
  templateUrl: './why-enley.component.html',
  styleUrls: ['./why-enley.component.css'],
  providers: [
    GoogleReviewService,
    GoogleTextReviewService,
    GoogleAllReviewsService,
  ],
})
export class WhyEnleyComponent {
  @Input() public backgroundColor: string = '';

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}
}
