import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import {ReviewInformation} from 'src/app/models/googlereview';
import {Product} from 'src/app/models/products';
import {
  GoogleAllReviewsService,
  GoogleReviewService,
  GoogleTextReviewService,
} from 'src/app/services/googlereview.services';

@Component({
  selector: 'app-home-reviews',
  templateUrl: './home-reviews.component.html',
  styleUrls: ['./home-reviews.component.css'],
  providers: [
    GoogleReviewService,
    GoogleTextReviewService,
    GoogleAllReviewsService,
  ],
})
export class HomeReviewsComponent implements OnInit, OnDestroy {
  public isBigScreen: boolean = true;
  public reviews: ReviewInformation[] = [];
  public stars: number = 0;
  public number_reviews: Number;
  public nextPageToken: string;
  public loading: boolean = true;

  @Input() public backgroundColor: string = '';
  @Input() public cardColor: string = '';
  @Input() product: Product;
  @Input() reviewsInput = null;
  @Input() title = null;

  private scriptHead;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private _googlereviewsv: GoogleReviewService,
    private _googletextreviewsv: GoogleTextReviewService,
    private _googleallreviewssv: GoogleAllReviewsService
  ) {
    if (isPlatformBrowser(this._platformId)) {
      import('@enley-es/web-components/es/opinion-carousel');
      import('@enley-es/web-components/es/opinion-element');
    }
  }

  updateScreenSize() {
    if (isPlatformBrowser(this._platformId)) {
      this.isBigScreen = window.innerWidth > 1025;
    }
  }

  ngOnInit(): void {
    this.getReviews();
    this.updateScreenSize();
  }

  getReviews() {
    this._googlereviewsv.getReviewInformation().subscribe((resp) => {
      this.stars = parseFloat(resp.averageRating.toFixed(1));
      this.number_reviews = resp.totalReviewCount;
      if (
        this.product &&
        this.product.product_description &&
        isPlatformServer(this._platformId)
      ) {
        const reviewJson = {
          '@context': 'https://schema.org/',
          '@type': 'CreativeWorkSeries',
          name: this.product.product_description.name,
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: this.stars,
            bestRating: '5',
            ratingCount: this.number_reviews,
          },
        };
        this.scriptHead = this.renderer2.createElement('script');
        this.scriptHead.type = `application/ld+json`;
        this.scriptHead.text = JSON.stringify(reviewJson);
        this.renderer2.removeChild(this._document.head, this.scriptHead);
        this.renderer2.appendChild(this._document.head, this.scriptHead);
      }
    });

    this._googletextreviewsv
      .getReviewInformation(null, 'rating desc')
      .subscribe((resp) => {
        this.reviews = resp.reviews.filter(
          (review) => review.comment.length <= 300
        );
        if (this.reviews.length < 10) {
          this.nextPageToken = resp.nextPageToken;
          this.nextPage();
        } else {
          this.loading = false;
        }
      });
  }

  nextPage() {
    this._googletextreviewsv
      .getReviewInformation(this.nextPageToken, 'rating desc')
      .subscribe((resp) => {
        this.reviews.push(
          ...resp.reviews.filter((review) => review.comment.length <= 300)
        );
        this.reviews = this.reviews.slice(0, 10);
        this.loading = false;
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScreenSize();
  }

  ngOnDestroy(): void {
    if (this.scriptHead) {
      this.renderer2.removeChild(this._document.head, this.scriptHead);
    }
  }
}
