import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  NgForm,
} from '@angular/forms';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DjangoErrorHandler } from 'src/app/django.error';
import { Contrato, ProductsID } from 'src/app/models/contrato';
import { Notificacion } from 'src/app/models/notificacion';
import { Product } from 'src/app/models/products';
import {
  ContentType,
  getRegisterAnalytics,
  Register,
  RegisterAnalytics,
  setRegisterAnalytics,
} from 'src/app/models/register';

import { FieldType } from 'src/app/models/tracking';
import { AuthService } from 'src/app/services/auth.service';
import { ContratoService } from 'src/app/services/contrato.services';
import { AlertService } from 'src/app/services/notificaciones.service';
import { UtilsServices } from 'src/app/services/utils.services';
import { Location } from '@angular/common';
import { UsuarioServices } from 'src/app/services/usuario.services';
import { ProductsServices } from 'src/app/services/products.services';
import { map } from 'rxjs/operators';
import { timeStamp } from 'console';
import { REFERER } from 'src/app/apptemplate/header/url';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-box-register',
  templateUrl: './box-register.component.html',
  styleUrls: ['./box-register.component.css'],
  providers: [
    UsuarioServices,
    UtilsServices,
    ContratoService,
    AuthService,
    ProductsServices,
  ],
})
export class BoxRegisterComponent implements OnInit, OnChanges, AfterViewInit {
  constructor(
    private utilsService: UtilsServices,
    private contractService: ContratoService,
    private authService: AuthService,
    private djangoError: DjangoErrorHandler,
    private router: Router,
    private _notificationsv: AlertService,
    public route: ActivatedRoute,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private location: Location,
    private usuarioService: UsuarioServices,
    private _productsv: ProductsServices,
    @Inject(PLATFORM_ID) private platformId: Object,
    private analyticsService: AnalyticsService
  ) {
    this.contract = new Contrato();
    this.step = 1;
    this.register = new Register();
    this.notification = new Notificacion();
    this.showPopUpContract = false;
    this.previous_contracted = false;
  }
  @Input() public product: Product;
  @Input() showPopup: Boolean;
  @Input() loading: any;
  //In order to implement two-way binding, you can add an @Output property with the same name followed by Change, and emit the event when the change occurs:
  @Output() loadingChange = new EventEmitter<Boolean>();
  @Output() showPopupResumeChange = new EventEmitter<Boolean>();
  public product_type: string;
  public step: number;
  public old_step: number = 1;
  private step_int: number;
  public contract: Contrato;
  public register: Register;
  public next: boolean;
  public notification: Notificacion;
  public nonFieldErrors: string;
  private register_analytics: RegisterAnalytics;
  public with_password: boolean = true;
  public contrac_fields$: Observable<FieldType[]>;
  public actual_contract_field: FieldType;
  public color: String;
  public isLoggedIn: boolean = false;
  @ViewChild('autoCountries') autoCountries;
  @ViewChild('autoCoummunities') autoCoummunities;
  @ViewChild('autoCoummunities') autoProvince;

  public loadingGif: boolean;

  public dataCountry: string[];
  public dataComunity: string[];
  public dataProvince: string[];

  public showPopUpContract: boolean;
  public product_title: string;
  public previous_contract_id: number;
  public previous_contracted: boolean;
  public iframe: boolean;
  public error;
  public iframe_bottom: boolean;
  public innerWidth: boolean;
  public bg_color: string;
  public text_color;
  public jwt_access_token: string;
  public jwt_refresh_token: string;
  public userEmail: string;
  public communityLoading: boolean = false;
  public countryLoading: boolean = false;
  public provinceLoading: boolean = false;

  @Input() formType: number;

  public is_not_circe_community: Object;

  @ViewChild('form', { static: false }) form: NgForm;

  ngOnInit(): void {
    this.loading = true;
    if (isPlatformBrowser(this.platformId)) {
      setRegisterAnalytics(
        this.route,
        this.platformId,
        this.analyticsService.getReferer(),
        ContentType.PREVIOUS_FORM
      );
    }

    this.isLoggedIn = this.authService.isLoggedIn();

    this.route.params.subscribe((params: Params) => {
      this.route.data.subscribe((response) => {
        this.product = response.product;
        this.loading = false;
        this.loadingChange.emit(this.loading);

        this.actual_contract_field = this.product.contract_fields[0];

        this.route.queryParams.subscribe((params: Params) => {
          this.iframe = params['iframe'];
          if (params['bg_color']) {
            this.bg_color = '#' + params['bg_color'];
            this.text_color = params['text_color'];
          }

          if (params.iframe_bottom != undefined) {
            this.iframe_bottom = params.iframe_bottom;
          }
          if (params.tipo_formulario != undefined) {
            this.formType = params.tipo_formulario;
            this.product.form_type = params.tipo_formulario;
          }
          if (params.step != undefined) {
            this.step = Number(params.step);
          } else {
            this.step = 1;
            this.step_int = 1;
          }
          this.actual_contract_field = this.product.contract_fields.find(
            (item) => item.contractfieldtranslation.order == this.step
          );
        });
        this.with_password = false;

        this.register_analytics = getRegisterAnalytics(this._platformId);
      });
      if (!this.iframe || (this.iframe && this.product.form_type != 2)) {
        this.usuarioService.getCommunity().subscribe((resp) => {
          this.dataComunity = resp;
        });
        this.usuarioService.getCountries().subscribe((resp) => {
          this.dataCountry = ['España'].concat(
            resp.filter((country) => country != 'España').sort()
          );
        });
        this.usuarioService.getProvinces().subscribe((resp) => {
          this.dataProvince = resp;
        });
      }
    });
  }

  getCommunitiy() {
    if (this.iframe && this.product.form_type == 2) {
      this.communityLoading = true;
      this.usuarioService.getCommunity().subscribe((resp) => {
        this.dataComunity = resp;
        this.communityLoading = false;
      });
    }
  }

  getCountries() {
    if (this.iframe && this.product.form_type == 2) {
      this.countryLoading = true;
      this.usuarioService.getCountries().subscribe((resp) => {
        this.dataCountry = ['España'].concat(
          resp.filter((country) => country != 'España').sort()
        );
        this.countryLoading = false;
      });
    }
  }

  getProvinces() {
    if (this.iframe && this.product.form_type == 2) {
      this.provinceLoading = true;
      this.usuarioService.getProvinces().subscribe((resp) => {
        this.dataProvince = resp;
        this.provinceLoading = false;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onesize(event) {
    this.innerWidth = window.innerWidth >= 440;
    this.send_height_to_parent_function();
  }

  send_height_to_parent_function() {
    if (document.getElementById('box-register')) {
      var height = document.getElementById('box-register').clientHeight;
      parent.postMessage({ height: height }, '*');
    }
  }

  isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.usuarioService.getCountries().subscribe((resp) => {
      this.dataCountry = ['España'].concat(
        resp.filter((country) => country != 'España').sort()
      );
    });

    this.usuarioService.getCommunity().subscribe((resp) => {
      this.dataComunity = resp;
    });
    this.usuarioService.getProvinces().subscribe((resp) => {
      this.dataProvince = resp;
    });

    if (this.error) {
      this.utilsService.ngAutocompltePlaceholdersBoxRegister();
      for (let key of Object.keys(this.error.error)) {
        if (this.form.controls[key]) {
          this.djangoError.handleError(this.error, this.form);
          this.error = undefined;
        }
      }
    }
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this._platformId)) {
      if (navigator.userAgent.includes('Firefox')) {
        document.querySelectorAll('input[type="text"').forEach((item) => {
          let textLength = this.getTextWidth(
            item.getAttribute('placeholder'),
            'italic 14px Barlow'
          );
          if (textLength > item.clientWidth * 3) {
            item.setAttribute(
              'style',
              'height: 80px!important; padding-bottom:' +
                (80 - 14 * 3 - 6) +
                'px!important; white-space:pre-wrap;'
            );
          } else if (textLength > item.clientWidth * 2) {
            item.setAttribute(
              'style',
              'height: 60px!important;  padding-bottom:' +
                (60 - 14 * 2 - 6) +
                'px!important;  white-space:pre-wrap;'
            );
          } else if (textLength > item.clientWidth) {
            item.setAttribute(
              'style',
              'height: 40px!important; padding-bottom:' +
                0 +
                'px!important; white-space:pre-wrap;'
            );
          }
        });
      } else {
        document.querySelectorAll('input[type="text"').forEach((item) => {
          let textLength = this.getTextWidth(
            item.getAttribute('placeholder'),
            'italic 20px Barlow'
          );
          if (textLength >= item.clientWidth * 4) {
            item.setAttribute(
              'style',
              'height: 100px!important; padding-bottom:' +
                (100 - 14 * 3 - 6) +
                'px!important; white-space:pre-wrap;'
            );
          } else if (textLength >= item.clientWidth * 3) {
            item.setAttribute(
              'style',
              'height: 80px!important; padding-bottom:' +
                (80 - 14 * 3 - 6) +
                'px!important; white-space:pre-wrap;'
            );
          } else if (textLength >= item.clientWidth * 2) {
            item.setAttribute(
              'style',
              'height: 60px!important;  padding-bottom:' +
                (60 - 14 * 2 - 6) +
                'px!important;  white-space:pre-wrap;'
            );
          } else if (textLength >= item.clientWidth) {
            item.setAttribute(
              'style',
              'height: 40px!important; padding-bottom:' +
                20 +
                'px!important; white-space:pre-wrap; max-width: 100%!important; border: 0px!important'
            );
          }
        });
      }
      //TODO: en comptes de fer if per navegador, fer-ho per propietats/atrbibuts
      document.querySelectorAll('input[type="date"').forEach((item) => {
        let textLength = this.getTextWidth(
          item.getAttribute('placeholder'),
          'italic 20px Barlow'
        );
        if (navigator.appVersion.indexOf('Chrome/') != -1) {
          item.setAttribute('style', 'padding-top: 17px!important');
        }
      });
      if (window.self !== window.top) {
        // if w.self === w.top, we are not in an iframe
        // send message to parent about height updates
        this.send_height_to_parent_function(); //whenever the page is loaded
        var observer = new MutationObserver(
          this.send_height_to_parent_function
        ); // whenever DOM changes PT1
        var config = {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        }; // PT2
        observer.observe(window.document, config); // PT3
      }
      this.utilsService.ngAutocompltePlaceholdersBoxRegister();
    }
  }
  getTextWidth(text, font) {
    // if given, use cached canvas for better performance
    // else, create new canvas
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
  }

  changeStep(step: number) {
    if (this.step < step) {
      if (this.form.valid) {
        this.step = this.step + 1;
        let url = '/formulario-registro/' + this.product.id + '?step=2';
        this.location.replaceState(url);
      } else {
        this.next = true;
        this.utilsService.scrollIfFormHasErrors(this.form).then(() => {});
      }
    } else {
      this.step = step;
      let url = '/formulario-registro/' + this.product.id + '?step=1';
      this.location.replaceState(url);
      setTimeout(() => {
        this.utilsService.ngAutocompltePlaceholdersBoxRegister();
      }, 200);
    }
  }

  nextStep(value) {
    if (value != undefined) {
      this.contract[this.actual_contract_field.name] = value;
    }
    this.product.contract_fields = this.product.contract_fields.sort((a, b) =>
      a.contractfieldtranslation.order > b.contractfieldtranslation.order
        ? 1
        : -1
    );
    this.step = this.step + 1;
    this.step_int = this.step_int + 1;
    let url = this.router.url.split('?')[0] + '?step=' + this.step;

    this.location.replaceState(url);
    this.actual_contract_field = this.product.contract_fields.find((item) => {
      return (
        item.contractfieldtranslation.order >= this.step_int &&
        (item.dependent_field == undefined ||
          (this.contract[item.dependent_field.name] != undefined &&
            item.dependent_field_value.toString() ==
              this.contract[item.dependent_field.name].toString()))
      );
    });
    if (this.actual_contract_field) {
      this.step_int = this.actual_contract_field.contractfieldtranslation.order;
    }

    setTimeout(() => {
      this.utilsService.ngAutocompltePlaceholdersBoxRegister();
    }, 100);
  }

  previousStep() {
    this.step_int = this.step_int - 1;
    let url =
      this.router.url.split('?')[0] + '?tipo_formulario=2&step=' + this.step;

    this.location.replaceState(url);
    let contract_field_copy = this.product.contract_fields.slice().reverse();
    this.actual_contract_field = contract_field_copy.find((item) => {
      return (
        item.contractfieldtranslation.order <= this.step_int &&
        (item.dependent_field == undefined ||
          (this.contract[item.dependent_field.name] != undefined &&
            item.dependent_field_value.toString() ==
              this.contract[item.dependent_field.name].toString()))
      );
    });

    this.step = this.step - 1;

    if (this.actual_contract_field.contractfieldtranslation) {
      this.step_int = this.actual_contract_field.contractfieldtranslation.order;
    }
    setTimeout(() => {
      this.utilsService.ngAutocompltePlaceholdersBoxRegister();
    }, 100);
  }

  closePopup() {
    this.showPopup = false;
  }

  goNext() {
    this.notification.order = 1;
    this.notification.contract_id = this.contract.id;
    this.notification.type = 'F';
    this.is_not_circe_community = this.contract.getIsCirceCommunitie(
      this.contract.province
    );
    let thanks = '/gracias/';
    if (this.is_not_circe_community) {
      thanks = '/gracias-alt/';
    }
    let url = 'contrato/' + this.contract.id + thanks + this.product.id;
    if (this.iframe) {
      window.top.location.href =
        '/' +
        url +
        this.product.id +
        '?access_token=' +
        this.jwt_access_token +
        '&refresh_token=' +
        this.jwt_refresh_token +
        '&userEmail=' +
        this.userEmail;
    } else {
      this.router.navigate([url]);
    }
    this._notificationsv.createAlert(this.notification).subscribe();
  }

  createNewContract(newUser?: boolean) {
    this.loadingGif = true;
    this.showPopUpContract = false;

    this.contractService.createContract(this.contract).subscribe(
      (response) => {
        this.contract.id = response.id;
        this.contractService.afterCreateContract(response);
        this.goNext();
      },
      (error) => {
        this.loadingGif = false;
        this.error = error;
        if (newUser) {
          this.isLoggedIn = true;
          this.step = 1;
          this.djangoError.handleError(error, this.form);
        } else {
          this.djangoError.handleError(error, this.form);
        }
        console.error(error);
      }
    );
  }

  submit() {
    this.loadingGif = true;

    this.contract.contract_type = 'prev_form';

    if (this.form.valid) {
      if (this.isLoggedIn) {
        this.contract.product_id = this.product.id;
        this.contract.send_previous_doc = true;
        this.contractService
          .getContracts(true)
          .subscribe((contractsResponse) => {
            let repeated = false;
            contractsResponse.forEach((contract) => {
              if (
                contract.product == this.contract.product_id &&
                contract.state != 'finished'
              ) {
                repeated = true;
                this.previous_contract_id = contract.id;
              }
            });
            if (repeated) {
              this._productsv
                .getProduct(this.product.id)
                .subscribe((productsResult) => {
                  this.product_title = productsResult.value.toLowerCase();
                  this.loading = false;
                  this.showPopUpContract = true;
                  this.loadingGif = false;
                });
            } else {
              this.createNewContract();
            }
          });
      } else {
        this.register.person_type = 'customer';
        if (this.with_password) {
          this.register.password2 = this.register.password1;
        } else {
          this.register.non_registered_user = true;
        }
        this.register.product_id = this.product.id;

        this.register.register_analytics = this.register_analytics;

        this.contract.product_id = this.product.id;
        this.contract.register_type = 'previous_form';
        this.register.contract = this.contract;
        this.authService.registerLogged(this.register).subscribe(
          (response) => {
            this.authService.isUserLoggedIn.next(true);
            this.contract.id = response.contract_id;
            this.jwt_access_token = response.access_token;
            this.jwt_refresh_token = response.refresh_token;
            this.userEmail = response.user.email;
            this.contractService
              .getContrato(this.contract.id)
              .subscribe((response) => {
                this.contractService.afterCreateContract(response);
              });
            this.goNext();
          },
          (error) => {
            this.contract.send_previous_doc = true;
            if (
              error.error.email ==
              'Ya existe un usuario registrado con esa dirección de correo electrónico.'
            ) {
              const queryParams: Params = { email: this.register.email };

              if (isPlatformBrowser(this._platformId)) {
                localStorage.setItem('contract', JSON.stringify(this.contract));
              }
              if (this.iframe) {
                window.top.location.href =
                  '/login-new/' +
                  this.product.id +
                  '?email=' +
                  this.register.email;
              } else {
                this.router.navigate(['/login-new/', this.product.id], {
                  queryParams: queryParams,
                });
              }
            }
            this.nonFieldErrors = error.error.non_field_errors;
            if (
              error.error &&
              !error.error['email'] &&
              !error.error['first_name'] &&
              !error.error['phone_number']
            ) {
              console.log(error);
              this.error = error;
              this.step = 1;
            }
            this.djangoError.handleError(error, this.form);
            this.loadingGif = false;
          }
        );
      }
    } else {
      this.loadingGif = false;
      this.utilsService.scrollIfFormHasErrors(this.form).then(() => {});
    }
  }

  goToMyContract() {
    window.top.location.href =
      'contrato/' + this.previous_contract_id + '/tracking/';
  }

  nextStepSelect() {
    if (this.contract[this.actual_contract_field.name] != undefined) {
      this.nextStep(undefined);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class BoxRegisterResolver {
  constructor(
    private service: ProductsServices,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.service.getProduct(route.params.productid).pipe(
      map((response) => {
        response.contract_fields = response.contract_fields.sort((a, b) => {
          return a.contractfieldtranslation.order >
            b.contractfieldtranslation.order
            ? 1
            : -1;
        });
        response.title_trans = Product.getProductType(route.params.productid);
        return response;
      })
    );
  }
}
