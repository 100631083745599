<fieldset ngModelGroup="address">
  <div class="row row-aligned">
    <div class="col-md-8 mt-4"></div>
  </div>
  <div class="d-flex flex-column">
    <label
      class="formConvenioLabels convenioReguladorInputsSize hijos-label"
      for="inputState"
    >
      ¿{{ third_person ? "Está" : "Estás" }} empadronado en el mismo municipio
      en el que {{ third_person ? "reside" : "resides" }}?</label
    >
    <div class="col-md-1 d-flex" style="max-width: 70px; padding-left: 0px">
      <div
        class="form-check"
        style="padding-left: 0px; display: flex; justify-content: space-between"
      >
        <label class="address_checkbox-contain">
          <input
            [hidden]="true"
            class="form-check-input"
            id="choice"
            type="radio"
            name="same_registration"
            #same_registration="ngModel"
            (change)="updateRegistration()"
            [(ngModel)]="customer.same_registration"
            required
            [value]="true"
          />
          <div class="address_checkbox-input"></div>
        </label>
        <div style="padding-right: 2px; font-weight: 300; color: #212529">
          <span class="checkboxTextStyle">SÍ</span>
        </div>
      </div>

      <div
        class="form-check"
        style="
          padding-left: 0px;
          display: flex;
          justify-content: space-between;
          margin-left: 20px;
        "
      >
        <label class="address_checkbox-contain">
          <input
            [hidden]="true"
            class="form-check-input"
            id="choice"
            type="radio"
            name="same_registration"
            #same_registration="ngModel"
            (change)="updateRegistration()"
            [(ngModel)]="customer.same_registration"
            required
            [value]="false"
          />
          <div class="address_checkbox-input"></div>
        </label>
        <div style="padding-right: 2px; font-weight: 300; color: #212529">
          <span class="checkboxTextStyle">NO</span>
        </div>
      </div>
    </div>
    <small
      *ngIf="!same_registration.valid && control.submitted"
      style="color: red"
    >
      Completa este campo
    </small>
    <small
      *ngIf="!same_registration.valid && same_registration.errors != null"
      style="color: red"
    >
      {{ same_registration.errors.message }}
    </small>
  </div>
  <ng-container *ngIf="customer.same_registration === false">
    <div class="row row-aligned">
      <div class="col-md-4 mt-3">
        <div class="ng-autocomplete adressInput">
          <ng-autocomplete
            (selected)="selectEventRegistrationProvince()"
            [data]="dataRegistrationProvinces"
            placeholder="Provincia empadronamiento"
            id="Provincia empadronamiento"
            [itemTemplate]="itemTemplate"
            #registration_province="ngModel"
            [(ngModel)]="customer.registration_province"
            name="registration_province"
          >
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <p [innerHTML]="item"></p>
          </ng-template>
          <small
            *ngIf="!registration_province.valid && control.submitted"
            style="color: red"
          >
            Completa este campo
          </small>
          <small
            *ngIf="
              !registration_province.valid &&
              registration_province.errors != null
            "
            style="color: red"
          >
            {{ registration_province.errors.message }}
          </small>
        </div>
      </div>
      <div class="col-md-4 mt-3">
        <div class="ng-autocomplete adressInput">
          <ng-autocomplete
            #autoRegistrationMuncipalities
            (keyup)="loadRegistrationMunicipalities($event.target.value)"
            id="Municipio empadronamiento"
            [disabled]="!customer.registration_province"
            (inputFocused)="loadRegistrationMunicipalities('')"
            [data]="dataRegistrationMunicipality"
            placeholder="Municipio empadronamiento"
            [itemTemplate]="itemTemplate"
            #registration_municipality="ngModel"
            [(ngModel)]="customer.registration_municipality"
            name="registration_municipality"
            minQueryLength="{{
              customer.registration_province === 'NAVARRA' ||
              customer.registration_province === 'ARABA' ||
              customer.registration_province === 'BIZKAIA' ||
              customer.registration_province === 'GUIPUZKOA'
                ? 3
                : 1
            }}"
          >
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <p [innerHTML]="item"></p>
          </ng-template>

          <small
            *ngIf="!registration_municipality.valid && control.submitted"
            style="color: red"
          >
            Completa este campo
          </small>
          <small
            *ngIf="
              !registration_municipality.valid &&
              registration_municipality.errors != null
            "
            style="color: red"
          >
            {{ registration_municipality.errors.message }}
          </small>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      contract &&
      contract.user_customer &&
      customer.registration_municipality &&
      contract.user_customer.customer.registration_municipality !==
        customer.registration_municipality
    "
  >
    <div class="row row-aligned">
      <div class="col-md-4 mt-3">
        <label class="formConvenioLabels convenioReguladorInputsSize"
          >¿Ante qué juzgado quieres que presentemos la demanda?
        </label>
        <div class="adressInput">
          <select
            placeholder="Provincia juzgado"
            #court_province="ngModel"
            [(ngModel)]="contract.court_province"
            name="court_province"
            required
            appValidateProvinceType="españa"
          >
            <option disabled [ngValue]="undefined" selected>
              Selecciona una opción
            </option>
            <option
              *ngIf="
                contract.user_customer.customer.registration_province &&
                dataRegistrationProvinces &&
                dataRegistrationProvinces.includes(
                  contract.user_customer.customer.registration_province.toUpperCase()
                )
              "
              [ngValue]="contract.user_customer.customer.registration_province"
            >
              {{ contract.user_customer.customer.registration_province }}
            </option>
            <option
              *ngIf="
                customer.registration_province &&
                customer.registration_province !==
                  contract.user_customer.customer.registration_province &&
                dataRegistrationProvinces &&
                dataRegistrationProvinces.includes(
                  customer.registration_province.toUpperCase()
                )
              "
              [ngValue]="customer.registration_province"
            >
              {{ customer.registration_province }}
            </option>
          </select>
        </div>
        <small
          *ngIf="!court_province.valid && control.submitted && control.required"
          style="color: red"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!court_province.valid && court_province.errors != null"
          style="color: red"
        >
          {{ court_province.errors.message }}
        </small>
        <small
          *ngIf="court_province.errors && court_province.errors.province"
          style="color: red"
        >
          Solo puedes seleccionar una provincia del estado español
        </small>
      </div>
      <div class="col-md-4 mt-3">
        <div class="adressInput">
          <select
            placeholder="Municipio juzgado"
            #court_municipality="ngModel"
            [(ngModel)]="contract.court_municipality"
            [disabled]="!contract.court_province"
            name="court_municipality"
            required
          >
            <option disabled [ngValue]="undefined" selected>
              Selecciona una opción
            </option>
            <option
              [ngValue]="
                contract.user_customer.customer.registration_municipality
              "
              *ngIf="
                contract.user_customer.customer.registration_municipality &&
                contract.court_province ===
                  contract.user_customer.customer.registration_province
              "
            >
              {{ contract.user_customer.customer.registration_municipality }}
            </option>
            <option
              [ngValue]="customer.registration_municipality"
              *ngIf="
                customer.registration_municipality &&
                contract.court_province === customer.registration_province
              "
            >
              {{ customer.registration_municipality }}
            </option>
          </select>

          <small
            *ngIf="!court_municipality.valid && control.submitted"
            style="color: red"
          >
            Completa este campo
          </small>
          <small
            *ngIf="
              !court_municipality.valid && court_municipality.errors != null
            "
            style="color: red"
          >
            {{ court_municipality.errors.message }}
          </small>
        </div>
      </div>
    </div>
  </ng-container>
</fieldset>
