import {RegisterAnalytics} from './register';

export class Contact {
  public category_id: number;
  constructor(
    public affair?: string,
    public name?: string,
    public last_name?: string,
    public email?: string,
    public message?: string,
    public contract_id?: number,
    public phone_number?: Object,
    public phone_number_temp?: Object,
    public gdpr_accepted?: boolean,
    public enley_accepted?: boolean,
    public register_analytics?: RegisterAnalytics,
    public product_id?: number,
    public lead_origin?: string
  ) {}
}

export class CvContact {
  public name: string;
  public phone_number: string;
  public email: string;
  public message: string;
  public cv: File;
  public gdpr_accepted: boolean = false;
  public enley_accepted: boolean = false;

  constructor() {}
}
