<ng-container>
  <div class="error" *ngFor="let error of errors">{{ error }}</div>
  <label>
    <span class="pre">{{ pre }}</span>
    <input
      *ngIf="control != null"
      [type]="type"
      [formControl]="control"
      placeholder=" "
    />
    <input
      *ngIf="control == null"
      [type]="type"
      [value]="value"
      placeholder=" "
      disabled
    />
    <span class="post">{{ post }}</span>
    <div class="outline"></div>
    <span class="float">{{ label }}</span>
  </label>
</ng-container>
