<fieldset
  ngModelGroup="address"
  class="address"
  (keydown.enter)="$event.preventDefault()"
  [disabled]="!enabled"
  [ngClass]="invoice ? 'añadir-pago-address' : ''"
>
  <div *ngIf="nacionalidad" class="row">
    <div class="col-md-4 mt-3">
      <div
        class="ng-autocomplete"
        [ngClass]="invoice ? 'añadir-pago-address' : 'adressInput'"
      >
        <p class="title-address">Nacionalidad</p>
        <ng-autocomplete
          [data]="dataCountry"
          [required]="true"
          id="Nacionalidad"
          placeholder="Nacionalidad"
          autocomplete="nothing"
          [itemTemplate]="itemTemplate"
          #nationality="ngModel"
          [(ngModel)]="customer.nationality"
          name="nationality"
        >
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <p [innerHTML]="item"></p>
        </ng-template>
        <small
          *ngIf="!nationality.valid && control.submitted"
          style="color: red"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!nationality.valid && nationality.errors != null"
          style="color: red"
        >
          {{ nationality.errors.message }}
        </small>
      </div>
    </div>
  </div>
  <ng-container *ngIf="show_choices">
    <div *ngIf="contract_addresses">
      <div class="row row-aligned">
        <div *ngIf="label !== ''" class="col-md-8 mt-4">
          <label
            style="margin-bottom: 10px"
            class="formConvenioLabels convenioReguladorInputsSize"
            >{{ label }}
          </label>
          <span *ngIf="information" class="info-margin-b">
            <app-information
              [name]="information"
              [product_id]="productId"
              [image]="null"
            >
            </app-information>
          </span>
        </div>
        <ng-container *ngFor="let obj of contract_addresses; let i = index">
          <div class="col-md-8 mt-3">
            <div
              class="adressWidth"
              style="
                border-width: 2px;
                border-color: #00788e;
                border-style: solid;
                border-radius: 6px;
                min-height: 36px;
                padding-top: 5px;
                padding-left: 10px;
              "
            >
              <div class="form-check">
                <label
                  class="address_checkbox-contain"
                  style="display: flex !important"
                >
                  <div
                    style="padding-left: 20px; font-weight: 300; color: #212529"
                  >
                    {{ obj.to_string(this.dataTypeStreet) }}
                  </div>
                  <input
                    class="form-check-input"
                    type="radio"
                    (change)="onClickAddress(obj, i)"
                    name="choice_{{ type }}"
                    #choice="ngModel"
                    [(ngModel)]="address.choice"
                    required
                    [value]="i"
                  />
                  <div
                    class="address_checkbox-input adress_checkbox_phoneMargin"
                  ></div>
                  <span
                    style="
                      color: #00748d !important;
                      font-size: 25px;
                      margin-top: -5px;
                      margin-left: 10px;
                      cursor: pointer;
                    "
                    class="material-icons-outlined taskButtons"
                    title="Modificar Dirección"
                    (click)="showDirectionInfo()"
                  >
                    mode_edit
                  </span>
                </label>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="col-md-8 mt-3">
          <div
            class="adressWidth"
            style="
              border-width: 2px;
              border-color: #00788e;
              border-style: solid;
              border-radius: 6px;
              min-height: 36px;
              padding-top: 5px;
              padding-left: 10px;
            "
          >
            <div class="form-check">
              <label class="address_checkbox-contain">
                <div
                  style="padding-left: 20px; font-weight: 300; color: #212529"
                >
                  <span
                    *ngIf="address.choice === -1"
                    style="margin-bottom: -10px"
                    >Detalle de la dirección</span
                  >
                  <span *ngIf="address.choice !== -1"
                    >Añadir nueva dirección</span
                  >
                </div>
                <input
                  [hidden]="true"
                  class="form-check-input"
                  type="radio"
                  (change)="onClickNewAddress()"
                  #choice="ngModel"
                  [(ngModel)]="address.choice"
                  name="choice_{{ type }}"
                  required
                  [value]="-1"
                />
                <div class="address_checkbox-input"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <small
        *ngIf="
          (control.submitted || submitted) &&
          !choice.valid &&
          choice.errors != null
        "
        style="color: red"
      >
        Debes definir una dirección
      </small>
    </div>
  </ng-container>
  <ng-container>
    <span *ngIf="!idContract || (contract_addresses && !show_choices)"
      ><label
        id="address-title"
        style="min-width: 110px; padding-top: 5px"
        class="formConvenioLabels convenioReguladorInputsSize"
        >{{ label }}
      </label></span
    >
    <div
      *ngIf="
        (address.country ||
          !contract_addresses ||
          (contract_addresses && show_fields) ||
          (contract_addresses && !show_choices)) &&
        (show_direction || type == '')
      "
      class="container"
      [ngStyle]="{ 'max-width': changeDistance ? '100%' : '65%' }"
    >
      <div
        *ngIf="show_country"
        class="ng-autocomplete"
        [ngClass]="invoice ? 'añadir-pago-address' : 'adressInput'"
      >
        <span *ngIf="information && !idContract">
          <span class="info-margin-a">
            <app-information
              [name]="information"
              [product_id]="productId"
              [image]="null"
            >
            </app-information>
          </span>
          <div style="height: 10px"></div>
        </span>
        <div
          class="input_component"
          [ngClass]="information && !idContract ? 'country_input' : ''"
        >
          <p
            class="title-address"
            [ngClass]="type == 'customer_2' ? 'province' : ''"
          >
            País
          </p>
          <ng-autocomplete
            #autoCountries
            [required]="true"
            id="País"
            [data]="dataCountry"
            (selected)="selectEventCountry($event)"
            placeholder="País"
            [itemTemplate]="itemTemplate"
            #country="ngModel"
            [disabled]="spanish"
            [(ngModel)]="address.country"
            name="country"
          >
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <p [innerHTML]="item"></p>
          </ng-template>
          <small
            *ngIf="!country.valid && control.submitted"
            style="color: red; margin-top: 5px"
          >
            Completa este campo
          </small>
          <small
            *ngIf="!country.valid && country.errors != null"
            style="color: red"
          >
            {{ country.errors.message }}
          </small>
          <div *ngIf="six_month_resident && country.value == 'España'">
            <label class="c-contain">
              <span class="holderLabelNoHover">
                <ng-container *ngIf="third_person"
                  ><label
                    class="form-check-label seisMesesPosition checkboxTextStyle"
                    for="inputState"
                    >¿Hace menos de 6 meses que reside en España?​</label
                  >
                </ng-container>
                <ng-container *ngIf="!third_person"
                  ><label
                    class="form-check-label seisMesesPosition checkboxTextStyle"
                    for="inputState"
                    >¿Hace menos de 6 meses que resides en España?​</label
                  >
                </ng-container>
              </span>
              <input
                type="checkbox"
                type="checkbox"
                id="inputState"
                #six_month_resident_spain="ngModel"
                [(ngModel)]="customer.six_month_resident_spain"
                name="six_month_resident_spain"
                [value]
              />
              <div class="c-input checkboxSize"></div>
            </label>
            <small
              *ngIf="!six_month_resident_spain.valid && control.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small
              *ngIf="six_month_resident_spain.errors != null"
              style="color: red"
            >
              {{ six_month_resident_spain.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div class="input_component">
        <p
          class="title-address"
          [ngClass]="six_month_resident ? 'province' : ''"
        >
          Provincia
        </p>
        <div
          class="ng-autocomplete"
          [ngClass]="invoice ? 'añadir-pago-address' : 'adressInput'"
        >
          <ng-autocomplete
            #autoProvinces
            id="Provincia"
            (selected)="selectEventProvincia()"
            [data]="dataProvinces"
            placeholder="Provincia"
            [itemTemplate]="itemTemplate"
            #province="ngModel"
            appValidateProvinceType="{{ address.country }}"
            [(ngModel)]="address.province"
            name="province"
            [required]="true"
          >
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <p [innerHTML]="item"></p>
          </ng-template>
          <small
            *ngIf="!province.valid && control.submitted"
            style="color: red"
          >
            Completa este campo
          </small>
          <small
            *ngIf="!province.valid && province.errors != null"
            style="color: red"
          >
            {{ province.errors.message }}
          </small>
          <small
            *ngIf="province.errors && province.errors.province"
            style="color: red"
          >
            Por favor, selecciona una opción valida.
          </small>
        </div>
      </div>
      <div class="input_component">
        <p class="title-address">Municipio</p>
        <div
          class="ng-autocomplete"
          [ngClass]="invoice ? 'añadir-pago-address' : 'adressInput'"
        >
          <ng-container>
            <ng-autocomplete
              #autoMuncipalities
              id="Municipio"
              [disabled]="!address.province ? true : false"
              (keyup)="loadMunicipie($event.target.value)"
              (inputFocused)="loadMunicipie('')"
              [data]="dataMunicipality"
              placeholder="Municipio"
              [itemTemplate]="itemTemplate"
              #municipality="ngModel"
              [(ngModel)]="address.municipality"
              [required]="true"
              name="municipality"
              minQueryLength="{{
                address.province == 'NAVARRA' ||
                address.province == 'ARABA' ||
                address.province == 'BIZKAIA' ||
                address.province == 'GUIPUZKOA'
                  ? 3
                  : 1
              }}"
            >
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <p [innerHTML]="item"></p>
            </ng-template>
            <small
              *ngIf="!municipality.valid && control.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small
              *ngIf="!municipality.valid && municipality.errors != null"
              style="color: red"
            >
              {{ municipality.errors.message }}
            </small>
          </ng-container>
        </div>
      </div>
      <div *ngIf="show_street_type" class="input_component">
        <p class="title-address">Tipo de vía</p>
        <div
          class="ng-autocomplete"
          [ngClass]="invoice ? 'añadir-pago-address' : 'adressInput'"
        >
          <ng-autocomplete
            #autoStreetTypes
            [disabled]="!address.municipality ? true : false"
            id="Tipo de vía"
            (selected)="selectedTypeStreet($event)"
            [data]="dataTypeStreet"
            placeholder="Tipo de vía"
            [searchKeyword]="keyword"
            appValidateStreetType=""
            [itemTemplate]="typeStreetTemplate"
            #street_type="ngModel"
            [(ngModel)]="objectDataStreet"
            name="objectDataStreet"
            [required]="true"
          >
          </ng-autocomplete>

          <ng-template #typeStreetTemplate let-item>
            <p [innerHTML]="item.name"></p>
          </ng-template>
          <small
            *ngIf="
              !street_type.valid &&
              control.submitted &&
              street_type.errors != null
            "
            style="color: red"
          >
            Completa este campo
          </small>
          <small
            *ngIf="street_type.errors && street_type.errors.street_type"
            style="color: red"
          >
            Por favor, selecciona una opción valida.
          </small>
        </div>
      </div>

      <div *ngIf="show_street" class="input_component">
        <p class="title-address">Nombre de la vía</p>
        <div
          class="ng-autocomplete"
          [ngClass]="invoice ? 'añadir-pago-address' : 'adressInput'"
        >
          <ng-autocomplete
            #autoStreets
            [disabled]="!address.street_type ? true : false"
            id="Nombre de la vía"
            (keyup)="selectKeyStreet($event.target.value)"
            (inputFocused)="selectKeyStreet()"
            [data]="dataStreet"
            placeholder="Nombre de la vía"
            [itemTemplate]="itemTemplate"
            #street="ngModel"
            [(ngModel)]="address.street"
            name="street"
            [required]="true"
            minQueryLength="{{
              address.province === 'NAVARRA' ||
              address.province === 'ARABA' ||
              address.province === 'BIZKAIA' ||
              address.province === 'GUIPUZKOA'
                ? 3
                : 1
            }}"
          >
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <p [innerHTML]="item"></p>
          </ng-template>
          <small *ngIf="!street.valid && control.submitted" style="color: red">
            Completa este campo
          </small>
          <small
            *ngIf="!street.valid && street.errors != null"
            style="color: red"
          >
            {{ street.errors.message }}
          </small>
        </div>
      </div>
      <div *ngIf="!numbers" class="input_component">
        <p class="title-address">Número</p>
        <div class="form-group">
          <div class="form-floating">
            <input
              type="number"
              placeholder="Número"
              #number="ngModel"
              [(ngModel)]="address.number"
              name="number"
              (ngModelChange)="onChangeNumber($event)"
              (wheel)="preventNumberInputScroll($event)"
              (keydown)="preventNumberInputArrows($event)"
            />
            <small
              *ngIf="!number.valid && control.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small
              *ngIf="!number.valid && number.errors != null"
              style="color: red"
            >
              {{ number.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div
        *ngIf="!numbers"
        class="input_component block"
        [ngClass]="show_country ? 'number_input' : 'phone_bloque'"
      >
        <p class="title-address">Bloque</p>
        <div class="form-floating">
          <input
            type="text"
            placeholder="Bloque"
            #block="ngModel"
            [(ngModel)]="address.block"
            name="block"
            maxlength="40"
          />
          <small
            *ngIf="!block.valid && block.errors != null"
            style="color: red"
          >
            {{ block.errors.message }}
          </small>
        </div>
      </div>
      <div *ngIf="!numbers" class="input_component number_input">
        <p class="title-address">Escalera</p>
        <div class="form-floating">
          <input
            type="text"
            placeholder="Escalera"
            #stairway="ngModel"
            [(ngModel)]="address.stairway"
            name="stairway"
            maxlength="20"
          />
          <small
            *ngIf="!stairway.valid && stairway.errors != null"
            style="color: red"
          >
            {{ stairway.errors.message }}
          </small>
        </div>
      </div>
      <div
        *ngIf="!numbers"
        class="input_component"
        [ngClass]="show_country ? '' : 'floor'"
      >
        <p class="title-address">Piso</p>
        <div class="form-floating">
          <input
            type="text"
            placeholder="Piso"
            #floor="ngModel"
            [(ngModel)]="address.floor"
            name="floor"
            maxlength="40"
          />
          <small
            *ngIf="!floor.valid && floor.errors != null"
            style="color: red"
          >
            {{ floor.errors.message }}
          </small>
        </div>
      </div>
      <div *ngIf="!numbers" class="input_component">
        <p class="title-address">Puerta</p>
        <div class="form-floating">
          <input
            type="text"
            placeholder="Puerta"
            #door="ngModel"
            [(ngModel)]="address.door"
            name="door"
            maxlength="30"
          />
          <small *ngIf="!door.valid && door.errors != null" style="color: red">
            {{ door.errors.message }}
          </small>
        </div>
      </div>
      <div *ngIf="postal_code_required" class="input_component">
        <p class="title-address">Código postal</p>
        <div
          class="form-floating"
          style="display: flex; flex-direction: column"
        >
          <input
            type="text"
            placeholder="Código postal"
            required
            #postal_code="ngModel"
            [(ngModel)]="address.postal_code"
            name="postal_code"
          />
          <small
            *ngIf="!postal_code.valid && control.submitted"
            style="color: red; margin-top: 5px"
          >
            Completa este campo
          </small>
          <small
            *ngIf="!postal_code.valid && postal_code.errors != null"
            style="color: red"
          >
            {{ postal_code.errors.message }}
          </small>
        </div>
      </div>
      <div *ngIf="surface && postal_code_required" class="input_component">
        <div class="info-popup">
          <p class="title-address">Superficie total en m2</p>
          <app-information
            [name]="'total_surface'"
            [product_id]="7"
            [image]="null"
          ></app-information>
        </div>
        <div class="form-floating">
          <input
            type="number"
            required="true"
            placeholder="Superficie total en m2"
            #surface="ngModel"
            [(ngModel)]="address.surface"
            name="surface"
          />
          <small
            *ngIf="!surface.valid && control.submitted"
            style="color: red; margin-top: 5px"
          >
            Completa este campo
          </small>
          <small
            *ngIf="!surface.valid && surface.errors != null"
            style="color: red"
          >
            {{ surface.errors.message }}
          </small>
        </div>
      </div>
    </div>
  </ng-container>
</fieldset>
