<div class="parent-container">
  <section
    class="services"
    [ngClass]="backgroundColor == 'gray' ? 'background-gray' : ''"
  >
    <div class="header-service">
      <h2 class="services__main-title">Hablamos tu idioma</h2>
      <p class="services__subtitle">
        Sabemos que el lenguaje legal es complejo y en ocasiones difícil de
        entender. Creemos que es fundamental que entiendas en qué consiste y qué
        está pasando en tu trámite legal, por eso hablamos tu idioma, para
        hacértelo sencillo.
      </p>
      <div>
        <button
          [ngClass]="forYou ? 'selected' : ''"
          class="for-you"
          (click)="updateYouServices()"
        >
          Para ti
        </button>
        <button
          [ngClass]="forBusiness ? 'selected' : ''"
          class="company"
          (click)="updateCompanyServices()"
        >
          Para tu empresa
        </button>
      </div>
    </div>
    <div [ngClass]="forBusiness ? 'services__wrapper--hidden' : ''">
      <app-services-for-you
        [items$]="itemsForYou$"
        [@servicesTypesOpacity]="show ? 'show' : 'hide'"
      ></app-services-for-you>
    </div>
    <div [ngClass]="forYou ? 'services__wrapper--hidden' : ''">
      <app-services-for-you
        [items$]="itemsForBusiness$"
        [@servicesTypesOpacity]="show ? 'show' : 'hide'"
      ></app-services-for-you>
    </div>
  </section>
  <div class="white-background"></div>
</div>
