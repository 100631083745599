import { Component, OnInit } from '@angular/core';
import { LaboralPackSubscription } from 'src/app/models/payment';
import { ScheduledServices } from 'src/app/services/payments/scheduled.services';

@Component({
  selector: 'app-productcategory-agency-laboral',
  templateUrl: './laboral.component.html',
  styleUrl: './laboral.component.css',
})
export class ProductCategoryAgencyLaboralComponent implements OnInit {
  packs: LaboralPackSubscription[] = null;

  constructor(private scheduledServices: ScheduledServices) {}

  ngOnInit(): void {
    this.scheduledServices.getActiveLaboralPacks().subscribe((resp) => {
      if (Array.isArray(resp)) {
        this.packs = resp;
      } else {
        throw new Error('Expected array of packs');
      }
    });
  }

  public get_employees_text(
    laboral_pack_subscription: LaboralPackSubscription
  ): string {
    if (
      !laboral_pack_subscription.min_workers &&
      !laboral_pack_subscription.max_workers
    ) {
      return '-';
    }
    if (
      laboral_pack_subscription.min_workers ==
      laboral_pack_subscription.max_workers
    ) {
      return laboral_pack_subscription.min_workers.toString();
    }
    if (!laboral_pack_subscription.max_workers) {
      return 'A partir de ' + laboral_pack_subscription.min_workers;
    } else {
      return (
        laboral_pack_subscription.min_workers +
        ' a ' +
        laboral_pack_subscription.max_workers
      );
    }
  }

  public get_price(laboral_pack_subscription: LaboralPackSubscription): string {
    if (laboral_pack_subscription.price_type == 'FIX_AMOUNT') {
      return (
        (laboral_pack_subscription.price_fix_amount / 1.21)
          .toFixed(0)
          .toString()
          .replace('.', ',') + '€/mes'
      );
    } else if (laboral_pack_subscription.price_type == 'BY_USER') {
      return (
        (laboral_pack_subscription.price_variable_amount / 1.21)
          .toFixed(1)
          .toString()
          .replace('.', ',') + '€ por empleado/mes'
      );
    } else {
      return (
        laboral_pack_subscription.price_fix_amount +
        'y ' +
        laboral_pack_subscription.price_variable_amount +
        ' por empleado'
      );
    }
  }

  public get_discounts(
    laboral_pack_subscription: LaboralPackSubscription
  ): string {
    if (
      laboral_pack_subscription.worker_discounts &&
      !isNaN(laboral_pack_subscription.worker_discounts)
    ) {
      return (
        Number(laboral_pack_subscription.worker_discounts).toFixed(0) + '%'
      );
    }
    return '-';
  }
}
