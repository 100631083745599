<label>
  <span class="input-header">{{ title }}</span>
  <input
    type="text"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    (blur)="onBlur()"
    #textInput="ngModel"
    name="value"
    [ngModelOptions]="{standalone: true}"
  />
  <small
    *ngIf="
      control.errors != null &&
      control.errors.required &&
      (control.touched || form.submitted)
    "
    >Completa este campo.</small
  >
  <small
    *ngIf="
      control.errors != null &&
      control.errors &&
      (control.touched || form.submitted)
    "
    >{{ control.errors.message }}</small
  >
  <small
    *ngIf="
      !control.valid &&
      control.errors != null &&
      !(control.touched || form.submitted)
    "
    style="color: red"
  >
    {{ control.errors.message }}
  </small>
</label>
