import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ProductsID} from 'src/app/models/contrato';
import {Faqs, Product} from 'src/app/models/products';
import {ProductsServices} from 'src/app/services/products.services';
import {UtilsServices} from 'src/app/services/utils.services';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css'],
  providers: [ProductsServices, UtilsServices],
})
export class FaqsComponent implements OnInit {
  constructor(
    private productService: ProductsServices,
    public sanitized: DomSanitizer,
    private _renderer2: Renderer2,
    public _utilssv: UtilsServices,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  public faqs: Faqs[];
  public faqsTitleProduct: string;
  @Input() productId;
  @Input() price;
  @Input() color;
  @Input() product: Product;
  public productHome: Product;

  ngOnInit(): void {
    if (this.productId) {
      this.productService.getFaqs(this.productId).subscribe((response) => {
        this.faqs = response;
        this.faqs = response.sort((a, b) =>
          Number(a.order) < Number(b.order) ? -1 : 1
        );
        this.faqsTitleProduct = FAQS_TITLE_PRODUCT_MAPPING[this.product.url];
        this.product.name = Product.getProductType(
          this.product.id,
          this.product
        );
        if (isPlatformServer(this._platformId)) {
          let script = this._renderer2.createElement('script');
          script.type = `application/ld+json`;
          let text_ob = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: [],
          };

          response.forEach((item) => {
            item.title = item.title.replace(/{{product_price}}/, this.price);
            item.text_html = item.text_html.replace(
              /{{product_price}}/,
              this.price
            );
            text_ob.mainEntity.push({
              '@type': 'Question',
              name: item.title,
              acceptedAnswer: {
                '@type': 'Answer',
                text: item.text_html,
              },
            });
          });
          script.text = JSON.stringify(text_ob);
          this._renderer2.appendChild(this._document.head, script);
        }
        response.forEach((item) => {
          item.title = item.title.replace(/{{product_price}}/, this.price);
        });
      });
    } else {
      this.faqs = FAQS_HOME;
      this.productService
        .getProduct(ProductsID.divorce_product_id)
        .subscribe((productResp) => {
          this.productHome = productResp;
        });
    }
  }

  onToggle(event) {
    const selectorId = event.target.id;
    setTimeout(function () {
      if (event.target.getBoundingClientRect().top < 100) {
        document
          .querySelector(`#${selectorId}`)
          .scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }, 150);
  }
}

const FAQS_TITLE_PRODUCT_MAPPING = {
  ['divorcios-express-online']: 'del divorcio express',
  ['gestion-de-herencias']: 'de herencias',
  ['concursos-de-acreedores']: 'de tu concurso de acreedores',
  ['compraventa-inmuebles']: 'de tu contrato de compraventa',
  ['constitucion-sociedad']: 'de tu sociedad',
  ['compra-sociedades']: 'de tu venta de sociedades',
  ['testamento-notarial']: 'de testamentos',
  ['pre-concursos-acreedores']: 'del preconcurso de acreedores',
  ['patentes-y-marcas']: 'de patentes y marcas',
  ['gestoria']: 'de gestoría fiscal',
};
const faq1 = new Faqs();
const faq2 = new Faqs();
const faq3 = new Faqs();
const faq4 = new Faqs();
const faq5 = new Faqs();
const faq6 = new Faqs();
const faq7 = new Faqs();
const faq8 = new Faqs();
const faq9 = new Faqs();

(faq1.title = '¿Qué es <span style="font-family: Quicksand;">enley</span>?'),
  (faq1.text_html =
    'Somos un <b style="color: #00bed6;">equipo multidisciplinar</b>, liderado por un grupo de abogados con una amplia experiencia profesional. Nuestro objetivo es <b style="color: #00bed6;">democratizar los procesos legales</b> y hacerlos más rápidos, fáciles y accesibles.');
faq2.title =
  '¿Cómo funciona <span style="font-family: Quicksand;">enley</span>?';
faq2.text_html =
  'Nuestro servicio es <b style="color: #00bed6;">fundamentalmente online</b>. A través de unos formularios y asesoramiento online con un abogado, recabamos la información esencial con la que empezar a trabajar en el trámite seleccionado por el cliente.';
faq3.title = '¿Por qué es tan barato respecto a los bufetes clásicos?';
faq3.text_html =
  'Porque hemos desarrollado una <b style="color: #00bed6;">tecnología propia que nos permite automatizar los procesos</b> y, por tanto, hacerlos más baratos. ¿Por qué pagar a un abogado para que anote una información que puedes indicar tú por internet en un formulario?';
faq4.title = '¿Sois abogados colegiados?';
faq4.text_html =
  'Sí. <b style="color: #00bed6;">Todos nuestros abogados están debidamente colegiados.</b>';
faq5.title = '¿Cómo puedo saber cómo va mi trámite?';
faq5.text_html =
  'Una vez que hayas iniciado tu trámite con nosotros, podrás seguir todo el proceso <b style="color: #00bed6;">desde tu Área Privada</b>. Además, siempre que se produzca algún cambio en el estado de tu trámite <b style="color: #00bed6;">te enviaremos un correo</b>. Igualmente, puedes contactarnos siempre que quieras a través de correo electrónico, teléfono o whatsapp.';
faq6.title = 'Yo prefiero que me atiendan personalmente ¿podéis hacerlo?';
faq6.text_html =
  'Sí. Si prefieres ser atendido por nuestros abogados de manera presencial, actualmente contamos con <b style="color: #00bed6;">oficinas en Madrid y Barcelona</b>. Si no te encuentras en ninguno de estos sitios, no te preocupes, siempre tendrás la posibilidad de concertar una videollamada con nosotros.';
faq7.title = '¿Los precios incluyen el IVA?';
faq7.text_html =
  '<b style="color: #00bed6;">Sí</b>, todos nuestros precios incluyen el IVA, <b style="color: #00bed6;">excepto en los casos en los que explícitamente hayamos indicado que el IVA se sumará a parte</b>.';
faq8.title = '¿Qué servicios incluyen vuestras tarifas?';
faq8.text_html =
  '<b style="color: #00bed6;">Siempre tendrás indicado lo que incluyen nuestras tarifas</b>. No obstante, no te preocupes, en caso de que haya algún gasto adicional siempre te avisaremos antes de contratar el servicio.';
faq9.title = '¿Puedo fraccionar los pagos?';
faq9.text_html =
  '<b style="color: #00bed6;">Sí</b>. En <span style="font-family: Quicksand;">enley</span> lo más importante eres tú y por eso queremos facilitarte las cosas adaptándonos a tus necesidades.';

const FAQS_HOME = [faq1, faq2, faq3, faq4, faq5, faq6, faq7, faq8, faq9];
