<label>
  <span>Mutua de Incapacidad Temporal</span>
  <div>
    <select
      [(ngModel)]="value"
      placeholder="Mutua de Incapacidad Temporal"
      (blur)="onBlur()"
      name="value"
      #mutualInput="ngModel"
      required
    >
      <option disabled [ngValue]="undefined">Selecciona una opción</option>
      <option value="MUTUA INTERCOMARCAL">MUTUA INTERCOMARCAL</option>
      <option value="MUTUA MONTAÑESA">MUTUA MONTAÑESA</option>
      <option value="MUTUA NAVARRA">MUTUA NAVARRA</option>
      <option value="MUTUA UNIVERSAL MUGENAT">MUTUA UNIVERSAL MUGENAT</option>
      <option value="MC MUTUA">MC MUTUA</option>
      <option value="UMIVALE">UMIVALE</option>
      <option value="SOLIMAT">SOLIMAT</option>
      <option value="UNIÓN DE MUTUAS (UNIMAT)">UNIÓN DE MUTUAS (UNIMAT)</option>
      <option value="ASEPEYO">ASEPEYO</option>
      <option value="FRATERNIDAD-MUPRESPA">FRATERNIDAD-MUPRESPA</option>
      <option value="FREMAP">FREMAP</option>
      <option value="MUTUALIA">MUTUALIA</option>
      <option value="MUTUA DE ACCIDENTES DE ZARAGOZA (MAZ)">
        MUTUA DE ACCIDENTES DE ZARAGOZA (MAZ)
      </option>
      <option value="MUTUA DE ACCIDENTES DE CANARIAS">
        MUTUA DE ACCIDENTES DE CANARIAS
      </option>
      <option value="MUTUA BALEAR">MUTUA BALEAR</option>
      <option value="ACTIVA MUTUA 2008">ACTIVA MUTUA 2008</option>
      <option value="MUTUA DE ANDALUCIA Y CEUTA – CESMA">
        MUTUA DE ANDALUCIA Y CEUTA – CESMA
      </option>
      <option value="EGARSAT">EGARSAT</option>
      <option value="IBERMUTUA">IBERMUTUA</option>
    </select>
  </div>
  <small
    *ngIf="
      mutualInput.errors &&
      mutualInput.errors.required &&
      (mutualInput.touched || form.submitted)
    "
    >Completa este campo.</small
  >
</label>
