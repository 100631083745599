import {
  APP_BASE_HREF,
  DOCUMENT,
  isPlatformBrowser,
  isPlatformServer,
  Location,
} from '@angular/common';

import {
  Component,
  OnInit,
  HostListener,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation,
  Optional,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Product } from 'src/app/models/products';
import { ContentType, setRegisterAnalytics } from 'src/app/models/register';
import { productToService, serviceSchema } from 'src/app/schemaOrg';
import { HeadService } from 'src/app/services/head.service';
import { UtilsServices } from 'src/app/services/utils.services';
import { environment } from 'src/environments/environment';
import { REFERER } from '../header/url';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  providers: [UtilsServices, HeadService],
  encapsulation: ViewEncapsulation.None,
})
export class ProductComponent implements OnInit {
  public innerWidth: any;
  public product: Product;
  public show_banner: boolean = false;
  public is_divorce_home: boolean;
  public show_banner_counter: number = 0;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    public _utilssv: UtilsServices,
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(DOCUMENT) private _document: Document,
    private analyticsService: AnalyticsService,

    private _renderer2: Renderer2,
    public sanitized: DomSanitizer,
    private _headService: HeadService,
    private _location: Location
  ) {}

  ngOnInit(): void {
    if (this._router.url.match('divorcios-express-online')) {
      this.is_divorce_home = true;
      if (isPlatformBrowser(this._platformId)) {
        window.onscroll = () => {
          if (this.show_banner_counter == 0 && this.is_divorce_home != true) {
            this.show_banner = true;
            this.show_banner_counter++;
          }
        };

        this.innerWidth = window.innerWidth;
      }
    }

    const head = this._headService.forRenderer(this._renderer2);
    head.createElement('link', {
      rel: 'preload',
      as: 'image',
      href: 'assets/img/como-funciona/divorcio-express-online-mobile.webp',
    });

    this._route.params.subscribe((params: Params) => {
      this.product = this._route.snapshot.data.data.product;
      if (isPlatformServer(this._platformId)) {
        const service = productToService(
          this.product,
          this._location,
          environment.frontUrl
        );
        head.createElement('script', {
          type: 'application/ld+json',
          [HeadService.ELEMENT_CONTENT]: JSON.stringify(serviceSchema(service)),
        });
      }
    });

    setRegisterAnalytics(
      this._route,
      this._platformId,
      this.analyticsService.getReferer(),
      ContentType.PRODUCT_HOME
    );
  }

  @HostListener('window:resize', ['$event'])
  onesize(event) {
    this.innerWidth = window.innerWidth;
  }
  onClick() {
    let x = document.querySelector('#preguntasSeccion');
    if (x) {
      x.scrollIntoView({ behavior: 'smooth' });
    }
  }
  closeBanner() {
    this.show_banner = false;
  }
}
