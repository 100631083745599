/* SERVICIOS PARA ANGULAR */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalApi } from './global';
import { Information } from '../models/information';
@Injectable()
export class InformationService {
  public url: string;
  public token: {};
  constructor(private _http: HttpClient) {
    this.url = globalApi.url;
  }

  getInformation(information): Observable<Information> {
    return this._http.post<Information>(this.url + 'information/', information);
  }

  getDescription(document_type_id: number): Observable<Information> {
    return this._http.get<Information>(
      this.url + 'document_type/' + document_type_id
    );
  }
}
