import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getErrorMessages } from 'src/utils/ng-forms-validators';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.sass'],
})
export class FormSelectComponent<T = unknown> {
  @Input() label: string = '';
  @Input() control: FormControl<T> | null = null;
  @Input() options: [string, string][] = [];
  @Input() pre: string = '';
  @Input() post: string = '';

  get errors(): string[] {
    return getErrorMessages(this.control);
  }
}
