import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
  query,
  stagger,
  keyframes,
} from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        height: '*',
        opacity: 1,
      })
    ),
    state(
      'out',
      style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden',
      })
    ),
    transition('in => out', [animate('600ms ease-in-out')]),
    transition('out => in', [animate('600ms ease-in-out')]),
  ]),
];

export const RotateAnimation = [
  trigger('rotateInOut', [
    state(
      'in',
      style({
        transform: 'rotate(180deg)',
      })
    ),
    state(
      'out',
      style({
        transform: 'rotate(0deg)',
      })
    ),
    transition('in => out', [animate('600ms ease-in-out')]),
    transition('out => in', [animate('600ms ease-in-out')]),
  ]),
];
