import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {ProductCategoryService} from 'src/app/services/productcategory.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  animations: [
    trigger('servicesTypesOpacity', [
      state(
        'show',
        style({
          opacity: '*',
        })
      ),
      state(
        'hide',
        style({
          opacity: '0',
        })
      ),
      transition('show => hide', [animate('300ms ease-out')]),
      transition('hide => show', [animate('300ms ease-in')]),
    ]),
  ],
})
export class ServicesComponent {
  public beginAnimation: boolean = false;
  public show: boolean = true;

  @Input() public backgroundColor: string = '';
  public forYou: boolean = true;
  public beginForYou: boolean = true;
  public beginforBusiness: boolean = false;
  public forBusiness: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private productCategoryService: ProductCategoryService
  ) {}

  public itemsForYou$ = this.productCategoryService.fetchCategories('b2c').pipe(
    map((response) => {
      return response.map((item) => {
        const services =
          item.subcategories && item.subcategories.length > 0
            ? item.subcategories.map((subcategory) => subcategory.name)
            : item.products.map((product) => product.product_description.name);

        return {
          title: item.name,
          services: services,
          buttonText: item.name.toUpperCase(),
          url: 'servicios-para-particulares/' + item.url,
          image: item.image_html.replace(/%20/g, '+'),
          image_svg: item.image.replace(/%20/g, '+'),
        };
      });
    })
  );

  public itemsForBusiness$ = this.productCategoryService
    .fetchCategories('b2b')
    .pipe(
      map((response) => {
        return response.map((item) => {
          const services =
            item.subcategories && item.subcategories.length > 0
              ? item.subcategories.map((subcategory) => subcategory.name)
              : item.products.map(
                  (product) => product.product_description.name
                );
          return {
            title: item.name,
            services: services,
            buttonText: item.name.toUpperCase(),
            url: 'servicios-para-tu-empresa/' + item.url,
            image: item.image_html.replace(/%20/g, '+'),
            image_svg: item.image.replace(/%20/g, '+'),
          };
        });
      })
    );

  updateYouServices() {
    this.show = false;
    setTimeout(() => {
      this.forBusiness = false;
      this.forYou = true;
    }, 300);
    setTimeout(() => {
      this.show = true;
    }, 300);
  }

  updateCompanyServices() {
    this.show = false;

    setTimeout(() => {
      this.forYou = false;
      this.forBusiness = true;
    }, 300);
    setTimeout(() => {
      this.show = true;
    }, 300);
  }
}
