<div *ngIf="displayedReviews.length" style="position: relative">
  <div
    class="carrousel"
    #carrousel
    (mouseenter)="onCarouselHover(true)"
    (mouseleave)="onCarouselHover(false)"
    (click)="onCarouselInteraction()"
    (touchstart)="onCarouselTouchStart()"
    (touchend)="onCarouselTouchEnd()"
  >
    <ng-container *ngFor="let review of displayedReviews">
      <app-review-home [review]="review"></app-review-home>
    </ng-container>
  </div>
  <div class="dots">
    <span
      *ngFor="let dot of dots; let i = index"
      [class.active]="currentDotIndex === i"
      (click)="scrollToPage(i)"
    ></span>
  </div>
</div>
