import {isPlatformBrowser} from '@angular/common';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';

@Component({
  selector: 'app-coockies-popup',
  templateUrl: './coockies-popup.component.html',
  styleUrls: ['./coockies-popup.component.css'],
})
export class CoockiesPopupComponent implements OnInit {
  public customCoockies: boolean = false;
  public mandatoryCoockies: boolean = true;
  public is_coockies_accepted = [];

  @Output() closePopUpEvent = new EventEmitter<boolean>();
  @Output() closeFooterPopUpEvent = new EventEmitter<string>();
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      var personalizedCookies = <HTMLInputElement>(
        document.getElementById('cookies')
      );
      personalizedCookies.checked = true;
    }
  }

  closePopUp() {
    this.closePopUpEvent.next(false);
  }

  acceptCookies() {
    var personalizedCookies = <HTMLInputElement>(
      document.getElementById('cookies')
    );
    var analyticCookies = <HTMLInputElement>(
      document.getElementById('analytic-cookies')
    );
    var mandatoryCookies = <HTMLInputElement>(
      document.getElementById('mandatory-cookies')
    );

    let acceptedCookies: {[key: string]: string} = {};

    acceptedCookies['marketing'] = personalizedCookies.checked ? 'yes' : 'no';
    acceptedCookies['analytic'] = analyticCookies.checked ? 'yes' : 'no';
    acceptedCookies['mandatory'] = mandatoryCookies.checked ? 'yes' : 'no';

    let date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    this.authService.setCookie(
      date,
      'is_coockies_accepted',
      JSON.stringify(acceptedCookies)
    );

    this.closeFooterPopUp(acceptedCookies);
    this.closePopUp();
  }

  closeFooterPopUp(acceptedCookies) {
    this.closeFooterPopUpEvent.next(acceptedCookies);
  }
}
