<section
  [ngClass]="backgroundColor === 'gray' ? 'can-do can-do--grey' : 'can-do'"
>
  <h2 class="can-do__main-title">Todo lo que podemos hacer</h2>
  <div class="can-do__container">
    <div class="can-do__item">
      <span> Para ti </span>
      <p>
        Nuestros abogados pueden asistirte en más de 7 áreas del derecho. Y si
        no encuentras el trámite que necesitas, contáctanos y te asesoraremos
        sin compromiso.
      </p>
      <a [routerLink]="['servicios-para-particulares']"
        ><b style="color: white">SERVICIOS PARA TI</b></a
      >
    </div>
    <div class="can-do__item">
      <span> Para tu empresa </span>
      <p>
        Si eres emprendedor, autónomo o tienes una empresa, tenemos los
        servicios más completos para ti y tu empresa, siempre acompañado por
        abogados y asesores mercantiles.
      </p>
      <a [routerLink]="['servicios-para-tu-empresa']"
        ><b style="color: white">SERVICIOS PARA TU EMPRESA</b></a
      >
    </div>
  </div>
</section>
