<div class="popupwrapper">
  <div class="text-container">
    <p
      class="cookies-title"
      style="font-size: 23px; margin-bottom: 13px !important"
    >
      Gestión de Cookies
    </p>
    <div class="personalized-container">
      <div class="type-slider">
        <p style="margin-bottom: 7px !important; font-size: 18px !important">
          Personalización
        </p>
        <div class="field" id="first" style="margin-left: 390px">
          <input
            class="switch-input"
            type="checkbox"
            id="cookies"
            name="cookies"
            value=" true"
            checked
          />
          <label for="cookies" class="switch">
            <span class="switch-label" data-on="Sí" data-off="No"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>

      <div class="list-cookies">
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Mostrar recomendaciones personalizadas basadas en tu navegación en
            otros sitios.
          </p>
        </div>
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Mostrar campañas personalizadas en otros sitios web.
          </p>
        </div>
      </div>
    </div>
    <div class="personalized-container">
      <div class="type-slider">
        <p style="margin-bottom: 7px !important; font-size: 18px !important">
          Analíticas
        </p>
        <div class="field" id="analytic" style="margin-left: 390px">
          <input
            class="switch-input"
            type="checkbox"
            id="analytic-cookies"
            name="analytic-cookies"
            value=" true"
            checked
          />
          <label for="analytic-cookies" class="switch">
            <span class="switch-label" data-on="Sí" data-off="No"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>

      <div class="list-cookies">
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Hacer seguimiento del número de visitantes y sus actuaciones.
          </p>
        </div>
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Uso anónimo de la información para ayudarnos a entender mejor cómo
            se usa nuestro sitio web, y así poder ofrecer una mejor experiencia
            al usuario.
          </p>
        </div>
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Identificar las líneas de negocio que generan un mayor interés entre
            nuestros usuarios/clientes.
          </p>
        </div>
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Informar de la no recopilación ni almacenamiento de información e
            intereses personales a través de este tipo de cookies.
          </p>
        </div>
      </div>
    </div>
    <div class="division-bar"></div>
    <div class="personalized-container">
      <div class="type-slider">
        <p
          style="
            margin-bottom: 7px !important;
            font-size: 18px !important;
            min-width: fit-content;
          "
        >
          Funcionales (obligatorio)
        </p>
        <div class="field" id="second" style="margin-left: 320px">
          <input
            class="switch-input"
            type="checkbox"
            id="mandatory-cookies"
            name="mandatory-cookies"
            disabled
            value="true"
            checked
          />
          <label for="mandatory-cookies" class="switch">
            <span
              class="switch-label disabled"
              style="border-color: gray"
              data-on="Sí"
              data-off="No"
            ></span>
            <span
              class="switch-handle"
              style="background: gray !important; opacity: 0.7"
            ></span>
          </label>
        </div>
      </div>
      <div class="list-cookies">
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Necesarias para navegar en este sitio y utilizar sus funciones.
          </p>
        </div>
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Identificarte como usuario y mantener tu sesión iniciada.
          </p>
        </div>
        <div class="text" style="display: flex !important">
          <span class="material-icons-outlined"> done </span>
          <p
            style="
              margin-left: 5px !important;
              margin-bottom: 5px !important;
              font-size: 15px !important;
            "
          >
            Personalizar tu experencia en base con tu navegador.
          </p>
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <a class="cancel-button" (click)="closePopUp()">CANCELAR</a>
      <button
        type="button"
        (click)="acceptCookies()"
        class=""
        style="
          background-color: #34bcd2;
          color: white;
          border-radius: 6px;
          background: #34bcd2;
          outline: none;
          width: 180px;
          height: 36px;
          font-weight: bold;
          font-size: 18px !important;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border: none;
          font-size: 14px;
        "
      >
        ACEPTAR
      </button>
    </div>
  </div>
</div>
