import {Component, Input, OnInit} from '@angular/core';
import {ReviewInformation} from 'src/app/models/googlereview';

@Component({
  selector: 'app-review-home',
  templateUrl: './review-home.component.html',
  styleUrls: ['./review-home.component.css'],
})
export class ReviewHomeComponent implements OnInit {
  @Input() review: ReviewInformation;

  ngOnInit() {
    if (this.review.comment) {
      this.review.comment = this.trimComment(this.review.comment);
    }
  }

  trimComment(str: string): string {
    return str.trim();
  }
}
