<section *ngIf="product.included[0] != ''" class="includes-section">
  <div class="left-text-wrapper">
    <h2 class="left-text" [innerHTML]="productTitle"></h2>
  </div>
  <div class="right-text-wrapper">
    <h3 class="right-text-title">¿Qué incluye?</h3>
    <ul class="includes-list">
      <li
        class="list-item"
        *ngFor="let item of product.included"
        [innerText]="item"
      ></li>
    </ul>
  </div>
</section>
