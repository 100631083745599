import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputRadioComponent,
    },
  ],
})
export class InputRadioComponent implements OnInit {
  constructor(private injector: Injector) {}
  @Input() value: string;
  @Input() storedAt: string;
  @Input() option: string;
  @Output() stateValue = new EventEmitter<string>();

  public form: NgForm;
  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  ngOnInit() {
    this.form = this.injector.get(NgForm, null);
  }

  writeValue(): void {}

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setValue(event: MouseEvent) {
    if (event.target instanceof HTMLInputElement) {
      this.stateValue.emit(this.value);
    }
  }
}
