import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductCategory } from '../models/productcategory';
import { HttpClient, HttpParams } from '@angular/common/http';
import { globalApi } from './global';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoryService {
  public url: string;

  constructor(private http: HttpClient) {
    this.url = globalApi.url;
  }

  fetchProductCategory(
    categoryUrl: string,
    type: string
  ): Observable<ProductCategory> {
    const urlGet = `${this.url}product_category/${type}/${categoryUrl}/`;
    return this.http.get<ProductCategory>(urlGet);
  }

  fetchProductCarouselCategory(type: string): Observable<ProductCategory[]> {
    const urlGet = `${this.url}product_category/${type}/carousel/`;
    return this.http.get<ProductCategory[]>(urlGet);
  }
  fetchCategories(type: string): Observable<ProductCategory[]> {
    const urlGet = `${this.url}product_category/${type}/`;
    return this.http.get<ProductCategory[]>(urlGet);
  }
  fetchAllCategories() {
    return this.http.get<ProductCategory[]>(this.url + 'product_category/');
  }
  get_subcategory_higher_order() {
    return this.http.get(this.url + 'product_category/subcategory_max_order/');
  }
}
