import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductsID } from 'src/app/models/contrato';
import { ContratoService } from 'src/app/services/contrato.services';
import { PrepagoService } from '../../services/payments/prepago.services';

@Component({
  selector: 'app-pagoerror',
  templateUrl: './pagoerror.component.html',
  styleUrls: ['./pagoerror.component.css'],
  providers: [PrepagoService, ContratoService],
})
export class PagoerrorComponent implements OnInit {
  public errorCode: string;
  public step: string;
  public idContract: string;
  public loading: boolean = true;
  public divorce_product_id: number = ProductsID.divorce_product_id;
  public inheritance_product_id: number = ProductsID.inheritance_product_id;
  public creditors_arrangement_id: number = ProductsID.creditors_arrangement_id;
  public payment_id: string;
  constructor(
    private _route: ActivatedRoute,
    private prepagosv: PrepagoService,
    private contratoService: ContratoService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      this.idContract = params.idContract;
      this.contratoService
        .getContrato(this.idContract)
        .subscribe((contractResponse) => {
          this.errorCode = params.errorCode;
          this.step = params.step;
          this.payment_id = params.idPayment;
          this.loading = false;
          if (this.step) {
            top.location.href =
              '/contrato/' +
              this.idContract +
              '/pre-pago/' +
              this.step +
              '/pago-error/' +
              this.errorCode;
          } else {
            top.location.href =
              '/contrato/' +
              this.idContract +
              '/pre-pago/pago/' +
              this.payment_id +
              '/pago-error/' +
              this.errorCode;
          }
        });
    });
  }
}
