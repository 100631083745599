import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { InvoiceFilter, InvoiceSubscriptions } from '../models/invoice';
import { download } from '../utils/api';
import { globalApi } from './global';
import { Subscription } from '../models/payment';
import { map } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = globalApi.url;
  }

  public invoicesSubject$: BehaviorSubject<InvoiceSubscriptions[]> =
    new BehaviorSubject<InvoiceSubscriptions[]>([]);
  public invoicesCountSubject$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public nextUrlSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  nextPagination(url: string) {
    return this.http.get<any>(url);
  }

  getInvoices(
    filter?: Partial<InvoiceFilter>, withLimit = true
  ): Observable<InvoiceSubscriptions[]> {
    
    let params = new HttpParams();
    if (filter) {
      for (let key in filter) {
        if (
          filter[key] &&
          filter[key] instanceof Array &&
          filter[key].length > 0
        ) {
          filter[key].forEach((item) => {
            params = params.append(key, item);
          });
        } else if (filter[key] && !(filter[key] instanceof Array)) {
          params = params.append(key, filter[key]);
        }
      }
    }

    const limitMax = withLimit ? 20 : Number.MAX_SAFE_INTEGER;
    const limit = Math.max(limitMax, this.invoicesSubject$.value.length);
    params = params.append('limit', limit.toString());

    return this.http
      .get<InvoiceSubscriptions[]>(invoiceUrl('all-invoices'), {
        params: params,
      })
      .pipe(
        map((invoices) => {
          this.setInvoicesCounter(invoices['count']);
          this.setNextUrl(invoices['next']);
          return invoices['results'];
        })
      );
  }

  setInvoices(invoices: InvoiceSubscriptions[]): void {
    this.invoicesSubject$.next(invoices);
  }

  setInvoicesCounter(count) {
    this.invoicesCountSubject$.next(count);
  }

  setNextUrl(nextUrl) {
    this.nextUrlSubject$.next(nextUrl);
  }

  createInvoices(newInvoices: InvoiceSubscriptions[]): Observable<string[]> {
    if (newInvoices.length > 0) {
      return this.http.post<string[]>(invoiceUrl(`create`), newInvoices);
    } else {
      return of([]);
    }
  }

  download(invoice?: InvoiceSubscriptions, invoice_id?: string): void {
    if (invoice) {
      invoice_id = invoice.invoice_id;
    }
    this.downloadAux(invoice_id).subscribe((url) => {
      download(url, 'invoice.pdf');
    });
  }

  downloadAux(invoice_id: string): Observable<string> {
    return this.http.get(invoiceUrl(`${invoice_id}/download-url`), {
      responseType: 'text',
    });
  }

  subscriptionInvoices(
    year: string,
    month: string
  ): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(invoiceUrl(`subscriptions/monthly`), {
      params: {
        year,
        month: parseInt(month) + 1,
      },
    });
  }

  downloadBankDraftExcel(year: string, month: string): Observable<Blob> {
    return this.http.get(invoiceUrl(`bank_draft_excel`), {
      responseType: 'blob',
      params: {
        year,
        month: parseInt(month) + 1,
      },
    });
  }
}

function invoiceUrl(path: string | URL): string {
  return new URL(path, new URL('invoices/', globalApi.url)).href;
}
