import {isPlatformBrowser} from '@angular/common';
import {AfterViewInit} from '@angular/core';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Optional,
  LOCALE_ID,
  InjectionToken,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {html} from 'lit';
import {render as ssrRender} from '@lit-labs/ssr';
import {SettingsID} from 'src/app/models/administration';
import {AuthService} from 'src/app/services/auth.service';
import {COOKIES} from '../header/url';
import {collectResultSync} from '@lit-labs/ssr/lib/render-result';

declare let gtag: Function;
declare let window: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit, AfterViewInit {
  public whatsapp_setting_id: number = SettingsID.whatsapp_setting_id;
  public is_coockies_accepted: {[key: string]: string} = {};
  public config_pop_up: boolean = false;
  public innerWidth: any;
  public mobile: boolean = false;
  @ViewChild('footer') footer: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(COOKIES) public cookies: any,
    @Inject(LOCALE_ID) private localeId: InjectionToken<string>,
    private authService: AuthService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    if (this.authService.getCookie('is_coockies_accepted')) {
      let cookies = this.authService.getCookie('is_coockies_accepted');
      this.is_coockies_accepted = JSON.parse(cookies);
    }

    if (isPlatformBrowser(this.platformId)) {
      if (this.isMobile(window.innerWidth)) this.mobile = true;
      else this.mobile = false;
    }
  }

  async ngAfterViewInit(): Promise<void> {
    switch (this.localeId.toString()) {
        case 'es':
          await import('@enley-es/web-components/es/footer');
        case 'ca':
          await import('@enley-es/web-components/ca/footer');
      }

    const footerHTML = collectResultSync(
      ssrRender(html`<footer-element></footer-element>`)
    );
    this.renderer.setProperty(
      this.footer.nativeElement,
      'innerHTML',
      footerHTML
    );
  }

  @HostListener('window:resize', ['$event'])
  onesize(event) {
    if (this.isMobile(window.innerWidth)) this.mobile = true;
    else this.mobile = false;
  }

  isMobile(width) {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth <= 918) return true;
      else return false;
    } else {
      return false;
    }
  }

  acceptCoockies() {
    this.is_coockies_accepted['marketing'] = 'yes';
    this.is_coockies_accepted['analytic'] = 'yes';
    this.is_coockies_accepted['mandatory'] = 'yes';
    let date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    this.authService.setCookie(
      date,
      'is_coockies_accepted',
      JSON.stringify(this.is_coockies_accepted)
    );
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
    });
  }

  configurationPopUp() {
    this.config_pop_up = true;
  }

  closePopUp(event) {
    this.config_pop_up = false;
  }

  closeFooterPopUp(event) {
    this.is_coockies_accepted = event;
  }
}
