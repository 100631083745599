<!-- gif -->
<div class="loading" *ngIf="loadingGif">
  <div class="gif">
    <img
      class=""
      width="150px"
      height="150px"
      src="assets/img/loading-gif.gif"
      alt=""
    />
    <p class="cargandoText">
      {{ B ? "Creando tu caso..." : "Realizando tu pre-estudio..." }}
    </p>
  </div>
</div>
<!-- gif -->

<!-- PopUp -->
<div
  *ngIf="showPopUpContract"
  class="loading"
  [ngStyle]="{ 'z-index': loading ? 2002 : 1500 }"
></div>
<div *ngIf="showPopUpContract" class="popup">
  <ng-container *ngIf="product.accept_repeated">
    Hemos detectado que ya tienes un caso creado de {{ this.product_title }},
    ¿seguro que quieres crear un nuevo caso?<br />
    <div class="positionButton">
      <button
        type="button"
        class="btn btn-custom classHover popButton"
        (click)="goToMyContract()"
      >
        No
      </button>
      <button
        type="button"
        class="btn btn-custom classHover popButton"
        (click)="createNewContract()"
      >
        Sí
      </button>
    </div>
    <br />
    Si respondes que no, te llevaremos a él.
  </ng-container>
  <ng-container *ngIf="!product.accept_repeated">
    Hemos detectado que ya tienes un caso creado de {{ this.product_title
    }}<br />
    <div class="positionButton">
      <button
        type="button"
        class="btn btn-custom classHover popButton"
        style="width: 100px"
        (click)="goToMyContract()"
      >
        Ver caso
      </button>
    </div>
  </ng-container>
</div>
<!-- PopUp -->

<form
  #form="ngForm"
  *ngIf="product && product.form_type !== 2 && formType !== 3"
  (keydown.enter)="$event.preventDefault()"
  (ngSubmit)="submit()"
>
  <div
    *ngIf="step === 1"
    id="box-register"
    class="right-rectangle-fr"
    [ngStyle]="{ 'background-color': bg_color ? bg_color : product.color_html }"
  >
    <p class="text-up" [ngStyle]="{ color: text_color ? text_color : '' }">
      Te ofrecemos un pre-estudio personalizado y gratuito sobre la
      <span *ngIf="product.id !== 7">tramitación</span
      ><span *ngIf="product.id === 7">creación</span> de tu
      {{ product.title_trans }}.
    </p>
    <div class="register-form-fr">
      <div
        class="form-group"
        *ngFor="let item of product.contract_fields; let i = index"
      >
        <ng-container
          *ngIf="
            item.type === 'boolean' &&
            (item.dependent_field == undefined ||
              (item.dependent_field != undefined &&
                contract[item.dependent_field.name] ===
                  item.dependent_field_value))
          "
        >
          <select
            name="{{ item.name }}"
            #template="ngModel"
            [(ngModel)]="contract[item.name]"
            [required]="item.required"
            class="form-control"
          >
            <option value="undefined" disabled>
              {{ item.contractfieldtranslation.label }}
            </option>
            <option value="true">SÍ</option>
            <option value="false">NO</option>
          </select>
          {{ this[item.name] }}
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </ng-container>
        <ng-container
          *ngIf="
            item.type === 'choices' &&
            (item.dependent_field == undefined ||
              (item.dependent_field != undefined &&
                contract[item.dependent_field.name] ===
                  item.dependent_field_value))
          "
        >
          <select
            id="{{ item.name }}"
            name="{{ item.name }}"
            #template="ngModel"
            [(ngModel)]="contract[item.name]"
            [required]="item.required"
            class="form-control"
          >
            <option value="undefined" disabled>
              {{ item.contractfieldtranslation.label }}
            </option>
            <option
              *ngFor="let optionItem of item.choicesfield_set"
              [ngValue]="optionItem.value"
            >
              {{ optionItem.name }}
            </option>
          </select>
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </ng-container>
        <ng-container
          *ngIf="
            item.type === 'string' &&
            (item.dependent_field == undefined ||
              (item.dependent_field != undefined &&
                contract[item.dependent_field.name] ===
                  item.dependent_field_value))
          "
        >
          <input
            type="text"
            id="{{ item.name }}"
            name="{{ item.name }}"
            #template="ngModel"
            [(ngModel)]="contract[item.name]"
            [required]="item.required"
            class="form-control"
            placeholder="{{ item.contractfieldtranslation.label }}"
          />
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </ng-container>

        <ng-container
          *ngIf="
            item.type === 'date' &&
            (item.dependent_field == undefined ||
              (item.dependent_field != undefined &&
                contract[item.dependent_field.name] ===
                  item.dependent_field_value))
          "
        >
          <div class="form-floating">
            <input
              type="date"
              id="{{ item.name }}"
              class="form-control floatingDate"
              name="{{ item.name }}"
              #template="ngModel"
              [(ngModel)]="contract[item.name]"
              [required]="item.required"
              placeholder="{{ item.contractfieldtranslation.label }}"
            />
            <label class="floating-label">{{
              item.contractfieldtranslation.label
            }}</label>
            <small
              *ngIf="
                !template.valid &&
                (form.submitted || next) &&
                template.errors.required
              "
              style="color: #fecc00"
            >
              Completa este campo
            </small>
            <small
              *ngIf="
                !template.valid &&
                (form.submitted || next) &&
                template.errors.max
              "
              style="color: #fecc00"
            >
              La fecha no puede ser posterior a la fecha actual.
            </small>
            <small
              *ngIf="!template.valid && template.errors != null"
              style="color: #fecc00"
            >
              {{ template.errors.message }}
            </small>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            item.type === 'decimal' &&
            (item.dependent_field == undefined ||
              (item.dependent_field != undefined &&
                contract[item.dependent_field.name] ===
                  item.dependent_field_value))
          "
        >
          <input
            type="number"
            id="{{ item.name }}"
            name="{{ item.name }}"
            #template="ngModel"
            [(ngModel)]="contract[item.name]"
            [required]="item.required"
            class="form-control"
            placeholder="{{ item.contractfieldtranslation.label }}"
          />
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </ng-container>
        <ng-container
          *ngIf="
            item.type === 'country' &&
            (item.dependent_field == undefined ||
              (item.dependent_field != undefined &&
                contract[item.dependent_field.name] ===
                  item.dependent_field_value))
          "
        >
          <ng-autocomplete
            id="{{ item.contractfieldtranslation.label }}"
            [data]="dataCountry"
            placeholder="{{ item.contractfieldtranslation.label }}"
            [itemTemplate]="itemTemplate"
            #template="ngModel"
            [(ngModel)]="contract[item.name]"
            [minQueryLength]="'3'"
            [isLoading]="countryLoading"
            (inputChanged)="getCountries($event)"
            name="{{ item.name }}"
          >
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <p [innerHTML]="item"></p>
          </ng-template>
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </ng-container>

        <ng-container
          *ngIf="
            item.type === 'autonomous_community' &&
            item.dependent_field == undefined
          "
        >
          <ng-autocomplete
            [data]="dataComunity"
            placeholder="{{ item.contractfieldtranslation.label }}"
            [itemTemplate]="itemTemplate"
            #template="ngModel"
            [(ngModel)]="contract[item.name]"
            id="{{ item.contractfieldtranslation.label }}"
            name="{{ item.name }}"
            [minQueryLength]="'3'"
            [isLoading]="communityLoading"
            (inputChanged)="getCommunitiy($event)"
          >
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <p [innerHTML]="item"></p>
          </ng-template>
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </ng-container>
        <ng-container
          *ngIf="item.type === 'province' && item.dependent_field == undefined"
        >
          <ng-autocomplete
            [data]="dataProvince"
            placeholder="{{ item.contractfieldtranslation.label }}"
            [minQueryLength]="'3'"
            [isLoading]="provinceLoading"
            (inputChanged)="getProvinces($event)"
            [itemTemplate]="itemTemplate"
            id="{{ item.contractfieldtranslation.label }}"
            #template="ngModel"
            [(ngModel)]="contract[item.name]"
            name="{{ item.name }}"
          >
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <p [innerHTML]="item"></p>
          </ng-template>
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </ng-container>
      </div>
    </div>
    <input
      *ngIf="!isLoggedIn"
      type="button"
      id="pre-estudio-1"
      value="PASO 1 DE 2"
      class="btn-fr arrow"
      (click)="changeStep(2)"
      [ngStyle]="{
        'background-color':
          product.color_html === '#13bfd6' ? '#00788e' : '#13bfd6'
      }"
    />
    <input
      *ngIf="isLoggedIn"
      id="pre-estudio-2"
      type="submit"
      value="SOLICITAR  PRE-ESTUDIO"
      class="btn-fr"
      [ngStyle]="{
        'background-color':
          product.color_html === '#13bfd6' ? '#00788e' : '#13bfd6'
      }"
    />
  </div>
  <div
    *ngIf="step === 2"
    id="box-register"
    class="right-rectangle-fr"
    [ngStyle]="{ 'background-color': bg_color ? bg_color : product.color_html }"
  >
    <p
      *ngIf="!B"
      class="text-up"
      [ngStyle]="{ color: text_color ? text_color : '' }"
    >
      Facilítanos tus datos de contacto:
    </p>
    <div class="register-form-fr">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input
              type="text"
              id="first_name"
              name="first_name"
              #first_name="ngModel"
              [(ngModel)]="register.first_name"
              required
              placeholder="Nombre *"
            />
            <small
              *ngIf="
                !first_name.valid &&
                form.submitted &&
                first_name.errors.required
              "
              style="color: #fecc00"
            >
              Completa este campo
            </small>
            <small
              *ngIf="!first_name.valid && first_name.errors != null"
              style="color: #fecc00"
            >
              {{ first_name.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input
              type="text"
              id="last_name"
              name="last_name"
              #last_name="ngModel"
              [(ngModel)]="register.last_name"
              required
              placeholder="Apellidos *"
            />
            <small
              *ngIf="
                !last_name.valid &&
                form.submitted &&
                last_name.errors.required
              "
              style="color: #fecc00"
            >
              Completa este campo
            </small>
            <small
              *ngIf="!last_name.valid && last_name.errors != null"
              style="color: #fecc00"
            >
              {{ last_name.errors.message }}
            </small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="email"
          id="email1"
          name="email"
          #email="ngModel"
          [(ngModel)]="register.email"
          required
          placeholder="Escribe tu correo electrónico  *"
        />
        <small
          *ngIf="!email.valid && form.submitted && email.errors.required"
          style="color: #fecc00"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!email.valid && email.errors != null"
          style="color: #fecc00"
        >
          {{ email.errors.message }}
        </small>
      </div>
      <ng-container *ngIf="with_password">
        <div class="form-group">
          <input
            type="password"
            autocomplete="off"
            id="password1"
            name="password1"
            #password1="ngModel"
            [(ngModel)]="register.password1"
            required
            placeholder="Crea tu contraseña  *"
          />
          <small
            *ngIf="
              !password1.valid && form.submitted && password1.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
          <small
            *ngIf="!password1.valid && password1.errors != null"
            style="color: #fecc00"
          >
            {{ password1.errors.message }}
          </small>
        </div>
      </ng-container>
      <div class="form-group">
        <app-custom-phone
          [custom_class]="'custom-box-register'"
          style="width: 100%"
          [placeholder]="'Teléfono *'"
          required
          name="phone_number"
          #phone_number="ngModel"
          [(ngModel)]="register.phone_number"
        ></app-custom-phone>

        <small
          *ngIf="
            !phone_number.valid &&
            form.submitted &&
            phone_number.errors.required
          "
          style="color: #fecc00"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!phone_number.valid && phone_number.errors != null"
          style="color: #fecc00"
        >
          {{ phone_number.errors.message }}
        </small>
        <small *ngIf="nonFieldErrors" style="color: #fecc00">
          {{ nonFieldErrors }}
        </small>
      </div>
    </div>
    <div class="form-group-c-contain">
      <label class="c-contain">
        <span class="holderLabelNoHover privacidadTextStyle"
          >He leído y acepto la
          <a [routerLink]="['/politica-de-cookies']" class="privacidadTextStyle"
            >política de privacidad</a
          ></span
        >
        <input
          type="checkbox"
          name="gdpr_accepted"
          id="gdpr_accepted"
          #gdpr_accepted="ngModel"
          [(ngModel)]="register.gdpr_accepted"
          required=""
        />
        <div
          class="c-input"
          [ngStyle]="{
            'background-color': bg_color ? 'white' : product.color_html
          }"
          [ngClass]="bg_color ? 'gray' : ''"
        ></div>
      </label>
      <small
        *ngIf="
          !gdpr_accepted.valid &&
          form.submitted &&
          gdpr_accepted.errors.required
        "
        style="color: #fecc00"
      >
        Debes aceptar la política de privacidad
      </small>
    </div>
    <div class="form-group-c-contain">
      <label class="c-contain">
        <span class="holderLabelNoHover privacidadTextStyle">
          Acepto recibir comunicaciones comerciales de
          <span style="font-family: Quicksand" class="privacidadTextStyle"
            >enley</span
          >
          que puedan ser de mi interés, con la posibilidad de revocar mi
          consentimiento en cualquier momento.
        </span>
        <input
          type="checkbox"
          id="receive_advertising"
          name="receive_advertising"
          #receive_advertising="ngModel"
          [(ngModel)]="register.enley_accepted"
        />
        <div
          class="c-input"
          [ngStyle]="{
            'background-color': bg_color ? 'white' : product.color_html
          }"
          [ngClass]="bg_color ? 'gray' : ''"
        ></div>
      </label>
    </div>
    <div class="row">
      <div class="col-4" *ngIf="!B">
        <input
          id="pre-estudio-2"
          type="button"
          (click)="changeStep(1)"
          value="ATRÁS"
          class="btn-fr-left arrow"
          [ngStyle]="{
            'background-color':
              product.color_html === '#13bfd6' ? '#00788e' : '#13bfd6'
          }"
        />
      </div>
      <div [ngClass]="[B ? 'col' : 'col-8']">
        <input
          id="pre-estudio-2"
          type="submit"
          [value]="B ? 'COMENZAR' : 'SOLICITAR  PRE-ESTUDIO'"
          class="btn-fr"
          [ngStyle]="{
            'background-color':
              product.color_html === '#13bfd6' ? '#00788e' : '#13bfd6'
          }"
        />
      </div>
    </div>
  </div>
</form>

<!-- Option 3 -->
<form
  #form="ngForm"
  *ngIf="product && product.id === 2 && formType === 3"
  class="iframe"
  [ngClass]="{ 'bottom-iframe': iframe_bottom === 'true' }"
  (keydown.enter)="$event.preventDefault()"
  (ngSubmit)="submit()"
>
  <div
    id="box-register"
    class="right-rectangle-fr"
    style="background-color: #d8d8d8"
  >
    <div class="center-content">
      <p class="text-up text-center" *ngIf="!innerWidth">
        Solicita información <br />
        sin ningún compromiso
      </p>
      <p class="text-up text-center" *ngIf="innerWidth">
        Solicita información sin ningún compromiso
      </p>

      <div class="register-form-fr">
        <p class="text-up text-left">Tus datos</p>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <input
                type="text"
                id="first_name"
                name="first_name"
                #first_name="ngModel"
                [(ngModel)]="register.first_name"
                required
                placeholder="Nombre *"
              />
              <small
                *ngIf="
                  !first_name.valid &&
                  form.submitted &&
                  first_name.errors.required
                "
                style="color: #fecc00"
              >
                Completa este campo
              </small>
              <small
                *ngIf="!first_name.valid && first_name.errors != null"
                style="color: #fecc00"
              >
                {{ first_name.errors.message }}
              </small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <input
                type="text"
                id="first_name"
                name="last_name"
                #last_name="ngModel"
                [(ngModel)]="register.last_name"
                required
                placeholder="Apellido*"
              />
              <small
                *ngIf="
                  !last_name.valid &&
                  form.submitted &&
                  last_name.errors.required
                "
                style="color: #fecc00"
              >
                Completa este campo
              </small>
              <small
                *ngIf="!last_name.valid && last_name.errors != null"
                style="color: #fecc00"
              >
                {{ last_name.errors.message }}
              </small>
            </div>
          </div>
        </div>
        <div class="row display-block">
          <div class="col">
            <div class="form-group">
              <input
                type="email"
                id="email1"
                name="email"
                #email="ngModel"
                [(ngModel)]="register.email"
                required
                placeholder="Escribe tu correo electrónico*"
              />
              <small
                *ngIf="!email.valid && form.submitted && email.errors.required"
                style="color: #fecc00"
              >
                Completa este campo
              </small>
              <small
                *ngIf="!email.valid && email.errors != null"
                style="color: #fecc00"
              >
                {{ email.errors.message }}
              </small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <app-custom-phone
                [custom_class]="'custom-box-register-iframe'"
                style="width: 100%"
                [placeholder]="'Teléfono*'"
                required
                name="phone_number"
                #phone_number="ngModel"
                [(ngModel)]="register.phone_number"
              ></app-custom-phone>

              <small
                *ngIf="
                  !phone_number.valid &&
                  form.submitted &&
                  phone_number.errors.required
                "
                style="color: #fecc00"
              >
                Completa este campo
              </small>
              <small
                *ngIf="!phone_number.valid && phone_number.errors != null"
                style="color: #fecc00"
              >
                {{ phone_number.errors.message }}
              </small>
              <small *ngIf="nonFieldErrors" style="color: #fecc00">
                {{ nonFieldErrors }}
              </small>
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="
            register.first_name &&
            register.last_name &&
            register.email &&
            register.phone_number
          "
        >
          <p class="text-up text-left">Datos del difunto</p>

          <div
            class="form-group"
            *ngFor="let item of product.contract_fields; let i = index"
          >
            <ng-container
              *ngIf="
                item.type === 'boolean' &&
                (item.dependent_field == undefined ||
                  (item.dependent_field != undefined &&
                    contract[item.dependent_field.name] ===
                      item.dependent_field_value))
              "
            >
              <select
                name="{{ item.name }}"
                #template="ngModel"
                [(ngModel)]="contract[item.name]"
                [required]="item.required"
                class="form-control"
              >
                <option value="undefined" disabled>
                  {{ item.contractfieldtranslation.label }}
                </option>
                <option value="true">SÍ</option>
                <option value="false">NO</option>
              </select>
              {{ this[item.name] }}
              <small
                *ngIf="
                  !template.valid &&
                  (form.submitted || next) &&
                  template.errors.required
                "
                style="color: #fecc00"
              >
                Completa este campo
              </small>
            </ng-container>

            <ng-container
              *ngIf="
                item.type === 'date' &&
                (item.dependent_field == undefined ||
                  (item.dependent_field != undefined &&
                    contract[item.dependent_field.name] ===
                      item.dependent_field_value))
              "
            >
              <div class="form-floating">
                <input
                  type="date"
                  id="{{ item.name }}"
                  class="form-control floatingDate"
                  name="{{ item.name }}"
                  #template="ngModel"
                  [(ngModel)]="contract[item.name]"
                  [required]="item.required"
                  placeholder="{{ item.contractfieldtranslation.label }}"
                />
                <label class="floating-label">{{
                  item.contractfieldtranslation.label
                }}</label>
                <small
                  *ngIf="
                    !template.valid &&
                    (form.submitted || next) &&
                    template.errors.required
                  "
                  style="color: #fecc00"
                >
                  Completa este campo
                </small>
                <small
                  *ngIf="
                    !template.valid &&
                    (form.submitted || next) &&
                    template.errors.max
                  "
                  style="color: #fecc00"
                >
                  La fecha no puede ser posterior a la fecha actual.
                </small>
                <small
                  *ngIf="!template.valid && template.errors != null"
                  style="color: #fecc00"
                >
                  {{ template.errors.message }}
                </small>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                item.type === 'country' &&
                (item.dependent_field == undefined ||
                  (item.dependent_field != undefined &&
                    contract[item.dependent_field.name] ===
                      item.dependent_field_value))
              "
            >
              <ng-autocomplete
                id="{{ item.contractfieldtranslation.label }}"
                [data]="dataCountry"
                placeholder="{{ item.contractfieldtranslation.label }}"
                [itemTemplate]="itemTemplate"
                #template="ngModel"
                [(ngModel)]="contract[item.name]"
                [minQueryLength]="'3'"
                [isLoading]="countryLoading"
                (inputChanged)="getCountries($event)"
                name="{{ item.name }}"
              >
              </ng-autocomplete>
              <ng-template #itemTemplate let-item>
                <p [innerHTML]="item"></p>
              </ng-template>
              <small
                *ngIf="
                  !template.valid &&
                  (form.submitted || next) &&
                  template.errors.required
                "
                style="color: #fecc00"
              >
                Completa este campo
              </small>
            </ng-container>
          </div>
        </ng-container>
        <div class="form-group-c-contain" style="margin-bottom: 5px">
          <label class="c-contain">
            <span class="holderLabelNoHover privacidadTextStyle"
              >He leído y acepto la
              <a
                [routerLink]="['/politica-de-cookies']"
                class="privacidadTextStyle"
                >política de privacidad</a
              ></span
            >
            <input
              type="checkbox"
              name="gdpr_accepted"
              id="gdpr_accepted"
              #gdpr_accepted="ngModel"
              [(ngModel)]="register.gdpr_accepted"
              required=""
            />
            <div
              class="c-input"
              [ngStyle]="{ 'background-color': product.color_html }"
            ></div>
          </label>
          <small
            *ngIf="
              !gdpr_accepted.valid &&
              form.submitted &&
              gdpr_accepted.errors.required
            "
            style="color: #fecc00"
          >
            Debes aceptar la política de privacidad
          </small>
        </div>
        <div class="form-group-c-contain" style="margin-bottom: 5px">
          <label class="c-contain">
            <span class="holderLabelNoHover privacidadTextStyle"
              >Acepto recibir comunicaciones comerciales de
              <span style="font-family: Quicksand" class="privacidadTextStyle"
                >enley</span
              >
              que puedan ser de mi interés, con la posibilidad de revocar mi
              consentimiento en cualquier momento.
            </span>
            <input
              type="checkbox"
              id="receive_advertising"
              name="receive_advertising"
              #receive_advertising="ngModel"
              [(ngModel)]="register.enley_accepted"
            />
            <div
              class="c-input"
              [ngStyle]="{ 'background-color': product.color_html }"
            ></div>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            id="pre-estudio-2"
            type="submit"
            value="Solicitar"
            class="btn-fr"
            [ngStyle]="{
              'background-color':
                product.color_html === '#13bfd6' ? '#00788e' : '#13bfd6'
            }"
          />
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Option 3 -->

<!-- Option 2-->
<form
  #form="ngForm"
  *ngIf="product.form_type === 2"
  (keydown.enter)="$event.preventDefault()"
  (ngSubmit)="submit()"
>
  <div
    class="right-rectangle-fr"
    id="box-register"
    *ngIf="actual_contract_field != undefined"
    [ngStyle]="{ 'background-color': product.color_html }"
  >
    <a *ngIf="step > 1" class="atras" (click)="previousStep()">Atrás</a
    ><br /><br />

    <p class="text-up">
      <span
        *ngIf="
          step > 1 &&
          actual_contract_field.contractfieldtranslation.description !== ''
        "
        ><b>{{ step - 1 }}. </b></span
      ><b
        [innerHTML]="actual_contract_field.contractfieldtranslation.description"
      ></b>
    </p>

    <p class="text-up"></p>
    <div class="register-form-fr">
      <ng-container *ngIf="actual_contract_field.type === 'boolean'">
        <p class="text-up">
          {{ actual_contract_field.contractfieldtranslation.label }}
        </p>

        <div class="row">
          <div class="col-6">
            <input
              id="pre-estudio-2"
              type="button"
              (click)="nextStep(true)"
              value="Sí"
              class="btn-fr-left"
              style="color: #00788e; background-color: #fff"
            />
          </div>
          <div class="col-6">
            <input
              id="pre-estudio-2"
              type="button"
              (click)="nextStep(false)"
              value="NO"
              class="btn-fr-left"
            />
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          actual_contract_field.type === 'choices' &&
          actual_contract_field.choicesfield_set.length > 2 &&
          isMobile()
        "
      >
        <p class="text-up">
          {{ actual_contract_field.contractfieldtranslation.label }}
        </p>
        <select
          name="{{ actual_contract_field.name }}"
          #template="ngModel"
          [(ngModel)]="contract[actual_contract_field.name]"
          [required]="actual_contract_field.required"
          class="form-control"
        >
          <option value="undefined" disabled>
            {{ actual_contract_field.contractfieldtranslation.name }}
          </option>
          <option
            *ngFor="
              let optionactual_contract_field of actual_contract_field.choicesfield_set
            "
            [ngValue]="optionactual_contract_field.value"
          >
            {{ optionactual_contract_field.name }}
          </option>
        </select>
        <small
          *ngIf="
            !template.valid &&
            (form.submitted || next) &&
            template.errors.required
          "
          style="color: #fecc00"
        >
          Completa este campo
        </small>
        <button type="button" class="btn-next" (click)="nextStep()">
          Siguiente
        </button>
      </ng-container>
      <ng-container
        *ngIf="
          actual_contract_field.type === 'choices' &&
          actual_contract_field.choicesfield_set.length > 2 &&
          !isMobile()
        "
      >
        <p class="text-up">
          {{ actual_contract_field.contractfieldtranslation.label }}
        </p>
        <select
          (blur)="nextStepSelect()"
          name="{{ actual_contract_field.name }}"
          #template="ngModel"
          [(ngModel)]="contract[actual_contract_field.name]"
          [required]="actual_contract_field.required"
          class="form-control"
        >
          <option value="undefined" disabled>
            {{ actual_contract_field.contractfieldtranslation.name }}
          </option>
          <option
            (blur)="nextStepSelect()"
            *ngFor="
              let optionactual_contract_field of actual_contract_field.choicesfield_set
            "
            [ngValue]="optionactual_contract_field.value"
          >
            {{ optionactual_contract_field.name }}
          </option>
        </select>
        <small
          *ngIf="
            !template.valid &&
            (form.submitted || next) &&
            template.errors.required
          "
          style="color: #fecc00"
        >
          Completa este campo
        </small>
      </ng-container>
      <ng-container
        *ngIf="
          actual_contract_field.type === 'choices' &&
          actual_contract_field.choicesfield_set.length === 2
        "
      >
        <p class="text-up">
          {{ actual_contract_field.contractfieldtranslation.label }}
        </p>
        <div class="row">
          <div class="col-6">
            <input
              id="pre-estudio-2"
              type="button"
              (click)="
                nextStep(actual_contract_field.choicesfield_set[0].value)
              "
              value="{{ actual_contract_field.choicesfield_set[0].name }}"
              class="btn-fr-left"
              style="color: #00788e; background-color: #fff"
            />
          </div>
          <div class="col-6">
            <input
              id="pre-estudio-2"
              type="button"
              (click)="
                nextStep(actual_contract_field.choicesfield_set[1].value)
              "
              value="{{ actual_contract_field.choicesfield_set[1].name }}"
              class="btn-fr-left"
            />
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="actual_contract_field.type === 'country'">
        <ng-autocomplete
          [data]="dataCountry"
          [minQueryLength]="'3'"
          [isLoading]="countryLoading"
          (inputChanged)="getCountries($event)"
          placeholder="{{
            actual_contract_field.contractfieldtranslation.label
          }}"
          [actual_contract_fieldTemplate]="actual_contract_fieldTemplate"
          #template="ngModel"
          [(ngModel)]="contract[actual_contract_field.name]"
          id="{{ actual_contract_field.contractfieldtranslation.label }}"
          name="{{ actual_contract_field.name }}"
          (selected)="nextStep($event)"
        >
        </ng-autocomplete>
        <ng-template #actual_contract_fieldTemplate let-actual_contract_field>
          <p [innerHTML]="actual_contract_field"></p>
        </ng-template>
        <small
          *ngIf="
            !template.valid &&
            (form.submitted || next) &&
            template.errors.required
          "
          style="color: #fecc00"
        >
          Completa este campo
        </small>
      </ng-container>
      <ng-container
        *ngIf="actual_contract_field.type === 'autonomous_community'"
      >
        <div class="arras-box-register">
          <ng-autocomplete
            [data]="dataComunity"
            [minQueryLength]="'3'"
            [isLoading]="communityLoading"
            (inputChanged)="getCommunitiy($event)"
            placeholder="{{
              actual_contract_field.contractfieldtranslation.label
            }}"
            [itemTemplate]="itemTemplate"
            #template="ngModel"
            [(ngModel)]="contract[actual_contract_field.name]"
            id="{{ actual_contract_field.contractfieldtranslation.label }}"
            name="{{ actual_contract_field.name }}"
            (selected)="nextStep($event)"
          >
          </ng-autocomplete>
          <ng-template #itemTemplate let-actual_contract_field>
            <p [innerHTML]="actual_contract_field"></p>
          </ng-template>
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </div>
      </ng-container>

      <ng-container *ngIf="actual_contract_field.type === 'province'">
        <div class="arras-box-register">
          <ng-autocomplete
            [data]="dataProvince"
            [minQueryLength]="'3'"
            [isLoading]="provinceLoading"
            (inputChanged)="getProvinces($event)"
            placeholder="{{
              actual_contract_field.contractfieldtranslation.label
            }}"
            [itemTemplate]="itemTemplate"
            #template="ngModel"
            [(ngModel)]="contract[actual_contract_field.name]"
            id="{{ actual_contract_field.contractfieldtranslation.label }}"
            name="{{ actual_contract_field.name }}"
            (selected)="nextStep($event)"
          >
          </ng-autocomplete>
          <ng-template #itemTemplate let-actual_contract_field>
            <p [innerHTML]="actual_contract_field"></p>
          </ng-template>
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
        </div>
      </ng-container>

      <ng-container *ngIf="actual_contract_field.type === 'date'">
        <div class="form-floating">
          <input
            type="date"
            id="date"
            class="form-control floatingDate"
            id="{{ actual_contract_field.name }}"
            name="{{ actual_contract_field.name }}"
            #template="ngModel"
            [(ngModel)]="contract[actual_contract_field.name]"
            [required]="actual_contract_field.required"
            appMaxDate=""
            placeholder="{{
              actual_contract_field.contractfieldtranslation.label
            }}"
            (change)="nextStep()"
          />
          <label class="floating-label">{{
            actual_contract_field.contractfieldtranslation.label
          }}</label>
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
          <small
            *ngIf="
              !template.valid && (form.submitted || next) && template.errors.max
            "
            style="color: #fecc00"
          >
            La fecha no puede ser posterior a la fecha actual.
          </small>
        </div>
      </ng-container>
      <ng-container
        *ngIf="actual_contract_field.type === 'decimal'"
        (change)="nextStep()"
      >
        <div style="display: flex" class="form-floating placeholder-not-show">
          <input
            type="number"
            id="{{ actual_contract_field.name }}"
            name="{{ actual_contract_field.name }}"
            #template="ngModel"
            [(ngModel)]="contract[actual_contract_field.name]"
            [required]="actual_contract_field.required"
            class="form-control"
            placeholder="{{
              actual_contract_field.contractfieldtranslation.label
            }}"
            (blur)="nextStep()"
          />
          <label class="floating-label">{{
            actual_contract_field.contractfieldtranslation.label
          }}</label>
          <small
            *ngIf="
              !template.valid &&
              (form.submitted || next) &&
              template.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
          <button type="button" class="btn-next" (click)="nextStep()">
            Siguiente
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="actual_contract_field == undefined"
    class="right-rectangle-fr"
    id="box-register"
    [ngStyle]="{ 'background-color': product.color_html }"
  >
    <a *ngIf="step > 1" class="atras" (click)="previousStep()">Atrás</a
    ><br /><br />
    <p class="text-up">Y por último, necesitamos tus datos personales:​</p>
    <div class="register-form-fr">
      <div class="form-group">
        <input
          type="email"
          id="email1"
          name="email"
          #email="ngModel"
          [(ngModel)]="register.email"
          required
          placeholder="Escribe tu correo electrónico  *"
        />
        <small
          *ngIf="!email.valid && form.submitted && email.errors.required"
          style="color: #fecc00"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!email.valid && email.errors != null"
          style="color: #fecc00"
        >
          {{ email.errors.message }}
        </small>
      </div>
      <ng-container *ngIf="with_password">
        <div class="form-group">
          <input
            type="password"
            autocomplete="off"
            id="password1"
            name="password1"
            #password1="ngModel"
            [(ngModel)]="register.password1"
            [required]="item.required"
            placeholder="Crea tu contraseña  *"
          />
          <small
            *ngIf="
              !password1.valid && form.submitted && password1.errors.required
            "
            style="color: #fecc00"
          >
            Completa este campo
          </small>
          <small
            *ngIf="!password1.valid && password1.errors != null"
            style="color: #fecc00"
          >
            {{ password1.errors.message }}
          </small>
        </div>
      </ng-container>
      <div class="form-group">
        <app-custom-phone
          [custom_class]="'custom-box-register'"
          style="width: 100%"
          [placeholder]="'Teléfono *'"
          required
          name="phone_number"
          #phone_number="ngModel"
          [(ngModel)]="register.phone_number"
        ></app-custom-phone>

        <small
          *ngIf="
            !phone_number.valid &&
            form.submitted &&
            phone_number.errors.required
          "
          style="color: #fecc00"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!phone_number.valid && phone_number.errors != null"
          style="color: #fecc00"
        >
          {{ phone_number.errors.message }}
        </small>
        <small *ngIf="nonFieldErrors" style="color: #fecc00">
          {{ nonFieldErrors }}
        </small>
      </div>
    </div>
    <div class="form-group-c-contain">
      <label class="c-contain">
        <span class="holderLabelNoHover privacidadTextStyle"
          >He leído y acepto la
          <a [routerLink]="['/politica-de-cookies']" class="privacidadTextStyle"
            >política de privacidad</a
          ></span
        >
        <input
          type="checkbox"
          name="gdpr_accepted"
          id="gdpr_accepted"
          #gdpr_accepted="ngModel"
          [(ngModel)]="register.gdpr_accepted"
          required=""
        />
        <div
          class="c-input"
          [ngStyle]="{ 'background-color': product.color_html }"
        ></div>
      </label>
      <small
        *ngIf="
          !gdpr_accepted.valid &&
          form.submitted &&
          gdpr_accepted.errors.required
        "
        style="color: #fecc00"
      >
        Debes aceptar la política de privacidad
      </small>
    </div>
    <div class="form-group-c-contain">
      <label class="c-contain">
        <span class="holderLabelNoHover privacidadTextStyle">
          Acepto recibir comunicaciones comerciales de
          <span style="font-family: Quicksand" class="privacidadTextStyle"
            >enley</span
          >
          que puedan ser de mi interés, con la posibilidad de revocar mi
          consentimiento en cualquier momento.
        </span>
        <input
          type="checkbox"
          id="receive_advertising"
          name="receive_advertising"
          #receive_advertising="ngModel"
          [(ngModel)]="register.enley_accepted"
        />
        <div
          class="c-input"
          [ngStyle]="{ 'background-color': product.color_html }"
        ></div>
      </label>
    </div>
    <div class="row">
      <input
        id="pre-estudio-2"
        type="submit"
        value="ACEPTAR"
        class="btn-fr"
        style="background-color: #00788e"
      />
    </div>
  </div>
</form>
