<div class="loading" *ngIf="loading"></div>
<ng-container *ngIf="iframe_size">
  <form
    action=""
    class="contact-form"
    [ngClass]="{ 'bottom-iframe': iframe_size === 'B' }"
    id="formcontacto"
    #contactForm="ngForm"
    method="POST"
    (ngSubmit)="submit()"
  >
    <h2 *ngIf="iframe_size === 'A'" class="contact-form-title">
      Primera consulta gratis <br />
      o llama al
      <span
        ><a
          _ngcontent-serverapp-c52=""
          id="number2"
          href="tel:913901132"
          class="contact-form-title"
          >91 390 11 32</a
        ></span
      >
    </h2>

    <h2 *ngIf="iframe_size === 'B'" class="bottom-iframe-title">
      Primera consulta gratis con un abogado
    </h2>
    <h3 *ngIf="iframe_size === 'B'" class="bottom-iframe-subtitle">
      Si tienes una duda sobre tu herencia, ponte en contacto con nosotros
    </h3>

    <div class="flex-inputs">
      <div class="input-size input-left">
        <input
          type="text"
          class="form-control inputsCssLab"
          name="name"
          #name="ngModel"
          [(ngModel)]="contact.name"
          required
          placeholder="Nombre *"
        />
        <small
          *ngIf="!name.valid && name.errors.required && name.touched"
          style="color: red"
        >
          Completa este campo
        </small>
        <small *ngIf="!name.valid && name.errors != null" style="color: red">
          {{ name.errors.message }}
        </small>
      </div>
      <div class="input-size">
        <input
          type="text"
          class="form-control inputsCssLab"
          name="last_name"
          #last_name="ngModel"
          [(ngModel)]="contact.last_name"
          required
          placeholder="Apellido*"
        />
        <small
          *ngIf="
            !last_name.valid && last_name.errors.required && last_name.touched
          "
          style="color: red"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!last_name.valid && last_name.errors != null"
          style="color: red"
        >
          {{ last_name.errors.message }}
        </small>
      </div>
    </div>
    <div class="flex-inputs-2">
      <div class="input-size email-input email-input-padding email-input-phone">
        <input
          type="text"
          class="form-control inputsCssLab"
          name="email"
          #email="ngModel"
          [(ngModel)]="contact.email"
          required
          placeholder="Dirección de email*"
        />
        <small
          *ngIf="!email.valid && email.errors.required && email.touched"
          style="color: red"
        >
          Completa este campo
        </small>
        <small *ngIf="!email.valid && email.errors != null" style="color: red">
          {{ email.errors.message }}
        </small>
      </div>
      <div class="input-size email-input email-input-phone">
        <app-custom-phone
          [custom_class]="'custom-contact-iframe'"
          style="width: 100%"
          [placeholder]="'Teléfono*'"
          required
          name="phone_number"
          #phone_number="ngModel"
          [(ngModel)]="contact.phone_number"
        ></app-custom-phone>

        <small
          *ngIf="
            !phone_number.valid &&
            phone_number.errors.required &&
            phone_number.touched
          "
          style="color: red"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!phone_number.valid && phone_number.errors != null"
          style="color: red"
        >
          {{ phone_number.errors.message }}
        </small>
      </div>
    </div>
    <div class="message-input">
      <textarea
        type="text"
        class="form-control"
        id="message"
        name="message"
        placeholder="Cuéntanos tu caso"
        #message="ngModel"
        [(ngModel)]="contact.message"
      ></textarea>
      <small
        *ngIf="!message.valid && message.errors.required && message.touched"
        style="color: red"
      >
        Completa este campo
      </small>
      <small
        *ngIf="!message.valid && message.errors != null"
        style="color: red"
      >
        {{ message.errors.message }}
      </small>
    </div>
    <div class="form-group checkbox-input">
      <label class="c-contain">
        <span class="holderLabelNoHover privacidadTextStyle"
          >He leído y acepto la
          <a [routerLink]="['/politica-de-cookies']" class="privacidadTextStyle"
            >política de privacidad</a
          ></span
        >
        <input
          type="checkbox"
          name="gdpr_accepted"
          id="gdpr_accepted"
          #gdpr_accepted="ngModel"
          [(ngModel)]="contact.gdpr_accepted"
          required=""
        />
        <div class="c-input" style="background-color: white"></div>
      </label>
      <small
        *ngIf="!gdpr_accepted.valid && gdpr_accepted.touched"
        style="color: red"
      >
        Completa este campo
      </small>
    </div>
    <div class="form-group checkbox-input">
      <label class="c-contain">
        <span class="holderLabelNoHover privacidadTextStyle"
          >Acepto recibir comunicaciones comerciales de
          <span style="font-family: Quicksand" class="privacidadTextStyle"
            >enley</span
          >
          que puedan ser de mi interés, con la posibilidad de revocar mi
          consentimiento en cualquier momento.
        </span>
        <input
          type="checkbox"
          name="enley_accepted"
          id="enley_accepted"
          #enley_accepted="ngModel"
          [(ngModel)]="contact.enley_accepted"
        />
        <div class="c-input" style="background-color: white"></div>
      </label>
      <small
        *ngIf="!enley_accepted.valid && enley_accepted.touched"
        style="color: red"
      >
        Completa este campo
      </small>
    </div>
    <div class="contact-button-div">
      <input
        type="submit"
        class="contact-button"
        value="Pedir consulta gratis"
      />
    </div>
    <!-- popup -->
    <div class="popupPoisition" *ngIf="showPopUp === true">
      <div class="popSize">
        <div class="positionButton" aria-label="Close" (click)="closePopup()">
          <img class="deleteButton" src="assets/img/closes-popups.svg" alt="" />
        </div>
        <div>
          <p class="popText">
            Gracias por ponerte en contacto con nosotros, un abogado te llamará
            lo antes posible. <br />
            Te hemos enviado un email a {{ contact.email }} con la información
            sobre tu consulta.
          </p>
        </div>
      </div>
    </div>
    <!-- endpopup -->
  </form>
</ng-container>
