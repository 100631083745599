<section [ngClass]="backgroundColor == 'gray' ? 'how how--grey' : 'how'">
  <div class="how__container">
    <h2 class="how__main-title">Cómo funciona nuestro servicio</h2>
    <p class="how__subtitle">
      Queremos facilitarte las cosas, por eso nuestros servicios son online,
      para que puedas realizar tu trámite cuándo y desde donde tú quieras.
    </p>
    <ol class="custom-ordered-list">
      <li>
        Rellena el formulario correspondiente al trámite legal que necesitas.
      </li>
      <li>
        Nos ponemos en contacto contigo para comentar tu caso y resolverte
        cualquier duda.
      </li>
      <li>Se te asignará un abogado, que será quién llevará tu caso.</li>
      <li>
        Se inicia la tramitación de tu gestión, sobre el que estarás
        permanentemente informado a través de email y teléfono.
      </li>
    </ol>
  </div>
</section>
