import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';

const ELEMENT_CONTENT: unique symbol = Symbol('contents');

/**
 * Service that allows to add tags to the header while a component is alive.
 * It automatically cleans up when the component is destroyed.
 *
 * # Example
 * ```ts
 * Component({
 *   selector: 'app-example',
 *   templateUrl: './example.component.html',
 *   styleUrls: ['./example.component.css'],
 *   providers: [HeadService],  // Notice the provider. Improtant for the cleanup.
 * })
 * export class ExampleComponent {
 *   constructor(
 *     renderer: Renderer2,
 *     headService: HeadService,
 *   ) {
 *     headService
 *     .forRenderer(renderer)
 *     .createElement('link', {
 *       rel: 'preload',
 *       as: 'image',
 *       href: 'assets/img/example.webp',
 *     })
 *     .createElement('script', {
 *       type: 'application/ld+json',
 *       [HeadService.ELEMENT_CONTENT]: JSON.stringify({
 *         '@context': 'http://schema.org',
 *         '@type': 'Thing',
 *         name: 'example',
 *       })
 *     })
 *   }
 * }
 * ```
 */
@Injectable()
export class HeadService implements OnDestroy {
  static readonly ELEMENT_CONTENT = ELEMENT_CONTENT;

  private _head: Head | null = null;

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  /**
   * Get a head handler using a specific renderer.
   *
   * @param renderer Renderer to use
   * @returns Head handler for that renderer
   */
  forRenderer(renderer: Renderer2): Head {
    if (this._head) throw new Error('Only supports a single renderer');
    this._head = new Head(renderer, this._document.head);
    return this._head;
  }

  ngOnDestroy(): void {
    this._head.removeAll();
  }
}

type Attributes = Partial<Record<string | typeof ELEMENT_CONTENT, string>>;

/**
 * Controls the head of the document
 *
 * @see {HeadService}
 */
export class Head {
  static readonly ELEMENT_CONTENT = ELEMENT_CONTENT;

  constructor(
    private _renderer: Renderer2,
    private _head: HTMLHeadElement,
    private _elements: HTMLHeadSubelement[] = []
  ) {}

  /**
   * Creates an element into head; tracking it.
   *
   * @param tag Tag of the element to create
   * @param attributes Set of attributes and content to set to the new tag
   * @returns Itself (for chaining)
   */
  createElement(tag: HeadTagName, attributes: Attributes = {}): Head {
    const el = this._renderer.createElement(tag);

    for (let [attr, value] of Object.entries(attributes)) {
      if (typeof attr === 'string') {
        this._renderer.setAttribute(el, attr, value);
      }
    }

    const content = attributes[ELEMENT_CONTENT];
    if (content) {
      this._renderer.appendChild(
        el,
        this._renderer.createText(content.toString())
      );
    }

    this._elements.push(el);
    this._renderer.appendChild(this._head, el);

    return this;
  }

  /**
   * Removes all elements from head and deletes them.
   */
  removeAll(): void {
    for (let el of this._elements) {
      this.removeElement(el);
    }
    this._elements = [];
  }

  private removeElement(el: HTMLElement): void {
    this._renderer.removeChild(this._renderer.parentNode(el), el);
  }
}

export type HTMLHeadSubelement =
  | HTMLTitleElement
  | HTMLStyleElement
  | HTMLBaseElement
  | HTMLLinkElement
  | HTMLMetaElement
  | HTMLScriptElement;

export type HeadTagName =
  | 'title'
  | 'style'
  | 'base'
  | 'link'
  | 'meta'
  | 'script';
