<span class="description"
  >Te ayudamos en todo lo que necesites, desde la planificación y presentación
  de impuestos o la gestión de deducciones, hasta la resolución de todas tus
  dudas en cualquier momento. Nuestro servicio personalizado te permitirá
  centrarte en lo que realmente importa: hacer crecer tu negocio.</span
>
<app-productcategory-agency-table
  [priceByTier]="priceByTier"
  [featuresByTier]="featuresByTier"
  tableFooterType="autonomos"
></app-productcategory-agency-table>
