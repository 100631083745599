<div class="wrapper" [attr.data-open]="open ? 'data-open' : ''">
  <div role="dialog" aria-modal="true">
    <img
      class="close-popup"
      *ngIf="closable"
      (click)="_closePopUp()"
      alt="Close"
      title="Close"
      src="assets/img/close-notification.svg"
    />
    <ng-content></ng-content>
  </div>
</div>
