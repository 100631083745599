import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-pdf-wrapper',
  templateUrl: './pdf-wrapper.component.html',
  styleUrls: ['./pdf-wrapper.component.css'],
  providers: [DocumentsService],
})
export class PdfWrapperComponent implements OnInit {
  @Input() public idContract: number;
  @Input() public documentTypes: string[];

  public pdfSource: string;

  constructor(
    private documentsService: DocumentsService,
    private renderer: Renderer2,
    private readonly elementRef: ElementRef,

    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      const script = this.renderer.createElement('script');
      script.src = '/assets/pdf.min.js';
      script.onload = () => {
        console.log('script loaded');
      };
      this.renderer.appendChild(this.elementRef.nativeElement, script);

      this.getFile();
    }
  }

  reloadComponent() {
    // Logic to reload the component
    this.ngOnInit();
  }

  getFile() {
    if (isPlatformBrowser(this._platformId)) {
      this.documentsService
        .getFiles(this.idContract)
        .pipe(
          map((response) => {
            return response.sort((doc_x, doc_y) => {
              return doc_x.uploaded_at < doc_y.uploaded_at ? 1 : -1;
            });
          })
        )

        .subscribe((filesResp) => {
          let files = filesResp.filter((file) =>
            this.documentTypes.includes(file.document_type.type)
          );
          if (files) {
            let file = files[0];
            this.documentsService
              .donwnloadFile(this.idContract, file.id)
              .subscribe((x) => {
                var re = /(?:\.([^.]+))?$/;
                var extension = 'pdf';

                this.documentsService
                  .getMimeType('.' + extension)
                  .subscribe((response) => {
                    // It is necessary to create a new blob object with mime-type explicitly set
                    // otherwise only Chrome works like it should
                    var newBlob = new Blob([x], { type: response });

                    // For other browsers:
                    // Create a link pointing to the ObjectURL containing the blob.
                    // If absolute URL from the remote server is provided, configure the CORS
                    // header on that server.
                    var url = window.URL.createObjectURL(newBlob);
                    this.pdfSource = url;

                    // Loaded via <script> tag, create shortcut to access PDF.js exports.
                    var pdfjsLib = window['pdfjs-dist/build/pdf'];
                    // var pdfjsLib = require('pdfjs-dist/build/pdf');

                    // The workerSrc property shall be specified.
                    pdfjsLib.GlobalWorkerOptions.workerSrc =
                      '/assets/pdf.worker.js';

                    // Asynchronous download of PDF

                    var loadingTask = pdfjsLib.getDocument(url);
                    loadingTask.promise.then(
                      function (pdf) {
                        var pdfWrapper = document.getElementById('pdfWrapper');
                        pdfWrapper.innerHTML = '';
                        for (
                          var pageNumber = 1;
                          pageNumber <= pdf.numPages;
                          pageNumber++
                        ) {
                          pdf.getPage(pageNumber).then(function (page) {
                            console.log('Page loaded');

                            var scale = 2;
                            var viewport = page.getViewport({ scale: scale });

                            // Prepare canvas using PDF page dimensions

                            var canvas = document.createElement('canvas');
                            canvas.id = 'canvas' + pageNumber;
                            pdfWrapper.appendChild(canvas);
                            canvas.classList.add('img-fluid');
                            canvas.classList.add('canvas-background');

                            canvas.height = viewport.height;
                            canvas.width = viewport.width;
                            // Render PDF page into canvas context
                            var renderContext = {
                              canvasContext: canvas.getContext('2d'),
                              viewport: viewport,
                            };
                            var renderTask = page.render(renderContext);
                            renderTask.promise.then(function () {
                              console.log('Page rendered');
                            });
                          });
                        }

                        console.log('PDF loaded');

                        // Fetch the first page
                      },
                      function (reason) {
                        // PDF loading error
                        console.error(reason);
                      }
                    );
                  });
              });
          }
        });
    }
  }

  downloadFile() {
    var link = document.createElement('a');
    link.href = this.pdfSource;
    link.download = 'Hoja-de-encargo.pdf';
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
    );
  }
}
