<span class="description"
  >Asegura el cumplimiento de todas las obligaciones tributarias de tu empresa.
  Confía en nuestro equipo para mantener las cuentas en orden y ayudarte a tomar
  decisiones que maximicen tus beneficios y minimicen tus riesgos, siempre con
  soluciones adaptadas a las necesidades específicas de tu negocio.</span
>
<app-productcategory-agency-table
  [priceByTier]="priceByTier"
  [featuresByTier]="featuresByTier"
  tableFooterType="sociedades"
></app-productcategory-agency-table>
