<mat-progress-bar
  *ngIf="loadingContentUser === false"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="loading"
  *ngIf="loadingContentUser === false || loadingSubmit === true"
></div>
<section id="validacion">
  <div>
    <form
      class="sp_form"
      method="POST"
      #userForm="ngForm"
      (ngSubmit)="submitEdit()"
    >
      <h1>Datos cliente</h1>
      <br />
      <app-datos-usuario
        [user]="user"
        [control]="userForm"
        [sixMonthResident]="false"
      ></app-datos-usuario>
      <br />
      <div class="row">
        <div class="col-md-4 col-sm col">
          <button
            class="btn btn-custom btn-block btn-sm btn-back-arrow"
            (click)="goBack()"
          >
            VOLVER</button
          >&nbsp;&nbsp;
        </div>
        <div class="col-md-4 col-sm col">
          <button class="btn btn-custom btn-block btn-sm p-2">GUARDAR</button
          >&nbsp;&nbsp;
        </div>
      </div>
    </form>
    <br /><br />
  </div>
</section>
