<span
  [innerHtml]="transform(product.product_description.home_tier_html)"
></span>
<!-- PHONE VERSION-->
<div
  *ngIf="
    product.product_description.home_tier_basic_html &&
    product.product_description.home_tier_advanced_html &&
    product.product_description.home_tier_premium_html
  "
  class="background-agency"
>
  <details
    id="basic-details"
    (click)="openDropwDown('basic')"
    class="container-pack tariif_types pack phone-version"
    [innerHtml]="transform(product.product_description.home_tier_basic_html)"
  ></details>
  <details
    id="advanced-details"
    (click)="openDropwDown('advanced')"
    class="container-pack tariif_types pack phone-version"
    [innerHtml]="transform(product.product_description.home_tier_advanced_html)"
  ></details>
  <details
    id="premium-details"
    (click)="openDropwDown('premium')"
    class="container-pack tariif_types pack phone-version"
    [innerHtml]="transform(product.product_description.home_tier_premium_html)"
  ></details>
</div>
<span
  *ngIf="product.product_description.home_list_why_html"
  [innerHtml]="transform(product.product_description.home_list_why_html)"
></span>
