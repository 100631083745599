import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingDetails } from '../models/billing-details';
import { globalApi } from './global';

@Injectable({
  providedIn: 'root',
})
export class BillingDetailsService {
  public url: string;

  constructor(private http: HttpClient) {
    this.url = globalApi.url;
  }

  getBillingDetails(): Observable<BillingDetails[]> {
    return this.http.get<BillingDetails[]>(this.url + 'billing_details/');
  }

  getContractBillingDetails(contractId: number): Observable<BillingDetails> {
    return this.http.get<BillingDetails>(
      `${this.url}contract/${contractId}/billing_details/`
    );
  }
}
