import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-floating-whatsapp',
  templateUrl: './floating-whatsapp.component.html',
  styleUrls: ['./floating-whatsapp.component.css'],
})
export class FloatingWhatsappComponent {
  @Input() public whatsapp: string;

  constructor() {}
}
