import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgModel,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { CountryCode } from 'src/app/models/custom-phone';
import { CustomPhoneServices } from 'src/app/services/custom-phone.service';
import { UtilsServices } from 'src/app/services/utils.services';

@Component({
  selector: 'app-custom-phone',
  templateUrl: './custom-phone.component.html',
  styleUrls: ['./custom-phone.component.css'],
  providers: [
    CustomPhoneServices,
    UtilsServices,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomPhoneComponent),
    },
  ],
})
export class CustomPhoneComponent implements OnInit, ControlValueAccessor {
  public country_codes: CountryCode[];
  public spain_country_code: CountryCode[];
  public reset_country_codes: CountryCode[];
  public country_code: CountryCode;
  public name_search: string;
  public show_countrys: boolean = false;

  public phone_number: string;

  onChange = (_: any) => {};
  onTouch = () => {};

  @Input() public custom_class: string;
  @Input() public placeholder: string;
  @Input() public dial_code_given: string;
  @Input() public ngModel: NgModel;
  @Output() public ngModelChange = new EventEmitter<NgModel>();

  constructor(
    private _country_codesv: CustomPhoneServices,
    private utilsService: UtilsServices,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  writeValue(value: any): void {
    if (isPlatformBrowser(this.platformId)) {
      if (value != undefined && value != '') {
        value = this.utilsService.parsePhoneNumber(value);
        this.phone_number = value.number;
        this._country_codesv.getCountryCodes().subscribe((countryCodeResp) => {
          let filtered_country_code = countryCodeResp.filter(
            (country_code) => country_code.dialCode == value.dialCode
          );
          this.country_code = filtered_country_code[0];
        });
      } else {
        this.phone_number = '';
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this._country_codesv.getCountryCodes().subscribe((countryCodeResp) => {
        this.country_codes = countryCodeResp;
        this.reset_country_codes = [...this.country_codes];

        this.spain_country_code = this.country_codes.filter(
          (country_code) =>
            country_code.name.toLowerCase().indexOf('spain') !== -1
        );

        if (!this.country_code) {
          this.country_code = this.spain_country_code[0];
        }
      });
    }
  }

  filterCountryCodes(name) {
    this.country_codes = this.reset_country_codes;
    this.country_codes = this.country_codes.filter(
      (country_code) =>
        country_code.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  showCountrys(event) {
    event.stopPropagation();
    this.show_countrys = !this.show_countrys;
  }

  dontToggle(event) {
    event.stopPropagation();
  }

  @HostListener('document:click') hideOnClick() {
    if (this.show_countrys == true) {
      this.show_countrys = false;
    }
  }

  setCountrCode(country_code) {
    this.country_code = country_code;
    this.show_countrys = false;
    this.country_codes = this.reset_country_codes;
    this.onChange(this.country_code.dialCode + this.phone_number);
  }

  setPhoneNumber(value: string) {
    this.phone_number = value;
    if (this.phone_number) {
      this.onChange(this.country_code.dialCode + this.phone_number);
    } else {
      this.onChange(this.phone_number);
    }
  }

  onTouched() {
    this.onTouch();
  }
}
