import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css'],
})
export class HowItWorksComponent {
  @Input() public backgroundColor: string = '';

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}
}
