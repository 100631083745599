import {
  AfterContentInit,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Payment } from 'src/app/models/payment';
import { ContratoService } from 'src/app/services/contrato.services';
import { PaymentServices } from 'src/app/services/payments/payments.services';

@Component({
  selector: 'app-initiate-authentication-payment',
  templateUrl: './initiate-authentication-payment.component.html',
  styleUrls: ['./initiate-authentication-payment.component.css'],
  providers: [PaymentServices, ContratoService],
})
export class InitiateAuthenticationPaymentComponent
  implements AfterContentInit
{
  public loadingContent: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,

    private paymentService: PaymentServices,
    private contratoService: ContratoService,

    private route: ActivatedRoute
  ) {}

  ngAfterContentInit(): void {
    this.loadingContent = true;
    this.route.params.subscribe((params: Params) => {
      var idContract = params.idContract;
      this.contratoService.getContrato(idContract).subscribe((response) => {
        var idPayment = params.idPayment;
        var payment = new Payment(this.platformId);
        payment.id = idPayment;
        payment.step = params.step;
        this.paymentService
          .initiateAuthenticationFlow(payment, idContract, response.product)
          .subscribe();
      });
    });
  }
}
