import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private screenWidthSubject = new BehaviorSubject<number>(window.innerWidth);
  screenWidth = this.screenWidthSubject.asObservable();

  constructor(private ngZone: NgZone) {
    this.init();
  }

  private init() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize.bind(this));
    });
  }

  private onResize() {
    this.screenWidthSubject.next(window.innerWidth);
  }
}
