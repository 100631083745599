import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';

@Directive({
  selector: '[appValidateStreetType]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: StreetTypeDirective, multi: true },
  ],
})
export class StreetTypeDirective {
  constructor() {}
  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    } else {
      return checkStreetType(control.value)
        ? null
        : { street_type: { value: control.value } };
    }
  }
}

function checkStreetType(value) {
  const STREET_TYPES = [
    { id: 'AG', name: 'AGREGADO' },
    { id: 'AL', name: 'ALDEA, ALAMEDA' },
    { id: 'AR', name: 'AREA, ARRABAL' },
    { id: 'AU', name: 'AUTOPISTA' },
    { id: 'AV', name: 'AVENIDA' },
    { id: 'AY', name: 'ARROYO' },
    { id: 'BJ', name: 'BAJADA' },
    { id: 'BO', name: 'BARRIO' },
    { id: 'BR', name: 'BARRANCO' },
    { id: 'CA', name: 'CAÑADA' },
    { id: 'CG', name: 'COLEGIO, CIGARRAL' },
    { id: 'CH', name: 'CHALET' },
    { id: 'CI', name: 'CINTURON' },
    { id: 'CJ', name: 'CALLEJA, CALLEJON' },
    { id: 'CL', name: 'CALLE' },
    { id: 'CM', name: 'CAMINO, CARMEN' },
    { id: 'CN', name: 'COLONIA' },
    { id: 'CO', name: 'CONCEJO, COLEGIO' },
    { id: 'CP', name: 'CAMPA, CAMPO' },
    { id: 'CR', name: 'CARRETERA, CARRERA' },
    { id: 'CS', name: 'CASERIO' },
    { id: 'CT', name: 'CUESTA, COSTANILLA' },
    { id: 'CU', name: 'CONJUNTO' },
    { id: 'CY', name: 'CALEYA' },
    { id: 'DE', name: 'DETRÁS' },
    { id: 'DP', name: 'DIPUTACION' },
    { id: 'DS', name: 'DISEMINADOS' },
    { id: 'ED', name: 'EDIFICIOS' },
    { id: 'EM', name: 'EXTRAMUROS' },
    { id: 'EN', name: 'ENTRADA, ENSANCHE' },
    { id: 'ER', name: 'EXTRARRADIO' },
    { id: 'ES', name: 'ESCALINATA' },
    { id: 'EX', name: 'EXPLANADA' },
    { id: 'FC', name: 'FERROCARRIL' },
    { id: 'FN', name: 'FINCA' },
    { id: 'GL', name: 'GLORIETA' },
    { id: 'GR', name: 'GRUPO' },
    { id: 'GV', name: 'GRAN VIA' },
    { id: 'HT', name: 'HUERTA, HUERTO' },
    { id: 'JR', name: 'JARDINES' },
    { id: 'LD', name: 'LADO, LADERA' },
    { id: 'LG', name: 'LUGAR' },
    { id: 'MC', name: 'MERCADO' },
    { id: 'ML', name: 'MUELLE' },
    { id: 'MN', name: 'MUNICIPIO' },
    { id: 'MS', name: 'MASIAS' },
    { id: 'MT', name: 'MONTE' },
    { id: 'MZ', name: 'MANZANA' },
    { id: 'PB', name: 'POBLADO' },
    { id: 'PD', name: 'PARTIDA' },
    { id: 'PJ', name: 'PASAJE, PASADIZO' },
    { id: 'PL', name: 'POLIGONO' },
    { id: 'PM', name: 'PARAMO' },
    { id: 'PQ', name: 'PARROQUIA, PARQUE' },
    { id: 'PR', name: 'PROLONGACION, CONTINUAC.' },
    { id: 'PS', name: 'PASEO' },
    { id: 'PT', name: 'PUENTE' },
    { id: 'PZ', name: 'PLAZA' },
    { id: 'QT', name: 'QUINTA' },
    { id: 'RB', name: 'RAMBLA' },
    { id: 'RC', name: 'RINCON, RINCONA' },
    { id: 'RD', name: 'RONDA' },
    { id: 'RM', name: 'RAMAL' },
    { id: 'RP', name: 'RAMPA' },
    { id: 'RR', name: 'RIERA' },
    { id: 'RU', name: 'RUA' },
    { id: 'SA', name: 'SALIDA' },
    { id: 'SD', name: 'SENDA' },
    { id: 'SL', name: 'SOLAR' },
    { id: 'SN', name: 'SALON' },
    { id: 'SU', name: 'SUBIDA' },
    { id: 'TN', name: 'TERRENOS' },
    { id: 'TO', name: 'TORRENTE' },
    { id: 'TR', name: 'TRAVESIA' },
    { id: 'UR', name: 'URBANIZACION' },
    { id: 'VR', name: 'VEREDA' },
  ];

  if (
    STREET_TYPES.filter(function (e) {
      return e.name == value || e.name == value.name;
    }).length > 0
  ) {
    return true;
  } else {
    return false;
  }
}
