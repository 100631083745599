import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { Information } from 'src/app/models/information';
import { InformationService } from 'src/app/services/information.services';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css'],
  providers: [InformationService],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class InformationComponent implements OnInit {
  @Input() public name: string = '';
  @Input() public product_id: number = null;
  @Input() public image: string = '';
  @Input() public cloud_coordinates?: number[]; // top - bottom - left - right (in rem)
  @Input() public document_type_id: number = null;

  public information = new Information();
  public open: boolean = false;

  constructor(private _informationsv: InformationService) {}

  ngOnInit(): void {
    if (this.document_type_id) {
      this._informationsv
        .getDescription(this.document_type_id)
        .subscribe((resp) => {
          this.information.description = resp.description;
        });
    } else {
      this.information.name = this.name;
      this.information.product_id = this.product_id;
      this._informationsv.getInformation(this.information).subscribe((resp) => {
        this.cleanDescription(resp.description);
      });
    }
  }

  cleanDescription(description: string) {
    this.information.description = description;
    var regex = /href(.+?)>/g;
    var matches = [];
    var match = regex.exec(description);

    while (match != null) {
      matches.push(match[1]);
      match = regex.exec(description);
    }

    matches.forEach((item) => {
      var item_cleaned = item.replace(/[^\x20-\x7E]+/g, '');
      this.information.description = this.information.description.replace(
        item,
        item_cleaned
      );
    });
  }

  closeModal(event) {
    event.stopPropagation();
    this.open = false;
  }

  openModal(event) {
    event.stopPropagation();
    this.open = true;
  }

  dontToggle(event) {
    event.stopPropagation();
  }

  @HostListener('document:click') hideOnClick() {
    if (this.open == true) {
      this.open = false;
    }
  }
}
