<section [ngClass]="backgroundColor == 'gray' ? 'background-gray' : ''">
  <h2>¿Qué opinan de nosotros?</h2>
  <opinion-carousel>
    <opinion-element
      *ngFor="let review of reviews"
      [attr.opinion]="review | json"
    >
    </opinion-element>
  </opinion-carousel>
</section>
