import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {
  transform(int) {
    let list_ordinals = [
      'Primer',
      'Segundo',
      'Tercer',
      'Cuarto',
      'Quinto',
      'Sexto',
      'Séptimo',
      'Octavo',
      'Noveno',
      'Décimo',
      'Undécimo',
      'duodécimo',
      'decimotercero',
      'decimocuarto',
      'decimoquinto',
      'decimosexto',
    ];

    if (list_ordinals.length > int) {
      return list_ordinals[int];
    } else {
      return int + 1;
    }
  }
}

@Pipe({ name: 'ordinalFem' })
export class OrdinalFemPipe implements PipeTransform {
  transform(int) {
    let list_ordinals = [
      'Primera',
      'Segunda',
      'Tercera',
      'Cuarta',
      'Quinta',
      'Sexta',
      'Séptima',
      'Octava',
      'Novena',
      'Décima',
      'Undécima',
      'duodécima',
      'decimotercera',
      'decimocuarta',
      'decimoquinta',
      'decimosexta',
    ];

    if (list_ordinals.length > int) {
      return list_ordinals[int];
    } else {
      return int;
    }
  }
}
