<section>
  <div class="gracias-alt-logo">
    <img
      src="assets/img/enley-servicios-legales-online.png"
      width="192px"
      height="66px"
      alt="Logo enley"
    />
  </div>
  <div class="gracias-body">
    <h2 class="gracias-title">
      ¡Gracias por confiar en <span class="enleyStyle">enley</span>!
    </h2>
    <p class="gracias-text">
      En breve uno de nuestros abogados <br />
      te llamará.
    </p>
  </div>
  <div class="close-button" [routerLink]="['']">
    <p>CERRAR</p>
  </div>
</section>
