import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import {
  EventEmitter,
  Inject,
  Injectable,
  Optional,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { COOKIES } from '../apptemplate/header/url';
import { GoogleLogin, MailConfirm, NoPasswordLogin } from '../models/login';
import { Register } from '../models/register';
import { Tokens } from '../models/tokens';
import { User } from '../models/user';
import { globalApi } from './global';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = environment.JWT_TOKEN;
  private readonly JWT_TOKEN_OLD = 'auth_token';

  private readonly REFRESH_TOKEN = environment.REFRESH_TOKEN;
  private readonly REFRESH_TOKEN_OLD = 'refresh_token';

  private loggedUser: string;
  private url: string;
  public isUserLoggedIn: Subject<boolean> = new Subject<boolean>();
  public isAdmin: Subject<boolean> = new Subject<boolean>();
  public show_password: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    @Optional() @Inject(COOKIES) public cookies: any,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this.url = globalApi.url;
  }

  login(user: { email: string; password: string }): Observable<any> {
    return this.http.post<any>(this.url + 'login/', user).pipe(
      tap((response) => {
        this.doLoginUser(user.email, response);
        this.isUserLoggedIn.next(true);
        this.isAdmin.next(response.user.is_staff);
      })
    );
  }

  registerLogged(register: Register): Observable<any> {
    return this.http
      .post<any>(this.url + 'registration/', register)
      .pipe(tap((tokens) => this.doLoginUser(register.email, tokens)));
  }

  register(register: Register): Observable<any> {
    return this.http.post<any>(this.url + 'registration/', register);
  }
  logout() {
    return this.http
      .post<any>(this.url + 'logout/', {
        refresh: this.getRefreshToken(),
      })
      .pipe(tap(() => this.doLogoutUser()));
  }

  isLoggedIn() {
    this.isUserLoggedIn.next(!!this.getJwtToken());
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http
      .post<any>(this.url + 'token/refresh/', {
        refresh: this.getRefreshToken(),
      })
      .pipe(
        tap((object: any) => {
          this.storeJwtToken(object.access);
        })
      );
  }

  getJwtToken() {
    return this.getCookie(this.JWT_TOKEN);
  }

  public doLoginUser(email: string, tokens: Tokens) {
    this.loggedUser = email;
    this.storeTokens(tokens);
  }

  public doLogoutUser() {
    this.removeTokens();
    this.loggedUser = null;
    this.isUserLoggedIn.next(false);
    this.show_password.next(false);
  }

  private getRefreshToken() {
    return this.getCookie(this.REFRESH_TOKEN);
  }

  public storeJwtToken(jwt: string) {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    this.delete_cookie(this.JWT_TOKEN);
    this.delete_cookie(this.JWT_TOKEN_OLD);

    this.setCookie(date, this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    this.setCookie(date, this.JWT_TOKEN, tokens.access);
    this.setCookie(date, this.REFRESH_TOKEN, tokens.refresh);
  }

  private removeTokens() {
    this.delete_cookie(this.JWT_TOKEN);
    this.delete_cookie(this.REFRESH_TOKEN);
    this.delete_cookie(this.REFRESH_TOKEN_OLD);
    this.delete_cookie(this.JWT_TOKEN_OLD);
  }

  confirmEmail(params: MailConfirm): Observable<any> {
    return this.http
      .post(this.url + 'registration/account-confirm-email/', params)
      .pipe(
        tap((response) => {
          this.doLoginUser(response.user.email, response);
          this.isUserLoggedIn.next(true);
          this.isAdmin.next(response.user.is_staff);
        })
      );
  }
  getUser(): Observable<User> {
    return this.http.get<User>(this.url + 'user/');
  }

  getUsers(query_params?): Observable<User[]> {
    let params = new HttpParams();
    if (query_params) {
      for (let key in query_params) {
        params = params.set(key, query_params[key]);
      }
    }
    return this.http.get<User[]>(this.url + 'users', { params: params });
  }

  loginNoPassword(login: NoPasswordLogin): Observable<any> {
    return this.http.post<any>(this.url + 'login/', login).pipe(
      tap((response) => {
        this.doLoginUser(response.user.email, response);
        this.isUserLoggedIn.next(true);
      })
    );
  }

  loginWithGoogle(login: GoogleLogin): Observable<any> {
    return this.http.post<any>(this.url + 'dj-rest-auth/google/', login).pipe(
      tap((response) => {
        this.doLoginUser(response.user.email, response);
        this.isUserLoggedIn.next(true);
        this.isAdmin.next(response.user.is_staff);
      })
    );
  }

  resetConfirm(params): Observable<any> {
    return this.http.post(this.url + 'password/reset/confirm/', params).pipe(
      tap((response) => {
        this.doLoginUser(response.user.email, response);
        this.isUserLoggedIn.next(true);
        this.isAdmin.next(response.user.is_staff);
      })
    );
  }

  passwordReset(email): Observable<any> {
    return this.http.post(this.url + 'password/reset/', email);
  }

  setCookie(expireDate: Date, name, value) {
    var expires = '';
    expires = '; expires=' + expireDate.toUTCString();
    if (isPlatformBrowser(this._platformId)) {
      document.cookie =
        name +
        '=' +
        (value || '') +
        expires +
        '; path=/; Domain=' +
        environment.cookieDomain +
        '; SameSite=None; Secure';
    } else {
      let cookieList: string[] = [];
      if (this.cookies) {
        cookieList = this.cookies.split(';');
      }

      cookieList.push(
        name +
          '=' +
          (value || '') +
          expires +
          '; path=/; Domain=' +
          environment.cookieDomain +
          '; SameSite=None; Secure'
      );
      this.cookies = cookieList.join(';');
    }
  }

  getCookie(name) {
    if (isPlatformServer(this._platformId)) {
      if (this.cookies) {
        let cookie = this.cookies
          .split(';')
          .filter(
            (item) =>
              item && item.split('=') && item.split('=')[0].trim() == name
          );
        if (cookie[0]) {
          return cookie[0].split('=')[1];
        }
      }
    } else {
      var nameEQ = name + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }

    return null;
  }

  delete_cookie(name) {
    if (isPlatformBrowser(this._platformId)) {
      document.cookie =
        name +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure';
      document.cookie =
        name +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=' +
        environment.cookieDomain +
        '; SameSite=None; Secure';
    } else {
      if (this.cookies) {
        let cookieList: string[] = this.cookies.split(';');
        cookieList = cookieList.filter((cookieItem) => {
          return (
            cookieItem &&
            cookieItem.split('=') &&
            cookieItem.split('=')[0].trim() != name
          );
        });
        this.cookies = cookieList.join(';');
      }
    }
  }
}
