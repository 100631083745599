import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, NgForm } from '@angular/forms';
import { Observable, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DjangoErrorHandler {
  public is500error: Subject<boolean> = new Subject<boolean>();
  public nonFieldError: Subject<String> = new Subject<String>();

  constructor(private scroll: ViewportScroller) {}

  applyError(
    error: Error,
    form: NgForm,
    controls?: AbstractControl
  ): Observable<never> {
    this.handleError(error, form, controls);
    return throwError(error);
  }

  handleError(
    error: Error,
    form: NgForm | FormGroup,
    controls?: AbstractControl
  ): void {
    if (!(error instanceof HttpErrorResponse)) return;

    if (error.status === 400) {
      this.handleErrorObject(error.error, controls ?? form);
    } else if (error.status >= 500 && error.status < 600) {
      this.scroll.scrollToPosition([0, 0]);
      this.is500error.next(true);
    }
  }

  handleErrorObject(error, form): void {
    for (let key of Object.keys(error)) {
      if (key == 'non_field_errors') {
        this.nonFieldError.next(error[key]);
        this.scroll.scrollToPosition([0, 0]);
      } else if (key in form.controls) {
        if (error[key] instanceof Array || typeof error[key] === 'string') {
          form.controls[key].setErrors({ server: true, message: error[key] });
          const firstElementWithError = document.getElementsByName(
            key.toString()
          )[0];
          if (firstElementWithError) {
            const y =
              firstElementWithError.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        } else if (error[key] instanceof Object) {
          this.handleErrorObject(error[key], form.controls[key]);
        }
      }
    }
  }
}
