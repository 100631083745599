import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { LoadingSpinnerBackofficeContractsService } from '../components/loading-spinner-backoffice-contracts/loading-spinner-backoffice-contracts.service';

@Injectable()
export class HttpCrmRequestInterceptorShowProgressBarInterceptor
  implements HttpInterceptor
{
  constructor(
    private loadingSpinnerBackofficeContractsService: LoadingSpinnerBackofficeContractsService,
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router
  ) {}
  REGEX_CALL_API_PATTERN: RegExp = /enley\/calls\/(\d{13})/;

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !request.params.get('loadingSpinner') &&
        (this.router.url.includes('crm') ||
          this.router.url.includes('contratados')) &&
        !this.REGEX_CALL_API_PATTERN.test(request.url)
      ) {
        this.loadingSpinnerBackofficeContractsService.setLoading(
          true,
          request.url
        );
        return next.handle(request).pipe(
          finalize(() => {
            this.loadingSpinnerBackofficeContractsService.setLoading(
              false,
              request.url
            );
          })
        );
      } else if (
        request.params.get('loadingSpinner') &&
        request.params.get('loadingSpinner') == 'no'
      ) {
        request = request.clone({
          params: request.params.delete('loadingSpinner'),
        });
        return next.handle(request);
      } else {
        return next.handle(request);
      }
    }
    return next.handle(request);
  }
}
