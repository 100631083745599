<ng-container>
  <div class="error" *ngFor="let error of errors">{{ error }}</div>
  <label>
    <span class="pre">{{ pre }}</span>
    <select [formControl]="control">
      <option *ngIf="options[0][0] !== ''" value="" disabled selected hidden>
        Selecciona una opción...
      </option>
      <option *ngFor="let option of options" [value]="option[0]">
        {{ option[1] }}
      </option>
    </select>
    <span class="post">{{ post }}</span>
    <div class="outline"></div>
    <span class="float">{{ label }}</span>
  </label>
</ng-container>
