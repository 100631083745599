<!-- gif -->
<div class="gif">
  <img
    class=""
    width="125px"
    height="125px"
    src="assets/img/loading-gif.gif"
    alt=""
  />
  <p class="cargandoText">Realizando el pago...</p>
</div>
<!-- gif -->
