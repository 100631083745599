/* SERVICIOS PARA ANGULAR */
import { Component, Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalApi } from '../global';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GenerateURLServices {
  public url: string;
  constructor(private http: HttpClient) {
    this.url = globalApi.url;
  }

  createPaymentURL(
    contract_id: number,
    urlData: URLCreateData | any
  ): Observable<URLReturnData> {
    return this.http.post<URLReturnData>(
      `${this.url}contract/${contract_id}/create_payment_url/`,
      urlData
    );
  }

  createPaymentURLSubscribe(
    contract_id,
    urlData: URLCreateDataSubscribe
  ): Observable<URLReturnData> {
    return this.http.post<URLReturnData>(
      `${this.url}contract/${contract_id}/create_payment_url_custom_sheet/`,
      urlData
    );
  }

  unshortenURL(contract_id, payment_id): Observable<URLReturnData> {
    return this.http.get<URLReturnData>(
      `${this.url}contract/${contract_id}/unshorten_url/${payment_id}/`
    );
  }
}

export interface URLCreateData {
  amount: number;
  concept: string;
  planned_payment_id: number;
}

export interface URLCreateDataSubscribe {
  concept: string;
  tier: string;
  offer: boolean;
  is_a_pack_subscription: boolean;
}

export interface URLReturnData {
  url: string;
  id: string;
}

@Component({
  template:
    '<div style="display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0;"><img src="assets/img/logo.jpg"/></div>',
})
export class UnshortenURLComponent implements OnInit {
  constructor(
    private generateURLServices: GenerateURLServices,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      var idContract = params.idContract;
      var idPayment = params.idPaymentShort;

      this.generateURLServices.unshortenURL(idContract, idPayment).subscribe(
        (response) => {
          window.location.href = response.url;
        },
        (error) => {
          alert('Error al redirigir a la url de pago');
        }
      );
    });
  }
}
