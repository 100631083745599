@if(isMobile) {
<section class="mobile-view">
  <div class="carousel-container-services flex-view">
    <div
      class="carousel-item-service"
      *ngFor="let item of items; let i = index"
      (click)="toggleMobileService(i)"
      [ngClass]="{ active: expandedIndex === i }"
    >
      <div class="item-header">
        <img
          class="item-header__image"
          width="73.6"
          height="80"
          *ngIf="expandedIndex !== i"
          [src]="item.image_svg"
        />
        <iframe
          *ngIf="expandedIndex == i"
          frameborder="0"
          [src]="item.image"
          width="184"
          height="184"
          style="
            margin-top: -50px;
            scale: 0.4;
            margin-bottom: -50px;
            transform-origin: left;
            margin-right: -95px;
          "
          scrolling="no"
        ></iframe>

        <div class="title-container">
          <b id="phone-{{ i }}" class="title">{{ item.title }}</b>
        </div>
      </div>

      <div
        class="services"
        [@showTextMobile]="expandedIndex === i ? 'show' : 'hide'"
      >
        <div class="service-text">
          <ng-container
            *ngFor="
              let service of item.services;
              let last = last;
              let j = index
            "
          >
            <p>{{ service }}</p>
            <hr *ngIf="!last" />
          </ng-container>
          <button [routerLink]="item.url"><b>VER MÁS</b></button>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</section>
} @else {
<div class="desktop-view">
  <div class="carousel-container-services" [@slideInOut]="this.direction">
    <div
      class="carousel-item-service"
      *ngFor="let item of services; let i = index"
      (mouseenter)="onItemHover(i)"
      (mouseleave)="onItemLeave(i)"
      [@widthGrow]="isHoveredIndex === i ? 'show' : 'hide'"
      [ngStyle]="isHoveredIndex != i ? { 'max-height': '300px' } : {}"
      style="position: relative"
    >
      <img
        width="184"
        height="184"
        class="svg-container"
        style="
          margin-top: -30px;
          scale: 0.8;
          min-height: 184px;
          margin-bottom: -20px;
          transform-origin: bottom;
        "
        [ngStyle]="{ visibility: visibilityStates[i] || 'visible' }"
        [src]="item.image_svg"
      />
      <iframe
        *ngIf="isHoveredIndex == i"
        frameborder="0"
        [src]="item.image"
        width="184"
        height="184"
        style="
          margin-top: -30px;
          scale: 0.8;
          min-height: 184px;
          margin-bottom: -20px;
          position: absolute;
          z-index: 1000;
          transform-origin: bottom;
        "
        scrolling="no"
      ></iframe>

      <b class="title">{{ item.title }}</b>
      <div
        class="services"
        [@showText]="isHoveredIndex === i ? 'show' : 'hide'"
      >
        <ng-container
          *ngFor="let service of item.services; let last = last; let j = index"
        >
          <p>{{ service }}</p>
          <hr *ngIf="!last" />
        </ng-container>
      </div>
      <div class="container-button">
        <a class="anchor-link" [routerLink]="item.url">
          <b>{{ item.buttonText }}</b>
        </a>
      </div>
    </div>
  </div>
  <div
    class="carousel-dots-service"
    [@hideDots]="isHoveredIndex != undefined ? 'hide' : 'show'"
  >
    <span
      class="dot-service"
      *ngFor="let _ of dotRange; let i = index"
      (click)="goToIndex(i)"
      [class.active]="i === currentIndex"
    ></span>
  </div>
</div>
}
