import { DjangoErrorHandler } from 'src/app/django.error';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { collectResultSync } from '@lit-labs/ssr/lib/render-result';
import { html } from 'lit';
import { render as ssrRender } from '@lit-labs/ssr';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public error500: boolean;
  @ViewChild('header') header: ElementRef;

  constructor(
    private router: Router,
    private djangoError: DjangoErrorHandler,
    private renderer: Renderer2,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.djangoError.is500error.subscribe((error) => (this.error500 = error));
    this.router.events.subscribe(() => {
      this.error500 = false;
    });

    this.authService.isUserLoggedIn.subscribe((isLogged) => {
      this.renderHeader();
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.renderHeader();
      }
      this.error500 = false;
    });
  }

  private renderHeader(): void {
    import('@enley-es/web-components/es/header');

    const headerHTML = collectResultSync(
      ssrRender(
        html`<header-element environment=${environment.name}></header-element>`
      )
    );
    this.renderer.setProperty(
      this.header.nativeElement,
      'innerHTML',
      headerHTML
    );
  }

  async ngAfterViewInit(): Promise<void> {
    await import('@enley-es/web-components/es/header');

    const headerHTML = collectResultSync(
      ssrRender(
        html`<header-element environment=${environment.name}></header-element>`
      )
    );
    this.renderer.setProperty(
      this.header.nativeElement,
      'innerHTML',
      headerHTML
    );
  }

  closePopUpServerError() {
    this.djangoError.is500error.next(false);
  }
}
