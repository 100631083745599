import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { BillingDetails } from 'src/app/models/billing-details';
import { JuridicalPerson } from 'src/app/models/constitution';
import { Address } from 'src/app/models/user';
import { UsuarioServices } from 'src/app/services/usuario.services';

@Component({
  selector: 'app-address-reactive-juridical',
  templateUrl: './juridical-address.component.html',
  styleUrls: [
    './styles/juridical-address.component.css',
    './styles/planned-payments-popup-add-payment-popup.scss',
  ],
  providers: [UsuarioServices],
})
export class AddressReactiveJuridicalComponent implements OnInit {
  @Input() superForm: FormGroup;
  @Input() contract = null;

  addressesOptions: Address[] = [];
  addressesSelection: FormControl = new FormControl('-1');
  addressSelected: Address = new Address();

  constructor(
    private fb: FormBuilder,
    private usuarioServices: UsuarioServices
  ) {}

  ngOnInit(): void {
    if (!this.contract) return;
    this.usuarioServices.getContractAddresses(this.contract.id).subscribe(
      (response) => {
        if (response.length > 0) {
          this.addressesOptions = response;
          this.addressesSelection.setValue(this.addressesOptions[0].pk);
          this.addressSelected = this.addressesOptions[0];
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  selectAddressNew() {
    this.addressSelected = new Address();
  }

  selectAddress(selected) {
    this.addressSelected = selected;
  }
}
