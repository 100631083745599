<section
  class="review"
  [ngClass]="backgroundColor == 'gray' ? 'background-gray' : ''"
>
  <div class="review__wrapper">
    <h2 class="review__main-title">{{ title }}</h2>
    <p class="review__subtitle">
      Nuestros clientes destacan nuestra cercanía, eficiencia, agilidad y
      comunicación constante ¿vas a ser el siguiente?
    </p>
    <app-stars-home [stars]="stars"></app-stars-home>
    <div class="google">
      <img
        alt="Google"
        width="40"
        height="40"
        src="./web-components-assets/img/google-logo.svg"
      />
      {{ stars | number : "1.1-1" }} sobre
      <a
        href="https://www.google.com/search?channel=fs&client=ubuntu&q=Enley#lrd=0x12a4995275019dbb:0xd2ad26b7d694f851,1,,,"
        target="_blank"
        >{{ number_reviews }} reseñas</a
      >
    </div>
  </div>
  <app-opinions-home *ngIf="!loading" [reviews]="reviews"></app-opinions-home>
</section>
