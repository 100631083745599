import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Product } from 'src/app/models/products';
import { ProductsServices } from 'src/app/services/products.services';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css'],
  providers: [ProductsServices],
})
export class StepsComponent implements OnInit {
  public innerWidth: any;
  @Input() product: Product;
  steps;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private productService: ProductsServices
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    this.productService.getSteps(this.product.id).subscribe((response) => {
      this.steps = response;
    });
  }

  @HostListener('window:resize', ['$event'])
  onesize(event) {
    this.innerWidth = window.innerWidth;
  }
}
