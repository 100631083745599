export class UserEdit {
  constructor(
    public pk: string, //1
    public username: string, //2
    public appellative: string, //3
    public email: string, //4
    public first_name: string, //5
    public last_name: string, //6
    public second_last_name: string, //7
    public gdpr_accepted: any, //8
    public phone_number: string, //9
    public nationality: string, //10
    public country: string, //11
    public province: string, //12
    public municipality: string, //13
    public street_type: string, //14
    public street: string, //15
    public number: any, //16
    public block: any, //17
    public stairway: any, //18
    public floor: any, //19
    public door: any, //20
    public national_id: string, //21
    public document_type: string,
    public six_month_resident_spain: boolean
  ) {}
}
export class UserEditContract {
  constructor(
    public first_name: string, //5
    public last_name: string, //6
    public second_last_name: string, //7
    public product_id: any,
    public actual_status: string,
    public union_type: string,
    public common_children: string,
    public assign_marital_residence: string,
    public common_patrimony: string,
    public agree_pensions: string
  ) {}
}
export class userRegister {
  constructor(
    public first_name: string, //5
    public last_name: string, //6
    public second_last_name: string, //7
    public phone_number: string, //9
    public email: string, //4
    public password1: any, //8
    public password2: any,
    public person_type: string,
    public six_month_resident_spain: any
  ) {}
}

export class Login {
  constructor(public email: string, public password: string) {}
}

export class User {
  constructor(
    public pk?: number,
    public username?: string,
    public email?: string,
    public first_name?: string,
    public last_name?: string,
    public second_last_name?: string,
    public customer?: Customer,
    public nationalid?: NationalId,
    public password1?: string,
    public password2?: string,
    public is_staff?: boolean,
   public is_superuser?: boolean,
    public has_password?: boolean,
    public has_social_access?: boolean,
    public already_registered?: boolean,
    public parent_b2b_user_id?: number,
    public kommo_user?: boolean,
    public lawyer?: Lawyer
  ) {
    this.customer = new Customer();
    this.nationalid = new NationalId();
  }

  public toString(): string {
    return this.first_name + ' ' + this.last_name;
  }
}

export class Lawyer {
  constructor(
    public pk?: number,
    public appellative?: string,
    public collegiate_number?: string,
    public collegiate_collage?: string,
    public phone_number_1?: string,
    public kommo_user?: boolean
  ) {}
}

export class Customer {
  public registered_user: Boolean;
  public birthday: Date;
  public birth_municipality: string;
  public kokuai_subscribed: boolean;
  public uses_kokuai: boolean;
  public invoice_to_b2b_user: boolean;
  constructor(
    public pk?: number,
    public gdpr_accepted?: Boolean,
    public enley_accepted?: Boolean,
    public phone_number?: any,
    public phone_number_temp?: any,
    public appellative?: string,
    public first_name?: string,
    public nationality?: string,
    public six_month_resident_spain?: Boolean,
    public address?: Address,
    public registration_municipality?: string,
    public registration_province?: string,
    public same_registration?: Boolean,
    public ip_enley_accepted?: string,
    public date_enley_accepted?: Date,
    public accept_conditions?: Boolean
  ) {}
}

export class NationalId {
  constructor(
    public national_id?: string,
    public document_type?: string,
    public is_verified?: Boolean,
    public date_expired?: Date
  ) {}
}

export class Address {
  constructor(
    public nationality?: string, //10
    public country?: string, //11
    public province?: string, //12
    public municipality?: string, //13
    public street_type?: string, //14
    public street?: string, //15
    public number?: any, //16
    public block?: any, //17
    public stairway?: any, //18
    public floor?: any, //19
    public door?: any, //2
    public warning?: string,
    public postal_code?: string,
    public type?: string,
    public pk?: number,
    public alias?: string,
    public choice?: number,
    public surface?: string,
    public catastral_register?: string,
    public without_catastral_register?: boolean
  ) {}

  toJSON() {
    for (var propName in this) {
      if (this[propName] == null) {
        delete this[propName];
      }
    }
    return this;
  }

  to_string(dataTypeStreetsTypes) {
    let street_type = this.street_type;
    if (
      dataTypeStreetsTypes &&
      dataTypeStreetsTypes.find((item) => item.id == String(this.street_type))
    ) {
      street_type = dataTypeStreetsTypes.filter(
        (item) => item.id == String(this.street_type)
      )[0].name;
    } else {
      street_type = this.street_type;
    }
    let string_address = '';
    if (this.alias) {
      string_address += this.alias + ', ';
    }
    if (this.street_type) {
      string_address +=
        street_type.charAt(0).toUpperCase() +
        street_type.slice(1).toLowerCase() +
        ' ';
    }
    if (this.street) {
      string_address +=
        this.street.charAt(0).toUpperCase() +
        this.street.slice(1).toLowerCase() +
        ', ';
    }
    if (this.number) {
      string_address += this.number + ', ';
    }
    if (this.floor) {
      string_address += this.floor + ', ';
    }
    if (this.door) {
      string_address += this.door + ', ';
    }
    if (this.municipality) {
      string_address +=
        this.municipality.charAt(0).toUpperCase() +
        this.municipality.slice(1).toLowerCase() +
        ', ';
    }
    if (this.postal_code) {
      string_address += this.postal_code + ', ';
    }
    if (this.country) {
      string_address +=
        this.country.charAt(0).toUpperCase() +
        this.country.slice(1).toLowerCase() +
        ', ';
    }

    return string_address.slice(0, -2);
  }
}
