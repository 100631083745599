import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ProductsID } from 'src/app/models/contrato';
import { Payment } from 'src/app/models/payment';
import { ContratoService } from 'src/app/services/contrato.services';
import { PaymentServices } from 'src/app/services/payments/payments.services';

@Component({
  selector: 'app-pagoconfirmacion',
  templateUrl: './pagoconfirmacion.component.html',
  styleUrls: ['./pagoconfirmacion.component.css'],
  providers: [PaymentServices, ContratoService],
})
export class PagoconfirmacionComponent implements OnInit {
  public idcontract: number;
  public idpay: string;
  public log: boolean;
  public boleanPay: boolean;
  public loadingPay: boolean = true;
  public divorce_product_id: number = ProductsID.divorce_product_id;
  public inheritance_product_id: number = ProductsID.inheritance_product_id;
  public creditors_arrangement_id: number = ProductsID.creditors_arrangement_id;
  constructor(
    private _paymentSV: PaymentServices,
    private contractService: ContratoService,
    @Inject(PLATFORM_ID) private platformId: Object,

    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      if (isPlatformBrowser(this.platformId)) {
        this.idcontract = params.idcontract;
        this.idpay = params.idpay;
        this.loadingPay = true;

        var idContract = params.idcontract;
        var step = params.step;
        var idPayment = params.idpay;
        var payment = new Payment(this.platformId);
        payment.id = idPayment;
        payment.step = step;
        payment.action = 'obtain_auth';
        this._paymentSV.initiateAuthentication(idContract, payment).subscribe(
          (resp) => {
            this.contractService.getContrato(this.idcontract).subscribe(
              (contractResponse) => {
                if (params.step) {
                  top.location.href =
                    'contrato/' +
                    this.idcontract +
                    '/postpago/' +
                    params.step +
                    '/' +
                    contractResponse.product;
                } else {
                  top.location.href =
                    'contrato/' +
                    this.idcontract +
                    '/postpago/pago/' +
                    contractResponse.product;
                }
              },
              (error) => {
                this.boleanPay = false;
                this.loadingPay = false;
              }
            );
          },
          (error) => {
            this.boleanPay = false;
            this.loadingPay = false;
            if (params.step) {
              if (isPlatformBrowser(this.platformId)) {
                top.location.href =
                  '/contrato/' +
                  this.idcontract +
                  '/pre-pago/' +
                  params.step +
                  '/pago-error/' +
                  Object.keys(error.error)[0];
              }
            } else {
              if (isPlatformBrowser(this.platformId)) {
                top.location.href =
                  '/contrato/' +
                  this.idcontract +
                  '/pre-pago/pago/' +
                  params.idpay +
                  '/pago-error/' +
                  Object.keys(error.error)[0];
              }
            }
          }
        );
      }
    });
  }
}
