<div class="container-products">
  <div class="content">
    <ul>
      <li *ngFor="let product of products" class="product-card">
        <div class="product-header">
          @if (subcategoryTile) {
          <h3 class="product-title">{{ product.product_description.name }}</h3>
          } @else {
          <h2 class="product-title">{{ product.product_description.name }}</h2>
          }
          <span
            class="product-price"
            [innerHTML]="product.product_description.price_text"
          ></span>
        </div>
        <span class="product-description">{{
          product.product_description.description
        }}</span>

        <a class="product-link" (click)="handleProductClick($event, product)">{{
          product.product_description.cta
        }}</a>
      </li>
    </ul>
  </div>
</div>
