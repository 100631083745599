import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Injector,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NgControl,
  NgForm,
  NgModel,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputTextComponent),
    },
    { provide: ControlContainer, useExisting: NgForm },
  ],
})
export class InputTextComponent implements ControlValueAccessor, OnInit {
  constructor(private injector: Injector) {}
  @Input() title: string;
  @Input() placeholder: string;
  @Input() ngModel: NgModel;
  @Output() valueSet = new EventEmitter<string>();
  @Output() ngModelChange = new EventEmitter<NgModel>();

  private innerValue: string;
  public form: NgForm;
  public control: NgControl;

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  ngOnInit() {
    this.form = this.injector.get(NgForm, null);
    this.control = this.injector.get(NgControl, null);
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v != this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
    this.onChangeCallback(this.innerValue);
  }

  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onBlur() {
    this.onTouchedCallback();
  }
}
