<section id="faqs">
  <div class="centerPreguntas container">
    <div class="row text-center preguntas-div">
      <h2 *ngIf="product" class="faqs-title">
        Preguntas frecuentes sobre la tramitación {{ faqsTitleProduct }}
      </h2>
      <h2 *ngIf="!product" class="faqs-title">
        Preguntas frecuentes sobre nuestros servicios
      </h2>
      <div class="col-md-12" style="margin: auto">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div
            class="accordion-item"
            *ngFor="let faq of faqs; index as i; let first = first"
          >
            <h2 class="accordion-header" id="flush-headingOne{{ i }}">
              <button
                (click)="onToggle($event)"
                id="button-{{ i }}"
                class="accordion-button buttonDivorcio"
                [ngClass]="[first ? '' : 'collapsed']"
                type="button"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#flush-collapseOne' + i"
                [aria-expanded]="first ? 'true' : 'false'"
              >
                <p *ngIf="product" class="AcordeonTextDiv">
                  {{ _utilssv.transformText(faq.title, product) }}
                </p>
                <p
                  *ngIf="!product"
                  class="AcordeonTextDiv"
                  [innerHTML]="
                    _utilssv.transform(faq.title, productHome, sanitized)
                  "
                ></p>
              </button>
            </h2>
            <div
              id="flush-collapseOne{{ i }}"
              class="accordion-collapse collapse"
              [ngClass]="[first ? 'show' : '']"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div
                *ngIf="product"
                class="faqs-answer accordion-body textLeft paddingbtwPreguntas"
                [innerHTML]="
                  _utilssv.transform(faq.text_html, product, sanitized)
                "
              ></div>
              <div
                *ngIf="!product"
                class="faqs-answer accordion-body textLeft paddingbtwPreguntas home"
                [innerHTML]="
                  _utilssv.transform(faq.text_html, productHome, sanitized)
                "
              ></div>
            </div>
          </div>
        </div>
        <div *ngIf="product">
          <a
            class="product-sections-button"
            style="margin-left: auto; margin-right: auto; white-space: pre-wrap"
            [routerLink]="['/formulario-registro', productId]"
            queryParamsHandling="merge"
            >{{
              _utilssv.transformText(product.home_button_action, product)
            }}</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
