import {Injectable} from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Routes,
} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProductComponent} from '../apptemplate/product/product.component';
import {ProductsID} from '../models/contrato';
import {Product} from '../models/products';
import {ProductsServices} from '../services/products.services';

@Injectable({
  providedIn: 'root',
})
export class ProductResolverUrl {
  constructor(
    private productService: ProductsServices,
    private router: Router
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.productService.getProductByURL(route.url.toString()).pipe(
      map(
        (response) => {
          if (response && response.title != undefined) {
            return {
              titulo: response.title
                .replace('{{product_price}}', response.price.toString())
                .replace(
                  '{{half_product_price}}',
                  (response.price / 2).toString()
                )
                .replace(
                  /\{{product_price_without_tax}}/gi,
                  (response.price / 1.21).toLocaleString('de-DE')
                ),
              description: response.meta_description
                .replace(
                  /\{{product_price_without_tax}}/gi,
                  (response.price / 1.21).toLocaleString('de-DE')
                )
                .replace('{{product_price}}', response.price.toString())
                .replace(
                  '{{half_product_price}}',
                  (response.price / 2).toString()
                ),
              product: response,
            };
          } else {
            if (route.url.toString() != 'error') {
              this.router.navigate(['error']);
            }
            return throwError('error');
          }
        },
        (error) => {
          if (route.url.toString() != 'error') {
            this.router.navigate(['error']);
          }
          this.router.navigate(['error']);
        }
      )
    );
  }
}
