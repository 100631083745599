import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { globalApi } from './global';
import { Router, ActivatedRoute } from '@angular/router';
import { Notificacion } from '../models/notificacion';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public url: string;
  public updateAlerts: Subject<boolean> = new Subject<boolean>();
  public alerts: Subject<Notificacion[]> = new Subject<Notificacion[]>();

  constructor(private http: HttpClient) {
    this.url = globalApi.url;
  }

  createAlert(notification): Observable<any> {
    return this.http.post<any>(this.url + 'alert', notification).pipe(
      switchMap(() => {
        return this.getAlerts();
      })
    );
  }

  updateAlert(notification): Observable<any> {
    return this.http
      .put<any>(this.url + 'alert/' + notification.id + '/', notification)
      .pipe(
        switchMap(() => {
          return this.getAlerts();
        })
      );
  }

  getAlerts(): Observable<Notificacion[]> {
    return this.http.get<Notificacion[]>(this.url + 'alert').pipe(
      map((response) => {
        this.alerts.next(response);
        return response;
      })
    );
  }

  getAlert(contractId, type): Observable<Notification> {
    return this.http.get<Notification>(
      this.url + 'alert?contract_id=' + contractId + '&type=' + type
    );
  }

  deleteAlert(order, contractId): Observable<any> {
    return this.http
      .delete<any>(this.url + 'alert/' + order + '/', contractId)
      .pipe(
        switchMap((response) => {
          return this.getAlerts();
        })
      );
  }

  stepFordwardAlert(contractId, order): Observable<any> {
    let type = 'F';
    return this.getAlert(contractId, type).pipe(
      switchMap((response) => {
        let notification: Notificacion = new Notificacion();
        notification.order = order;
        notification.type = 'F';
        notification.contract_id = contractId;
        notification.viewed = false;
        if (response[0] == undefined) {
          return this.createAlert(notification);
        } else {
          notification.id = response[0].id;
          return this.updateAlert(notification);
        }
      })
    );
  }
}
