<div class="stars-container">
  <ng-container
    *ngFor="let star of starsArray; let i = index; trackBy: trackByIndex"
  >
    <svg
      class="star"
      id="star-{{ i }}"
      width="68"
      height="68"
      viewBox="0 0 79 76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="grad-{{ i }}">
          <stop [attr.offset]="star?.offsetFill" stop-color="#00bed6" />
          <stop [attr.offset]="star?.offsetEmpty" stop-color="#e9e9e9" />
        </linearGradient>
      </defs>
      <path
        [attr.fill]="'url(#grad-' + i + ')'"
        d="M42.426 65.658a5.76 5.76 0 0 0-6.136 0l-14.388 9.055c-4.395 2.766-9.917-1.239-8.655-6.276l4.154-16.57a5.76 5.76 0 0 0-1.887-5.815L2.427 35.082c-3.975-3.331-1.867-9.808 3.308-10.16l16.985-1.158a5.76 5.76 0 0 0 4.955-3.605l6.336-15.814c1.933-4.824 8.761-4.823 10.694 0L51.04 20.16a5.76 5.76 0 0 0 4.955 3.605l16.985 1.158c5.175.352 7.283 6.829 3.308 10.16l-13.087 10.97a5.76 5.76 0 0 0-1.887 5.815l4.154 16.57c1.262 5.037-4.26 9.042-8.655 6.276l-14.388-9.055z"
      />
    </svg>
  </ng-container>
</div>
