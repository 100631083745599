<div class="post">
  <div
    class="desktop"
    id="articlesCarousel"
    (touchstart)="isAnimated = !isAnimated"
  >
    <a
      *ngFor="let article of articlesInPage; let i = index"
      [href]="article.link"
    >
      <article [ngClass]="isAnimated ? 'animated' : ''" id="article-{{ i }}">
        <img
          class="article-image"
          [src]="article.thumbnailUrl"
          [alt]="article.image_alt"
          width="300px"
          heigh="250px"
        />
        <div class="contents">
          <span class="blog-group">{{ article.product }} | </span>
          <span class="blog-minutes">{{ article.minutes }}</span>
          <h3>{{ article.title }}</h3>
        </div>
      </article>
    </a>
  </div>
  <div class="article-separator-wrapper">
    <ng-container *ngFor="let page of articlesPages; let i = index">
      <div
        class="article-separator"
        id="arcticleSeparator-{{ i }}"
        [ngClass]="actualPage - 1 === i ? 'active' : ''"
        (click)="changeArticlePage(i + 1)"
      ></div>
    </ng-container>
  </div>
</div>
