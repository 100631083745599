<div class="container">
  @if(isMobile) {
  <!-- Phone version -->
  <div class="mobile-view">
    <div
      #carouselContainer
      class="carousel-container"
      (swipeleft)="nextSlide()"
      (swiperight)="previousSlide()"
    >
      <div class="carousel-item">
        <img src="assets/img/1_pq.svg" width="135" height="135" />
        <b>Servicio integral experto</b>
        <span
          >Nuestro equipo de abogados colegiados tiene amplia experiencia y
          conocimiento en diversas áreas legales.</span
        >
      </div>
      <div class="carousel-item">
        <img src="assets/img/2_pq.svg" width="135" height="135" />
        <b>Trámites rápidos</b>
        <span
          >Utilizamos tecnología y automatización para resolver tus problemas
          legales lo más rápido posible.</span
        >
      </div>
      <div class="carousel-item">
        <img src="assets/img/3_pq.svg" width="135" height="135" />
        <b>Precio económico</b>
        <span
          >Nuestra plataforma permite acceder a servicios legales de alta
          calidad a precios asequibles.</span
        >
      </div>
      <div class="carousel-item">
        <img src="assets/img/4_pq.svg" width="135" height="135" />
        <b>Accesibilidad</b>
        <span
          >Simplificamos los procesos legales para ayudarte a resolverlos de
          manera ágil y sencilla.</span
        >
      </div>
    </div>
    <div class="carousel-dots">
      <span class="dot" #dot (click)="currentSlide(1)"></span>
      <span class="dot" #dot (click)="currentSlide(2)"></span>
      <span class="dot" #dot (click)="currentSlide(3)"></span>
      <span class="dot" #dot (click)="currentSlide(4)"></span>
    </div>
  </div>
  } @else {

  <!-- PC version -->
  <div class="desktop-view">
    <div class="item">
      <img src="assets/img/1_pq.svg" width="180" height="95" />
      <b>Servicio integral experto</b>
      <span
        >Nuestro equipo de abogados colegiados tiene amplia experiencia y
        conocimiento en diversas áreas legales.</span
      >
    </div>
    <div class="item">
      <img src="assets/img/2_pq.svg" width="180" height="95" />
      <b>Trámites rápidos</b>
      <span
        >Utilizamos tecnología y automatización para resolver tus problemas
        legales lo más rápido posible.</span
      >
    </div>
    <div class="item">
      <img src="assets/img/3_pq.svg" width="180" height="95" />
      <b>Precio económico</b>
      <span
        >Nuestra plataforma permite acceder a servicios legales de alta calidad
        a precios asequibles.</span
      >
    </div>
    <div class="item">
      <img src="assets/img/4_pq.svg" width="180" height="95" />
      <b>Accesibilidad</b>
      <span
        >Simplificamos los procesos legales para ayudarte a resolverlos de
        manera ágil y sencilla.</span
      >
    </div>
  </div>
  }
</div>
