/* SERVICIOS PARA ANGULAR */
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {globalApi} from './global';
import {
  GoogleRatingReview,
  GoogleReview,
  GoogleTextReview,
} from '../models/googlereview';
import {map} from 'rxjs/operators';

@Injectable()
export class GoogleReviewService {
  public url: string;
  public token: {};
  constructor(private _http: HttpClient) {
    this.url = globalApi.url;
  }

  getReviewInformation(): Observable<GoogleReview> {
    return this._http.get<GoogleReview>(this.url + 'reviews');
  }
}

@Injectable()
export class GoogleTextReviewService {
  public url: string;
  public token: {};
  constructor(private _http: HttpClient) {
    this.url = globalApi.url;
  }

  getReviewInformation(
    nextPageToken?: string | undefined,
    orderBy?: string | undefined
  ): Observable<GoogleTextReview> {
    let params = new HttpParams();
    if (nextPageToken) {
      params = params.set('pageToken', nextPageToken);
    }
    if (orderBy) {
      params = params.set('orderBy', orderBy);
    }
    return this._http
      .get<GoogleTextReview>(this.url + 'all_reviews', {params: params})
      .pipe(
        map((resp: GoogleTextReview) => {
          resp.reviews.forEach((review) => {
            this.formatReviewStarRating(review);
            this.formatReviewDate(review);
            if (review.comment) {
              this.formatReviewComment(review);
            }
            if (review.reviewReply) {
              this.formatReviewReplyDate(review);
              this.formatReviewReplyComment(review);
            }
          });
          return resp;
        })
      );
  }

  formatReviewStarRating(review) {
    if (review.starRating == 'FIVE') {
      review.starRating = 5;
    } else if (review.starRating == 'FOUR') {
      review.starRating = 4;
    } else if (review.starRating == 'THREE') {
      review.starRating = 3;
    } else if (review.starRating == 'TWO') {
      review.starRating = 2;
    } else if (review.starRating == 'ONE') {
      review.starRating = 1;
    }
  }

  formatReviewDate(review) {
    review.createTime = new Date(review.createTime).toLocaleDateString();
  }

  formatReviewComment(review) {
    if (review.comment.includes('(Original)')) {
      review.comment = review.comment.split('(Original)')[1];
    } else if (review.comment.includes('(Traducido por Google)')) {
      review.comment = review.comment.split('(Traducido por Google)')[1];
    }
  }

  formatReviewReplyDate(review) {
    review.reviewReply.updateTime = new Date(
      review.reviewReply.updateTime
    ).toLocaleDateString();
  }

  formatReviewReplyComment(review) {
    if (review.reviewReply.comment.includes('(Original)')) {
      review.reviewReply.comment =
        review.reviewReply.comment.split('(Original)')[1];
    }
    review.reviewReply.comment = review.reviewReply.comment.split(
      '(Translated by Google)'
    )[0];
  }
}

@Injectable()
export class GoogleAllReviewsService {
  public url: string;
  public token: {};
  constructor(private _http: HttpClient) {
    this.url = globalApi.url;
  }

  getReviewInformation(
    nextPageToken?: string | undefined
  ): Observable<GoogleRatingReview> {
    return this._http.get<GoogleRatingReview>(this.url + 'get_all_reviews');
  }
}
