import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InvoiceSubscriptions} from 'src/app/models/invoice';
import {InvoiceService} from 'src/app/services/invoices.service';
import {FilterInvoiceFormService} from 'src/app/services/invoice-filter.service';
import {ScheduledServices} from 'src/app/services/payments/scheduled.services';

@Component({
  selector: 'app-invoice-confirmation',
  templateUrl: './invoice-confirmation.component.html',
  styleUrls: ['./invoice-confirmation.component.css'],
})
export class InvoiceConfirmationComponent implements OnInit {
  @Input() invoice: InvoiceSubscriptions;
  @Output() public outputEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public outputResponse: EventEmitter<any> = new EventEmitter<any>();
  public paymentAmount: FormGroup;
  constructor(
    private fb: FormBuilder,
    private scheduledService: ScheduledServices,
    private invoiceService: InvoiceService,
    private invoiceFilterService: FilterInvoiceFormService
  ) {}

  ngOnInit(): void {
    this.paymentAmount = this.fb.group({
      amount: [this.invoice.amount, [Validators.required]],
    });
  }

  closePopUp(): void {
    this.outputEvent.emit(false);
  }

  sendResponse(): void {
    this.outputResponse.emit(this.invoice);
    this.closePopUp();
  }
}
