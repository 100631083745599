import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';

@Component({
  selector: 'app-can-do',
  templateUrl: './can-do.component.html',
  styleUrls: ['./can-do.component.css'],
})
export class CanDoComponent {
  @Input() public backgroundColor: string = '';

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}
}
