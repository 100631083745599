import { Location } from '@angular/common';
import { Product, isProductVisible } from './models/products';
import { environment } from 'src/environments/environment';

export type Service = {
  name: string;
  description: string;
  url: string;
  image: string;
};

export function enleySchema(services: Service[]): object {
  return {
    ...SCHEMA_TOP_LEVEL,
    ..._localBusinessSchema({
      full: true,
      services,
    }),
  };
}

export function serviceSchema(service: Service): object {
  return {
    ...SCHEMA_TOP_LEVEL,
    ..._serviceSchema({ service, full: true }),
  };
}

const _localBusinessSchema = ({
  full,
  services,
}: {
  full: boolean;
  services?: Service[];
}): object => ({
  '@type': 'LegalService',
  name: 'Enley',
  description: 'Despachos de abogados online',
  logo: 'https://www.enley.com/assets/icons/icon-192x192.png',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'Spain',
    addressLocality: 'Barcelona',
    addressRegion: 'Catalonia',
    postalCode: '08029',
    streetAddress: 'Carrer Rocafort, 248-250, Entlo. 4ª',
  },
  email: 'mailto:info@enley.com',
  telephone: '+34 93 159 24 28',
  url: 'https://www.enley.com/',
  openingHours: ['Mo-Th 9:00-19:00', 'Fr 9:00-15:00'],
  knowsLanguage: ['ca', 'es', 'en'], // RFC 5646 Language Tags
  currenciesAccepted: 'EUR',
  paymentAccepted: 'Credit Card, Cash, Bizum, Apple Pay',
  keywords: [
    'Divorcio express online',
    'Gestión de herencias online',
    'Abogados para deudas',
    'Constitución de sociedades',
    'Crear empresa',
    'Abogados online',
  ],
  legalName: 'Enley Tech, S.L.',
  taxID: 'B01875491',
  vatID: 'ESB01875491',
  sameAs: [
    'https://www.facebook.com/enleyabogados/',
    'https://www.linkedin.com/company/enley/',
  ],
  ...(!full || !services
    ? {}
    : {
        hasOfferCatalog: {
          '@type': 'OfferCatalog',
          name: 'Servicios legales',
          itemListElement: services.map((service) =>
            _serviceSchema({ service, full: false })
          ),
        },
      }),
  ...(!full
    ? {}
    : {
        areaServed: AREA_SERVED,
      }),
});

const _serviceSchema = ({
  service,
  full,
}: {
  service: Service;
  full: boolean;
}): object => ({
  '@type': 'Service',
  ...service,
  termsOfService: 'https://www.enley.com/condiciones-de-contratacion',
  ...(!full
    ? {}
    : {
        areaServed: AREA_SERVED,
        provider: _localBusinessSchema({ full: false }),
      }),
});

const AREA_SERVED = {
  '@type': 'Country',
  name: 'Spain',
};

const SCHEMA_TOP_LEVEL = {
  '@context': 'http://schema.org',
};

export function* productsToServices(
  products: Product[],
  location: Location
): Generator<Service> {
  const origin = environment.frontUrl;
  for (let product of products) {
    if (product.product_description?.name == null || !isProductVisible(product))
      continue;

    yield productToService(product, location, origin);
  }
}

export function productToService(
  product: Product,
  location: Location,
  origin: string
): Service {
  return {
    name: product.product_description?.name,
    description: _cleanProductDescription(product),
    url:
      origin +
      location.prepareExternalUrl(product.url ? product.url : product.fragment),
    image: product.home_image,
  };
}

export function _cleanProductDescription(product: Product): string {
  if (product.meta_description == null) return '';
  return product.meta_description
    .replace(/\{{product_price}}/gi, product.price.toLocaleString('de-DE'))
    .replace(
      /\{{half_product_price}}/gi,
      (product.price / 2).toLocaleString('de-DE')
    )
    .replace(
      /\{{product_price_without_tax}}/gi,
      (product.price / 1.21).toLocaleString('de-DE')
    );
}
