import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DjangoErrorHandler } from 'src/app/django.error';
import { Contact } from 'src/app/models/formcontacto';
import { Product } from 'src/app/models/products';
import { getRegisterAnalytics } from 'src/app/models/register';
import { User } from 'src/app/models/user';
import { ContactoServices } from 'src/app/services/formcontacto.services';
import { UtilsServices } from 'src/app/services/utils.services';
import { NgForm } from '@angular/forms';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-productcategory-generic-product-modal',
  templateUrl: './generic-product-modal.component.html',
  styleUrl: './generic-product-modal.component.css',
  providers: [ContactoServices, UtilsServices],
})
export class ProductCategoryGenericProductModalComponent implements OnInit {
  product: Product;
  user: User;
  contactForm: FormGroup;
  isSubmitted = false;
  loading = false;
  email = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { product: Product; user: User },
    @Inject(PLATFORM_ID) private _platformId: Object,
    public dialogRef: MatDialogRef<ProductCategoryGenericProductModalComponent>,
    private fb: FormBuilder,
    private contactService: ContactoServices,
    private utilsService: UtilsServices,
    private djangoError: DjangoErrorHandler
  ) {
    this.product = data.product;
    this.user = data.user;

    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      surnames: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      comment: [''],
      gdpr_accepted: [false, Validators.requiredTrue],
      enley_accepted: [false],
    });
  }

  ngOnInit(): void {
    if (this.user) {
      this.contactForm = this.fb.group({
        comment: [''],
        email: [this.user.email],
        enley_accepted: [this.user.customer.enley_accepted],
      });
    }
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.email = this.contactForm.get('email')?.value;
      const contactData: Contact = {
        category_id: undefined,
        affair: this.user ? 'AC' : 'LD',
        name: this.contactForm.get('name')?.value,
        last_name: this.contactForm.get('surnames')?.value,
        email: this.email,
        phone_number: this.contactForm.get('phone_number')?.value?.phone_number,
        gdpr_accepted: this.contactForm.get('gdpr_accepted')?.value,
        enley_accepted: this.contactForm.get('enley_accepted')?.value,
        register_analytics: getRegisterAnalytics(this._platformId),
        product_id: this.product.id,
        message: this.contactForm.get('comment')?.value,
      };

      this.loading = true;

      this.contactService.postContact(contactData).subscribe(
        (contactResult) => {
          this.isSubmitted = true;
          this.loading = false;
          console.log('Contact submitted successfully:', contactResult);
        },
        (error) => {
          this.djangoError.handleError(error, this.contactForm);
          this.loading = false;
          console.error('Error submitting contact:', error);
        }
      );
    } else {
      const fakeNgForm = {
        form: this.contactForm,
        submitted: false,
        _directives: [],
        ngSubmit: new EventEmitter(),
      } as unknown as NgForm;

      this.utilsService.scrollIfFormHasErrors(fakeNgForm).then(() => {
        this.loading = false;
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
