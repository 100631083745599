<div class="container pl-0" *ngIf="user">
  <fieldset ngModelGroup="user" id="user-data-fieldset">
    <div class="d-flex">
      <div
        class="col-md-1 mt-3"
        style="
          max-width: 70px;
          padding-left: 0px;
          padding-top: 5px;
          margin-right: 20px;
        "
      >
        <div
          class="form-check"
          style="
            padding-left: 0px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="padding-right: 2px; font-weight: 300; color: #212529">
            <span class="checkboxTextStyle">Sr.</span>
          </div>
          <label class="address_checkbox-contain">
            <input
              [hidden]="true"
              class="form-check-input"
              id="choice"
              type="radio"
              name="appellative"
              #appellative="ngModel"
              [(ngModel)]="user.customer.appellative"
              required
              value="MR"
            />
            <div class="address_checkbox-input"></div>
          </label>
        </div>

        <div
          class="form-check"
          style="
            padding-left: 0px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="padding-right: 2px; font-weight: 300; color: #212529">
            <span class="checkboxTextStyle">Sra.</span>
          </div>
          <label class="address_checkbox-contain">
            <input
              [hidden]="true"
              class="form-check-input"
              id="choice"
              type="radio"
              name="appellative"
              #appellative="ngModel"
              [(ngModel)]="user.customer.appellative"
              required
              value="MRS"
            />
            <div class="address_checkbox-input"></div>
          </label>
        </div>
        <small
          *ngIf="
            !appellative.valid &&
            control.submitted &&
            appellative.errors.required
          "
          style="color: red"
        >
          Completa este campo
        </small>
        <small
          *ngIf="!appellative.valid && appellative.errors != null"
          style="color: red"
        >
          {{ appellative.errors.message }}
        </small>
      </div>
      <div class="col-md-3 mt-3" style="padding-left: 0px">
        <div class="form-floating">
          <app-input-text
            [title]="'Nombre'"
            required
            [placeholder]="'Nombre (Como en tu DNI)'"
            [(ngModel)]="user.first_name"
            name="first_name"
            #first_name
          ></app-input-text>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mt-3">
        <div class="form-floating surnames">
          <app-input-text
            [title]="'Primer apellido'"
            required
            [placeholder]="'Primer apellido (Como en tu DNI)'"
            [(ngModel)]="user.last_name"
            name="last_name"
            #last_name
          ></app-input-text>
        </div>
      </div>
      <div class="col-md-4 mt-3">
        <div class="form-floating surnames">
          <app-input-text
            [title]="'Segundo apellido'"
            [placeholder]="'Segundo apellido (Como en tu DNI)'"
            [(ngModel)]="user.second_last_name"
            name="second_last_name"
            #second_last_name
          ></app-input-text>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mt-3">
        <label>
          <span class="input-header">Tipo de documento</span>
          <select
            class="form-control"
            id="type_id"
            name="type_id"
            #type_id="ngModel"
            [(ngModel)]="user.nationalid.document_type"
            required
          >
            <option disabled [ngValue]="undefined">
              Selecciona una opción
            </option>
            <option value="NIF">DNI / NIE</option>
            <option value="PASSPORT">PASAPORTE</option>
          </select>
          <small
            *ngIf="
              !type_id.valid && type_id.errors != null && control.submitted
            "
            style="color: red"
          >
            Completa este campo
          </small>
        </label>
      </div>
      <div class="col-md-4 mt-3">
        <fieldset ngModelGroup="nationalid">
          <div *ngIf="user.nationalid.document_type == 'NIF'">
            <label class="phone_dni">
              <span class="input-header">DNI / NIE</span>
              <input
                type="text"
                required
                name="national_id"
                #national_id="ngModel"
                [(ngModel)]="user.nationalid.national_id"
                placeholder="DNI / NIE"
                appValidDNI=""
              />
              <small
                *ngIf="!national_id.valid && control.submitted"
                style="color: red"
              >
                Completa este campo
              </small>
              <small
                *ngIf="
                  national_id.errors &&
                  national_id.touched &&
                  national_id.errors.dni
                "
                style="color: red"
              >
                El DNI/NIE no es válido.
              </small>
              <small
                *ngIf="!national_id.valid && national_id.errors != null"
                style="color: red"
              >
                {{ national_id.errors.message }}
              </small>
            </label>
          </div>
          <div *ngIf="user.nationalid.document_type == 'PASSPORT'">
            <label>
              <span class="input-header">Pasaporte</span>
              <input
                type="text"
                required
                name="national_id"
                #national_id="ngModel"
                [(ngModel)]="user.nationalid.national_id"
                placeholder="Pasaporte"
              />
              <small
                *ngIf="!national_id.valid && control.submitted"
                style="color: red"
              >
                Completa este campo
              </small>
            </label>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row">
      <div *ngIf="showEmail" class="col-md-4 mt-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control inputsCssLab"
            name="email"
            disabled
            required
            #email="ngModel"
            [(ngModel)]="user.email"
            placeholder="Email"
            required
          />
          <label class="floating-label" for="national_id">Email</label>
          <small
            *ngIf="
              (!email.valid &&
                email.errors &&
                email.errors.required &&
                email.touched) ||
              (!email.valid && control.submitted && email.errors.required)
            "
            style="color: red"
          >
            Completa este campo
          </small>
          <small
            *ngIf="!email.valid && email.errors != null"
            style="color: red"
          >
            {{ email.errors.message }}
          </small>
        </div>
      </div>
      <div *ngIf="showPhoneNumber" class="col-md-4 mt-3">
        <div class="form-floating" style="max-width: 285px">
          <app-custom-phone
            [custom_class]="'custom-phone-crm'"
            [placeholder]="'Teléfono*'"
            name="phone_number"
            required
            #phone_number="ngModel"
            [(ngModel)]="user.customer.phone_number"
          >
          </app-custom-phone>

          <small
            *ngIf="
              !phone_number.valid &&
              phone_number.touched &&
              phone_number.errors.required
            "
            style="color: red"
          >
            Completa este campo
          </small>
          <small
            *ngIf="
              !phone_number.valid &&
              control.submitted &&
              phone_number.errors != null
            "
            style="color: red"
          >
            {{ phone_number.errors.message }}
          </small>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-4 mt-3"></div>
      <div class="col-md-4 mt-3">
        <label>
          <span class="input-header">¿Utilizas Kokuai?</span>
          <select
            class="form-control"
            id="type_id"
            name="type_id"
            #type_id="ngModel"
            [(ngModel)]="user.customer.uses_kokuai"
          >
            <option disabled [ngValue]="undefined">
              Selecciona una opción
            </option>
            <option [value]="true">Si</option>
            <option [value]="false" default>No</option>
          </select>
        </label>
      </div>
    </div> -->
    <fieldset ngModelGroup="customer">
      <app-address
        [address]="user.customer.address"
        [control]="control"
        [nacionalidad]="true"
        [six_month_resident]="sixMonthResident"
        [customer]="user.customer"
        [information]="information"
        [postal_code_required]="true"
      ></app-address>
      <app-registration-address
        [customer]="user.customer"
        [control]="control"
      ></app-registration-address>
    </fieldset>
  </fieldset>
</div>
