import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OgConfigSeo } from 'src/app/models/seo/og-seo-config';

@Injectable({
  providedIn: 'root',
})
export class OgSeoService {
  constructor(private meta: Meta, private titleService: Title) {}

  setOgSeoTitleAndDescription(ogConfig: OgConfigSeo) {
    console.log('ENTRA OG SEO!');

    this.titleService.setTitle(ogConfig.title);
    this.meta.updateTag(
      { property: 'og:title', content: ogConfig.title },
      "property='og:title'"
    );
    this.meta.updateTag(
      {
        property: 'og:description',
        content: ogConfig.description,
      },
      "property='og:description'"
    );
  }
}
