import {Component, Input} from '@angular/core';
import {ProductSubCategory} from 'src/app/models/productcategory';

@Component({
  selector: 'app-productcategory-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrl: './subcategories.component.css',
})
export class ProductCategorySubcategoriesComponent {
  @Input() subcategories: ProductSubCategory[] = null;
}
