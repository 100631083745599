import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
})
export class StarsComponent {
  @Input() stars: number;
  public max_stars: number = 5;

  star_sources(): string[] {
    return Array.from(star_sources(this.stars, this.max_stars));
  }
}

function* star_sources(stars: number, max_stars: number) {
  for (let i = 0; i < max_stars; i++) {
    if (stars >= 1) {
      yield 'assets/img/star-blue-big.webp';
    } else if (stars > 0) {
      yield 'assets/img/star_horizontal_50_percent_blue.png';
    } else {
      yield 'assets/img/star-empty.webp';
    }
    stars -= 1;
  }
}
