<section
  id="funciona"
  class="marginTop"
  [ngStyle]="{ 'background-color': product.color_html }"
>
  <div id="top">
    <h2 class="title">Cómo funciona</h2>
    <h3 class="divorciarte5Pasos">{{ product.home_how_it_works_subtitle }}</h3>
  </div>
  <div class="container-fluid">
    <div
      id="carouselExampleControls"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner cardsSize">
        <div class="row text-center comoFuncionaCenter spaceBetween">
          <div
            class="carousel-item col"
            ngClass="{{ innerWidth > 1025 || first ? 'active' : '' }}"
            *ngFor="let step of steps; let first = first"
          >
            <img
              class="step-image"
              src="{{ step.image }}"
              alt="{{ step.alt }}"
              width="120px"
              height="120px"
              loading="lazy"
            />
            <p
              class="txtresponsiveHeren"
              style="margin-top: 25px"
              [innerHtml]="step.description"
            ></p>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev como-funciona-arrow-show"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="prev"
        aria-label="Anterior"
      >
        <span class="carousel-control-prev-icon2" aria-hidden="true"></span>
      </a>
      <a
        class="carousel-control-next como-funciona-arrow-show"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="next"
        aria-label="Siguiente"
      >
        <span class="carousel-control-next-icon2" aria-hidden="true"></span>
      </a>
    </div>
  </div>
  <div>
    <p class="nuevaVidaTextStyle">{{ product.home_how_it_works_end }}</p>
  </div>
</section>
