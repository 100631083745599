import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Validator } from './ng-forms';
import { PartialMerge } from './typing';

/**
 * Validator that ensures that the value is an integer
 * @param control Control to check
 * @returns ValidationError or null
 */
export function integerRequired(
  control: AbstractControl
): IntegerRequiredValidationError | null {
  const error = { integerRequired: true };

  if (typeof control.value === 'number') {
    if (Number.isInteger(control.value)) {
      return null;
    } else {
      return error;
    }
  }

  if (typeof control.value === 'string') {
    if (control.value.match(/^-?\d+$/)) {
      return null;
    } else {
      return error;
    }
  }

  return error;
}
/** Validation error of `integerRequired` */
export type IntegerRequiredValidationError = {
  integerRequired: boolean;
};

type CustomValidationErrors = PartialMerge<[IntegerRequiredValidationError]>;

type MinValidationError = { min: { min: number; actual: number } };
type MaxValidationError = { max: { max: number; actual: number } };
type RequiredValidationError = { required: boolean };
type EmailValidationError = { email: boolean };
type MinLengthValidationError = {
  minlength: { requiredLength: number; actualLength: number };
};
type MaxLengthValidationError = {
  maxlength: { requiredLength: number; actualLength: number };
};
type PatterbValidationError = {
  pattern: { actualValue: string; requiredPattern: string | RegExp };
};

type BuiltinValidationErrors = PartialMerge<
  [
    MinValidationError,
    MaxValidationError,
    RequiredValidationError,
    EmailValidationError,
    MinLengthValidationError,
    MaxLengthValidationError,
    PatterbValidationError
  ]
>;

/**
 * Validation errors with typing for known errors.
 *
 * It provides typing for the known types and umbrela typing for any unknown ones.
 */
export type TypedValidationErrors = CustomValidationErrors &
  BuiltinValidationErrors &
  ValidationErrors;

/**
 * Extract the errros of a control
 * @param control Control that may contain errors
 * @returns A list of errors (may be empty is there are none :)
 */
export function getErrorMessages(control: AbstractControl | null): string[] {
  if (control == null) return [];
  if (control.status != 'INVALID') return [];

  const errors: TypedValidationErrors | null = control.errors;
  if (!errors) return [];

  const {
    integerRequired,
    required,
    pattern,
    min,
    max,
    email,
    minlength,
    maxlength,
    ...others
  } = errors;

  const errorMessages = [];
  // Custom :)
  if (integerRequired) {
    errorMessages.push('El valor debe ser un número');
  }

  // Builtin
  if (min) {
    errorMessages.push(`debe ser igual superior a ${min.min}`);
  }
  if (max) {
    errorMessages.push(`debe ser igual inferior a ${max.max}`);
  }
  if (!integerRequired && required) {
    errorMessages.push('Completa este campo');
  }
  if (email) {
    errorMessages.push('No es un email válido');
  }
  if (minlength) {
    errorMessages.push(
      `Debe tener como mínimo ${minlength.requiredLength} carácteres`
    );
  }
  if (maxlength) {
    errorMessages.push(
      `Debe tener como máximo ${maxlength.requiredLength} carácteres`
    );
  }
  if (!integerRequired && !email && pattern) {
    errorMessages.push('Formato invalido');
  }

  if (errorMessages.length > 0) {
    return errorMessages;
  } else if (others) {
    return ['Validación fallida'];
  } else {
    return [];
  }
}
