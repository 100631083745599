import { Component, Input, OnInit } from '@angular/core';
import { BlogPost } from 'src/app/models/blog-post';
import { Product } from 'src/app/models/products';
import { BlogRssService } from 'src/app/services/blog-rss.service';

@Component({
  selector: 'app-product-articles',
  templateUrl: './product-articles.component.html',
  styleUrls: ['./product-articles.component.css'],
})
export class ProductArticlesComponent implements OnInit {
  @Input() product: Product;
  public articles: BlogPost[];
  public articlesContent: string;
  public articlesTitle: string;

  constructor(private _blogRssService: BlogRssService) {}

  ngOnInit(): void {
    this.articlesContent = PRODUCT_CRM_MAPPING[this.product.url];
    this.articlesTitle = PRODUCT_POST_TITLE_MAPPING[this.product.url];

    this._blogRssService
      .getProductPosts(this.articlesContent)
      .subscribe((articles) => {
        this.articles = articles;
      });
  }
}

const PRODUCT_CRM_MAPPING = {
  ['divorcios-express-online']: 'Divorcio',
  ['gestion-de-herencias']: 'Herencia',
  ['concursos-de-acreedores']: 'Concurso',
  ['compraventa-inmuebles']: 'Inmobiliario',
  ['constitucion-sociedad']: 'Empresa',
  ['compra-sociedades']: 'Empresa',
  ['testamento-notarial']: 'Testamento',
  ['pre-concursos-acreedores']: 'Concurso',
  ['ley-segunda-oportunidad']: 'Concurso',
  ['patentes-y-marcas']: 'Random',
  ['gestoria']: 'Gestoría',
};

const PRODUCT_POST_TITLE_MAPPING = {
  ['divorcios-express-online']: 'de divorcio express',
  ['gestion-de-herencias']: 'sobre sucesiones',
  ['concursos-de-acreedores']: 'de concurso de acreedores',
  ['compraventa-inmuebles']: 'de inmobiliario',
  ['constitucion-sociedad']: 'de constitución de sociedades',
  ['compra-sociedades']: 'de empresa',
  ['testamento-notarial']: 'sobre sucesiones',
  ['pre-concursos-acreedores']: 'sobre derecho concursal',
  ['ley-segunda-oportunidad']: 'sobre la ley de la segunda oportunidad',
  ['patentes-y-marcas']: '',
  ['gestoria']: 'de gestoría',
};
