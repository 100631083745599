<div
  class="lds-spinner overlay"
  style="position: absolute; top: 38%; left: 45%"
>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
