export const environment = {
  production: true,
  apiUrl: 'https://api.enley.com/enley/',
  backendUrl: 'https://api.enley.com/',
  frontUrl: 'www.enley.com',
  siteMapUrl: 'https://www.enley.com/',
  staticUrl: 'https://teststatic.enley.com/',
  cdn: 'cdn-product.enley.com',
  name: 'PROD',
  googleClientId:
    '93317737902-bnh86r6ruj89i3a7fue348vl6ig7p4ir.apps.googleusercontent.com',
  googleLogInUrl: 'https://www.enley.com/google/login',
  cookieDomain: 'enley.com',
  enableApplePay: true,
  cyberPackUrl: 'https://sis.redsys.es/sis/realizarPago',
  sentryDsn:
    'https://623484a1b1c94edabfd5b9c52f69ff9a@o4504910210072576.ingest.sentry.io/4504956046213120',
  JWT_TOKEN: 'access',
  REFRESH_TOKEN: 'refresh',
};
