/* SERVICIOS PARA ANGULAR */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {globalApi} from './global';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {
  stepTracking,
  subStepTracking,
  SubStepTrackingAudit,
  Tracking,
} from '../models/tracking';
import {filter, map, switchMap} from 'rxjs/operators';
import {ContractFilter} from '../models/contrato';
import {Invoice} from '../models/payment';
import {environment} from 'src/environments/environment';

@Injectable()
export class TrackingService {
  public url: string;
  public token: {};
  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.url = globalApi.url;
  }
  /*
    METODO PARA OBTENER EL TRACKING
    */
  getTracking(idContract): Observable<Tracking> {
    return this._http.get<Tracking>(
      this.url + 'contract/' + idContract + '/tracking/'
    );
  }
  /*
    METODO PARA OBTENER EL StepTRACKING
    */
  getStepTracking(idContract, step_tracking_id): Observable<stepTracking> {
    return this._http
      .get<stepTracking>(
        this.url +
          'contract/' +
          idContract +
          '/tracking/step_tracking/' +
          step_tracking_id +
          '/'
      )
      .pipe(
        map((results) => {
          results.substeps_tracking = results.substeps_tracking.sort((x, y) =>
            x.order < y.order ? -1 : 1
          );
          return results;
        })
      );
  }

  /*
    METODO PARA VALIDAR PASO DEL TRACKING
    */
  validateStepTracking(
    idContract,
    step_tracking_id,
    sub_step_tracking_id,
    body,
    parameters?: {
      force?: boolean;
    }
  ): Observable<SubStepTrackingAudit> {
    const params =
      parameters == null
        ? null
        : new HttpParams().set('force', parameters.force);
    return this._http.put<SubStepTrackingAudit>(
      this.url +
        'contract/' +
        idContract +
        '/tracking/step_tracking/' +
        step_tracking_id +
        '/sub_step_tracking/' +
        sub_step_tracking_id +
        '/validate/',
      body,
      {params: params}
    );
  }

  uploadStepTracking(
    idContract,
    step_tracking_id,
    sub_step_tracking_id,
    body: SubStepTrackingAudit,
    fileToUpload: File,
    enable_notifications,
    ignore_missing_documents
  ): Observable<SubStepTrackingAudit> {
    const endpoint =
      this.url +
      'contract/' +
      idContract +
      '/tracking/step_tracking/' +
      step_tracking_id +
      '/sub_step_tracking/' +
      sub_step_tracking_id +
      '/validate/';
    const formData: FormData = new FormData();
    let headers = new HttpHeaders().set('Accept-Language', 'es-es');
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('manual_trigger', 'true');
    formData.append('ignore_missing_documents', ignore_missing_documents);
    return this._http.put<SubStepTrackingAudit>(endpoint, formData, {
      headers: headers,
    });
  }

  downloadStaticFile(file_name): Observable<any> {
    const endpoint = environment.backendUrl + 'static/' + file_name;
    return this._http.get(endpoint, {responseType: 'blob'});
  }

  donwnloadFile(idContract, idDocument): Observable<any> {
    const endpoint =
      this.url + 'contract/' + idContract + '/document/' + idDocument + '/ ';
    let headers = new HttpHeaders()
      .set('Accept-Language', 'es-es')
      .set('Authorization', `Bearer ${this.token}`);
    return this._http.get(endpoint, {responseType: 'blob', headers: headers});
  }

  downloadInvoice(contract_id, payment_id, invoice_id): Observable<any> {
    const endpoint =
      this.url +
      'contract/' +
      contract_id +
      '/payment/' +
      payment_id +
      '/invoice/' +
      invoice_id +
      '/';
    return this._http.get(endpoint, {responseType: 'blob'});
  }

  downloadInvoiceFile(transfer) {
    this.downloadInvoice(
      transfer.contract_id,
      transfer.id,
      transfer.invoice_id
    ).subscribe((x) => {
      var re = /(?:\.([^.]+))?$/;
      var extension = 'PDF';

      this.getMimeType('.' + extension).subscribe((response) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], {type: response});

        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = transfer.invoice_file_name.split('/').pop([0]);
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
    });
  }

  postInvoice(payment_id, invoice: Invoice): Observable<Invoice> {
    const endpoint = this.url + 'payment/' + payment_id + '/invoice/';
    return this._http.post<Invoice>(endpoint, invoice);
  }

  putInvoice(payment_id, invoice: Invoice): Observable<Invoice> {
    const endpoint =
      this.url + 'payment/' + payment_id + '/invoice/' + invoice.pk + '/';
    return this._http.put<Invoice>(endpoint, invoice);
  }

  downloadInvoiceExcel(start_date?: Date, end_date?: Date): Observable<any> {
    let params = new HttpParams();
    if (start_date) {
      console.log(start_date);
      params = params.append('start_date', start_date.toISOString());
    }
    if (end_date) {
      console.log(end_date);

      params = params.append('end_date', end_date.toISOString());
    }
    const endpoint = this.url + 'payment/invoice/';
    console.log(params);
    return this._http.get(endpoint, {params: params, responseType: 'blob'});
  }

  downloadInvoiceZip(start_date?: Date, end_date?: Date): Observable<any> {
    let params = new HttpParams();
    if (start_date) {
      console.log(start_date);
      params = params.append('start_date', start_date.toISOString());
    }
    if (end_date) {
      console.log(end_date);

      params = params.append('end_date', end_date.toISOString());
    }
    const endpoint = this.url + 'payment/invoice/zip/';
    console.log(params);
    return this._http.get(endpoint, {params: params, responseType: 'blob'});
  }

  downloadExportedContracts(kmaleon_contracts): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const endpoint = this.url + 'export_contracts/';
    return this._http.post(endpoint, kmaleon_contracts, {
      headers,
      responseType: 'blob',
    });
  }

  importKmaleonContracts(): Observable<any> {
    const endpoint = 'https://192.168.33.12:80/enley/import_contracts/';
    return this._http.get(endpoint);
  }

  getMimeType(extension): Observable<any> {
    return this._http.get('/assets/file-extension-to-mime-types.json').pipe(
      map((item) => {
        return item[extension];
      })
    );
  }

  stepBackTracking(idContract) {
    return this._http.post(
      this.url + 'contract/' + idContract + '/tracking/step_back/',
      null
    );
  }

  stepFordwardTracking(
    idContract,
    step_order,
    sub_step_order
  ): Observable<any> {
    return this.getTracking(idContract).pipe(
      switchMap((response) => {
        let actual_step: stepTracking = response.steps_tracking.find(
          (item) => item.order == response.actual_tracking_step
        );
        if (actual_step == undefined) {
          return of({});
        }
        let actual_sub_step: subStepTracking =
          actual_step.substeps_tracking.find(
            (item) => item.order == actual_step.actual_tracking_substep
          );

        if (
          actual_sub_step != undefined &&
          actual_sub_step.order == sub_step_order &&
          actual_step.order == step_order
        ) {
          let subStepTrackingAudit: SubStepTrackingAudit =
            new SubStepTrackingAudit();
          subStepTrackingAudit.manual_trigger = false;
          return this.validateStepTracking(
            idContract,
            actual_step.id,
            actual_sub_step.id,
            subStepTrackingAudit
          );
        } else {
          return of({});
        }
      })
    );
  }

  generateToken(email): Observable<any> {
    return this._http.get(this.url + 'generate_token/?email=' + email);
  }
}
