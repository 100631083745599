/* SERVICIOS PARA ANGULAR */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { globalApi } from './global';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User, Address as UserAddress, Address } from '../models/user';
import { Contrato } from '../models/contrato';
import { MarriageRelationship } from '../models/formconvenio';
import { CustomPayment } from '../models/payment';

@Injectable()
export class UsuarioServices {
  public url: string;
  public token: {};
  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.url = globalApi.url;
  }

  postMarriageRelationship(
    contractid: number,
    marriage_relationship: MarriageRelationship
  ): Observable<MarriageRelationship> {
    return this._http.post<MarriageRelationship>(
      this.url + 'contract/' + contractid + '/marriage_relationship/',
      marriage_relationship
    );
  }

  getMarriageRelationship(
    contractid: number
  ): Observable<MarriageRelationship> {
    return this._http.get<MarriageRelationship>(
      this.url + 'contract/' + contractid + '/marriage_relationship/'
    );
  }

  updateMarriageRelationship(
    contractid: number,
    marriage_relationship: MarriageRelationship
  ): Observable<MarriageRelationship> {
    return this._http.put<MarriageRelationship>(
      this.url +
        'contract/' +
        contractid +
        '/marriage_relationship/' +
        marriage_relationship.id +
        '/',
      marriage_relationship
    );
  }

  getCommunity(): Observable<any> {
    return this._http.get(this.url + 'registration/autonomous_communities/');
  }

  /*METODO PARA REGISTRARSE DESDE MODAL
      EN VISTA FORMULARIO PREVIO*/
  registerFromModal(user: User): Observable<any> {
    return this._http.post(this.url + 'registration/', user);
  }
  /*
    METODO PARA REALIZAR EL PAGO
    EN LA PLATAFORMA "checkoutshopper-test.adyen.com"
    PARAMS: CONTRACT ID - STEP: 1    
    */
  PaymentOne(step, contract_id): Observable<any> {
    let params = { step: step };
    return this._http.post(
      this.url + 'contract/' + contract_id + '/payment/',
      params
    );
  }
  doSaveContract(contract: Contrato): Observable<any> {
    return this._http.post<any>(this.url + 'contract/', contract);
  }
  /*
    METODO PARA REALIZAR EL PAGO 
    PARA CUALQUIER STEP Y PRODUCTO
    */
  paymentService(step, contract_id, payment_id?): Observable<any> {
    let params = { step: step };
    if (payment_id) {
      return this._http.get(
        this.url +
          'contract/' +
          contract_id +
          '/contracted_product_services/' +
          payment_id +
          '/'
      );
    } else {
      return this._http.post(
        this.url + 'contract/' + contract_id + '/contracted_product_services/',
        params
      );
    }
  }

  getPaymentService(contract_id: number, payment_id: string): Observable<any> {
    return this._http.get(
      this.url +
        'contract/' +
        contract_id +
        '/contracted_product_services/' +
        payment_id +
        '/'
    );
  }

  postPaymentService(
    contract_id: number,
    custom_payment: CustomPayment
  ): Observable<CustomPayment> {
    return this._http.post(
      this.url + 'contract/' + contract_id + '/contracted_product_services/',
      custom_payment
    );
  }

  /*
    METODO PARA OBTENER LA CALLE PARA NAVARRA EXCLUSIVAMENTE
    */
  getStreetForNavarra(
    provinces,
    municipalities,
    typestreet,
    street
  ): Observable<any> {
    return this._http.get(
      this.url +
        'registration/streets/?province=' +
        provinces +
        '&municipality=' +
        municipalities +
        '&street_type=' +
        typestreet +
        '&street=' +
        street
    );
  }
  // METODO PARA OBTENER LOS PAISES
  getCountries(): Observable<any> {
    return this._http.get(this.url + 'registration/countries/');
  }

  getStreet(provinces, municipalities, typestreet): Observable<any> {
    return this._http.get(
      this.url +
        'registration/streets/?province=' +
        provinces +
        '&municipality=' +
        municipalities +
        '&street_type=' +
        typestreet
    );
  }
  /*
    METODO PARA OBTENER TODOS LOS TIPOS DE CALLE DE ESPANA
    */
  getTypeStreet(): Observable<any> {
    return this._http.get(this.url + 'registration/streettypes/');
  }
  /*METODO PARA RETORNAR LAS PROVINCIAS
    PARAMETRO: TOKEN DE USUARIO , PROVINCIA
    PETICION: GET
    */
  getMunicipality(provinces): Observable<any> {
    let params = new HttpParams().append('province', provinces);
    return this._http.get(this.url + 'registration/municipalities/?' + params);
  }
  getMunicipalityWithP(provinces, municipaliti): Observable<any> {
    let params = new HttpParams().append('province', provinces);
    let params2 = new HttpParams().append('municipality', municipaliti);
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.token}`
    );
    return this._http.get(
      this.url + 'registration/municipalities/?' + params + '&' + params2
    );
  }

  getZipCode(
    country,
    provinces,
    municipalities,
    typestreet,
    street,
    number
  ): Observable<any> {
    let params = new HttpParams()
      .set('country', country)
      .set('province', provinces)
      .set('municipality', municipalities)
      .set('street_type', typestreet)
      .set('street', street)
      .set('number', number);

    return this._http.get(
      this.url + 'registration/zip_code?' + params.toString()
    );
  }
  /*METODO PARA RETORNAR LAS PROVINCIAS
    PARAMETRO: TOKEN DE USUARIO
    PETICION: GET
    */
  getProvinces(): Observable<any> {
    return this._http.get(this.url + 'registration/provinces/');
  }
  getProvincesFiltered(
    province: string,
    autonomous_community: string
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('province', province);
    params = params.append('autonomous_community', autonomous_community);

    return this._http.get(this.url + 'registration/provinces/', {
      params: params,
    });
  }

  /*
    METODO PARA RECUPERAR USUARIO ESTRUCTURA CORRECTA
    */
  getUser(): Observable<User> {
    return this._http.get<User>(this.url + 'user/');
  }

  getUserPk(pk: number): Observable<User> {
    return this._http.get<User>(this.url + 'user/' + pk);
  }
  /*
    METODO PARA RECUPERAR USUARIO ESTRUCTURA CORRECTA
    */
  patchUser(user: User, pk?: string): Observable<User> {
    if (pk == undefined || pk == null) {
      pk = '';
    }
    return this._http.patch(this.url + 'user/' + pk, user);
  }

  updateUser(user: User, pk?: string): Observable<User> {
    if (pk == undefined || pk == null) {
      pk = '';
    }
    return this._http.put(this.url + 'user/' + pk, user);
  }

  updateUserEnleyAccepted(email: string): Observable<User> {
    let params = new HttpParams();
    params = params.append('email', email);
    let url = this.url + 'user/enley_accepted/';
    return this._http.patch(url, email, { params });
  }

  deleteCustomerCrm(userId): Observable<any> {
    return this._http.delete(this.url + 'user/' + userId);
  }

  /*
    GET SPOUSE
    */
  getSpouse(idContract: number): Observable<User[]> {
    return this._http.get<User[]>(
      this.url + 'contract/' + idContract + '/spouse/'
    );
  }

  /*
    insert SPOUSE
    */
  addSpouse(idContract: number, user: User): Observable<User> {
    return this._http.post<User>(
      this.url + 'contract/' + idContract + '/spouse/',
      user
    );
  }

  /*
    update SPOUSE
    */
  updateSpouse(
    idContract: number,
    user: User,
    idUser: number
  ): Observable<User> {
    return this._http.put<User>(
      this.url + 'contract/' + idContract + '/spouse/' + idUser + '/',
      user
    );
  }

  /*
    get CONTRACT ADDRESSES
    */

  getContractAddresses(idContract): Observable<Address[]> {
    return this._http
      .get<Address[]>(
        this.url + 'contract/' + idContract + '/contract_addresses/'
      )
      .pipe(
        map((response) => {
          let listAddress = new Array<Address>();
          response.forEach((item) => {
            let address: Address = new Address();
            for (var prop in item) {
              address[prop] = item[prop];
            }
            listAddress.push(address);
          });
          return listAddress;
        })
      );
  }
}
