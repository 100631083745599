import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestCacheService } from './app/services/request-cache.service';
import { environment } from './environments/environment';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // continue if not cacheable.
    if (!isCacheable(req)) {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req.url);
    return cachedResponse
      ? of(cachedResponse)
      : sendRequest(req, next, this.cache);
  }
}

function isCacheable(req: HttpRequest<any>): boolean {
  if (req.method == 'GET') {
    return true;
  }
}

function sendRequest(
  req: HttpRequest<any>,
  next: HttpHandler,
  cache: RequestCacheService
): Observable<HttpEvent<any>> {
  return next.handle(req).pipe(
    tap((event) => {
      // There may be other events besides the response.
      if (event instanceof HttpResponse) {
        cache.set(req.url, event); // Update the cache.
      }
    })
  );
}
