import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Product } from 'src/app/models/products';

@Component({
  selector: 'app-product-options',
  templateUrl: './product-options.component.html',
  styleUrls: ['./product-options.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductOptionsComponent {
  @Input() product: Product;
  public constitutionsUrl: string = '/constitucion-sociedad';
  public buySocietyUrl: string = '/compra-sociedades';

  constructor(private sanitized: DomSanitizer) {}

  transform(value) {
    if (value != undefined) {
      return this.sanitized.bypassSecurityTrustHtml(
        value
          .replace(/\{{productId}}/gi, this.product.id)
          .replace(
            /\{{product_price_without_tax}}/gi,
            (this.product.price / 1.21).toLocaleString('de-DE')
          )
          .replace(/\{{constitutionUrl}}/gi, this.constitutionsUrl)
          .replace(/\{{sellSocietyUrl}}/gi, this.buySocietyUrl)
      );
    } else {
      return undefined;
    }
  }
}
