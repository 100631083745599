import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioServices } from '../../services/usuario.services';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  Address,
  Customer,
  NationalId,
  User,
  UserEdit,
} from '../../models/user';
import { NgForm } from '@angular/forms';
import { DjangoErrorHandler } from 'src/app/django.error';
import { UtilsServices } from 'src/app/services/utils.services';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css'],
  providers: [UsuarioServices, UtilsServices],
})
export class UsuarioComponent implements OnInit {
  public user: User;
  public loadingContentUser: boolean;
  public messageUser: string;
  public loadingUser: boolean;
  public loadingSubmit: boolean;
  @ViewChild('userForm', { static: false }) form: NgForm;

  constructor(
    private _usuariosv: UsuarioServices,
    private utils: UtilsServices,
    private _route: ActivatedRoute,
    private _router: Router,
    private djangoError: DjangoErrorHandler,
    private cd: ChangeDetectorRef
  ) {
    this.loadingContentUser = false;
    let nationalid = new NationalId();
    let address = new Address();
    let customer = new Customer();
    customer.address = address;
    this.user = new User();
    this.user.customer = customer;
    this.user.nationalid = nationalid;
  }

  ngOnInit(): void {
    this._usuariosv.getUser().subscribe((response) => {
      this.user = response;
      if (this.user.nationalid == undefined) {
        this.user.nationalid = new NationalId();
      }
      if (this.user.customer == undefined) {
        this.user.customer = new Customer();
      }
      if (this.user.customer.address == undefined) {
        this.user.customer.address = new Address();
      }
      this.loadingContentUser = true;
    });
  }

  // EDITA EL USUARIO
  submitEdit() {
    this.loadingSubmit = true;
    if (this.user.customer.phone_number == 'NaN') {
      this.user.customer.phone_number = undefined;
    }
    if (this.user.customer.address.country == '') {
      this.user.customer.address.country = undefined;
    }
    if (this.user.customer.address.door == '') {
      this.user.customer.address.door = undefined;
    }
    if (this.user.customer.address.floor == '') {
      this.user.customer.address.floor = undefined;
    }
    if (this.user.customer.address.municipality == '') {
      this.user.customer.address.municipality = undefined;
    }
    if (this.user.customer.address.postal_code == '') {
      this.user.customer.address.postal_code = undefined;
    }
    if (this.user.customer.address.province == '') {
      this.user.customer.address.province = undefined;
    }
    if (this.user.customer.address.stairway == '') {
      this.user.customer.address.stairway = undefined;
    }
    if (this.user.customer.address.street == '') {
      this.user.customer.address.street = undefined;
    }
    if (this.user.customer.address.street_type == '') {
      this.user.customer.address.street_type = undefined;
    }
    /*LLAMA AL METODO DO EDIT PARA ENVIAR LA DATA Y PODER EDITAR EL USUARIO*/
    this._usuariosv.patchUser(this.user).subscribe(
      (resp) => {
        this.loadingSubmit = false;
        this._router.navigate(['backoffice/crm']);
      },
      (error) => {
        this.djangoError.handleError(
          error,
          this.form,
          this.form.controls['user']
        );
        this.loadingSubmit = false;
      }
    );
  }

  goBack() {
    this._router.navigate(['/area-privada']);
  }
}
