<section
  *ngIf="product.product_home_sections.length > 0"
  class="product-sections-section"
>
  <div
    class="product-section-wrapper"
    *ngFor="let section of product.product_home_sections"
  >
    <h2
      class="product-section-title"
      *ngIf="section.title"
      [innerHtml]="section.title"
    ></h2>
    <p class="product-section-text" [innerHtml]="transform(section.text)"></p>
  </div>
  <div class="button-image-wrapper">
    <a
      class="product-sections-button"
      [routerLink]="['/formulario-registro/', product.id]"
      queryParamsHandling="merge"
      >{{
        _utilssv.transformText(
          product.product_description.description_button_title,
          product
        )
      }}</a
    >
    <div class="image-wrapper">
      <img
        class="product-sections-image"
        [src]="product.product_description_image"
        [alt]="product.home_image_alt"
        role="presentation"
        loading="lazy"
      />
    </div>
  </div>
</section>
