import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.sass'],
})
export class PopupComponent {
  @Input() closable: boolean = true;
  @Output() closePopUp = new EventEmitter<void>();
  @Input() open: boolean = true;
  @Output() openChange = new EventEmitter<boolean>();

  _closePopUp() {
    this.closePopUp.emit();
    this.openChange.emit(false);
  }
}
