import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { BlogPost } from 'src/app/models/blog-post';

const ARTICLES_PER_PAGE = 3;

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css'],
})
export class PostComponent implements OnInit {
  public innerWidth: any;
  public articlesPages: any[];
  public actualPage: number;
  public articlesInPage: BlogPost[];
  public isAnimated: boolean = true;
  @Input() public articles: BlogPost[];

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}

  ngOnInit(): void {
    this.setArticlesPages();
    this.setArticlesInPage();
  }

  setArticlesInPage() {
    this.actualPage = 1;
    if (isPlatformBrowser(this._platformId)) {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth <= 948) {
        this.articlesInPage = this.articles;
        document
          .querySelector('.article-separator-wrapper')
          .setAttribute('style', 'display: none;');
      } else {
        document
          .querySelector('.article-separator-wrapper')
          .removeAttribute('style');
        this.articlesInPage = this.articles.slice(0, 3);
      }
    }
  }

  carouselClicked(event) {
    if (isPlatformBrowser(this._platformId)) {
      let articles = document.querySelectorAll('.animated');
      articles.forEach((article) => {
        article.classList.remove('animated');
        article.classList.add('unanimated');
      });
    }
  }

  carouselUnclicked(event) {
    let articles = document.querySelectorAll('.unanimated');
    articles.forEach((article) => {
      article.classList.remove('unanimated');
      article.classList.add('animated');
    });
  }

  setArticlesPages() {
    this.actualPage = 1;
    this.articlesPages = new Array(
      Math.ceil(this.articles.length / ARTICLES_PER_PAGE)
    );
  }

  changeArticlePage(page) {
    this.actualPage = page;
    if (page == 1) {
      this.articlesInPage = this.articles.slice(0, 3);
    } else {
      this.articlesInPage = this.articles.slice(page + 1, page * 3);
    }
  }

  @HostListener('window:resize', ['$event'])
  onesize(event) {
    this.innerWidth = window.innerWidth;
    this.setArticlesInPage();
  }
}
