import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CountryCode } from '../models/custom-phone';
import { globalApi } from './global';

@Injectable({
  providedIn: 'root',
})
export class CustomPhoneServices {
  private url: string;

  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.url = globalApi.url;
  }

  getCountryCodes(): Observable<CountryCode[]> {
    return this._http.get<CountryCode[]>('/assets/phone-country-codes.json');
  }
}
