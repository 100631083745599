import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UsuarioServices } from 'src/app/services/usuario.services';

@Directive({
  selector: '[appValidateProvinceType]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: ProvinceTypeDirective,
      multi: true,
    },
  ],
})
export class ProvinceTypeDirective implements AsyncValidator {
  @Input('appValidateProvinceType') country: string;

  constructor(public usuarioService: UsuarioServices) {}

  public provinces$ = this.usuarioService.getProvinces().pipe(shareReplay(1));

  validate(control: AbstractControl): Observable<any> | null {
    if (!control.value) {
      return of(null);
    } else if (this.country && this.country.toLowerCase() != 'españa') {
      console.log(this.country);
      return of(null);
    } else {
      return this.checkProvinceType(control.value);
    }
  }

  checkProvinceType(value) {
    return this.provinces$.pipe(
      map((response) => {
        if (response.find((province) => province == value)) {
          return null;
        } else {
          return { province: { value: value } };
        }
      })
    );
  }
}
