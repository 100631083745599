import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import {Product} from 'src/app/models/products';
import {Review} from 'src/app/models/review';
import {GoogleReviewService} from 'src/app/services/googlereview.services';

@Component({
  selector: 'app-product-reviews',
  templateUrl: './product-reviews.component.html',
  styleUrls: ['./product-reviews.component.css'],
  providers: [GoogleReviewService],
})
export class ProductReviewsComponent implements OnInit, OnDestroy {
  public isBigScreen: boolean = true;
  public reviews: Array<Review>;
  public stars: Number;
  public number_reviews: Number;
  @Input() public backgroundColor: string = '';
  @Input() public cardColor: string = '';
  @Input() product: Product;
  private scriptHead;
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private _googlereviewsv: GoogleReviewService
  ) {
    if (isPlatformBrowser(this._platformId)) {
      import('@enley-es/web-components/es/opinion-carousel');
      import('@enley-es/web-components/es/opinion-element');
    }
  }

  updateScreenSize() {
    if (isPlatformBrowser(this._platformId)) {
      this.isBigScreen = window.innerWidth > 1025;
    }
  }

  ngOnInit(): void {
    this._googlereviewsv.getReviewInformation().subscribe((resp) => {
      this.stars = resp.averageRating;
      this.number_reviews = resp.totalReviewCount;
      if (
        this.product &&
        this.product.product_description &&
        isPlatformServer(this._platformId)
      ) {
        const reviewJson = {
          '@context': 'https://schema.org/',
          '@type': 'CreativeWorkSeries',
          name: this.product.product_description.name,
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: this.stars,
            bestRating: '5',
            ratingCount: this.number_reviews,
          },
        };
        this.scriptHead = this.renderer2.createElement('script');
        this.scriptHead.type = `application/ld+json`;
        this.scriptHead.text = JSON.stringify(reviewJson);
        this.renderer2.removeChild(this._document.head, this.scriptHead);
        this.renderer2.appendChild(this._document.head, this.scriptHead);
      }
    });

    this.updateScreenSize();
    this.reviews = REVIEWS_MAPPING[this.product.url];
  }

  @HostListener('window:resize', ['$event'])
  onesize(event) {
    this.updateScreenSize();
  }

  ngOnDestroy(): void {
    if (this.scriptHead) {
      this.renderer2.removeChild(this._document.head, this.scriptHead);
    }
  }
}

const REVIEWS_MAPPING = {
  ['divorcios-express-online']: [
    new Review(
      'Marisa Villalba',
      '5',
      '5',
      'Atentos, profesionales y muy eficaces. Era un divorcio complicado y con los temores que ello conlleva, ellos han aportado serenidad haciendo que el trámite sea de lo más fluido y satisfactorio. Gracias Montse por tu saber hacer. 100% recomendable.'
    ),
    new Review(
      'Maribel Fernández',
      '5',
      '5',
      'Encantada de haber tramitado el divorcio con Enley. Muy buenos profesionales. Resolutivos, cercanos y a un precio muy inferior al del resto del mercado. Gracias'
    ),
    new Review(
      'Daria Volkova',
      '5',
      '5',
      'Me fue muy bien. Me atendían Gloria y Paula, hasta el punto que han hablado con el juzgado para adelantar la cita porque me tenia que ir, y estoy muy contenta que se quedó solucionando todo antes de que me fuera. Muchas gracias!'
    ),
  ],
  ['gestion-de-herencias']: [
    new Review(
      'Pablo Gutiérrez',
      '5',
      '4',
      'Con gran profesionalidad nos tramitaron la herencia que dejó mi abuela. No teníamos testamento, pero no fue un problema. Recomendaré el servicio sin ninguna duda.'
    ),
    new Review(
      'Cristina Fuentes',
      '5',
      '4',
      'El tiempo de respuesta es excepcional. La pregunta se respondió por teléfono con rapidez y con un alto grado de comprensión.'
    ),
    new Review(
      'Macarena Muñoz',
      '5',
      '5',
      '100% buena experiencia del servicio. Adrián, el abogado que me asignaron, fue muy atento en todo momento.'
    ),
  ],
  ['concursos-de-acreedores']: [
    new Review(
      'Julio Diaz',
      '5',
      '4',
      'Nos han ayudado y han resuelto nuestro problema. Sus honorarios son mas que ajustados. Empresas así te facilitan la vida. Gracias'
    ),
    new Review(
      'Marga Sotilla',
      '5',
      '5',
      'El tiempo de respuesta es excepcional. La pregunta se respondió por teléfono con rapidez y con un alto grado de comprensión.'
    ),
    new Review(
      'Esteban Mello',
      '5',
      '4',
      'Me han asesorado con el concurso de mi empresa y han evitado muchos problemas. He podido recuperar la tranquilidad gracias a su respaldo y llevar a cabo el concurso.'
    ),
  ],
  ['compraventa-inmuebles']: [
    new Review(
      'Miriam Teruel',
      '5',
      '4',
      'Estoy muy contenta con la atención y la profesionalidad que me han atendido para la compra de un inmueble. Os agradezco el tiempo y la dedicación!'
    ),
    new Review(
      'Oscar Fábregas',
      '5',
      '5',
      'Gran profesionalidad, transparencia y eficacia en un largo proceso con buenos resultados'
    ),
    new Review(
      'Ricardo Monser',
      '5',
      '4',
      'Comunicación en todo momento del proceso y de su estado. Muy contento con el servicio. Gracias.'
    ),
  ],
  ['constitucion-sociedad']: [
    new Review(
      'Enrique Diaz',
      '5',
      '4',
      'El proceso ha sido fácil y efectivo. Volveré a utilizarlo si me embarco en otro proyecto como emprendedor.'
    ),
    new Review(
      'Pol Roca',
      '5',
      '4',
      'Me habéis sorprendido por la eficacia, simpatía y rapidez. Muchas gracias.'
    ),
    new Review(
      'Pilar Badia',
      '5',
      '5',
      'Estupendo servicio. Me han ayudado a constituir mi empresa muy rápido. El abogado que me asesoró, muy atento.'
    ),
  ],
  ['compra-sociedades']: [
    new Review(
      'Joaquín Pardo',
      '5',
      '5',
      'Mi experiencia ha sido positiva. Muy atentos durante todo el proceso, acompañándonos desde la primera consulta hasta la firma.'
    ),
    new Review(
      'Ana Pou',
      '5',
      '4',
      'Estamos muy contentos. Nos han ayudado en todo y han resuelto nuestras dudas en todo momento. Trato amable y profesional.'
    ),
    new Review(
      'Rodrigo Suárez',
      '5',
      '5',
      'Grandes profesionales. Servicio de calidad, atención inmejorable, cómodo y rápido. Lo recomiendo 100%.'
    ),
  ],
  ['testamento-notarial']: [
    new Review(
      'Ignacio Cebrián',
      '5',
      '4',
      'Mi experiencia fue muy buena. Todo el proceso fue rápido y muy profesional. Lo recomiendo 100%.'
    ),
    new Review(
      'Inés Suárez',
      '5',
      '4',
      'Mi experiencia fue totalmente satisfactoria, muy agradecida por el trato recibido. Atendieron mis dudas y preguntas, me asesoraron y guiaron en el momento que más lo necesitaba.'
    ),
    new Review(
      'Lourdes Vigil',
      '5',
      '5',
      'Buenos profesionales. Eficientes, ágiles y muy atentos. Nos gestionaron el testamento a la perfección, siempre dispuestos a atender nuestras consultas'
    ),
  ],
  ['pre-concursos-acreedores']: [
    new Review(
      'José María López',
      '5',
      '5',
      'Muy agradecidos por el bien trabajo en la gestión de unos asuntos a pesar de que eran un poco complicados y por el trato recibido. El resultado del procedimiento que nos han gestionado ha sido excelente.'
    ),
    new Review(
      'Mario Jiménez',
      '5',
      '4',
      'Muy recomendable. Me aportaron confianza y seguridad, algo muy necesario cuando necesitas que te asesoren en momentos complicados.'
    ),
    new Review(
      'Esther Peláez',
      '5',
      '5',
      'Tranquilidad de conocer el conocer el precio de todo el proceso desde el principio. Facilidades de pago. Serios y resolutivos. Todo muy bien.'
    ),
    new Review(
      'Eva Piñera Pérez',
      '5',
      '5',
      'Todo fue estupendo, lo gestionaron sin ningún tipo de incidencia y hemos quedado muy satisfecho.'
    ),
    new Review(
      'Beatriz Amigo Bengoechea',
      '5',
      '5',
      'Muy contenta con el servicio prestado. Gran equipo, muy eficientes. Se me hizo todo muy fácil y sencillo. Gracias.'
    ),
  ],
  ['ley-segunda-oportunidad']: [
    new Review(
      'Mario Jiménez',
      '5',
      '4',
      'Muy recomendable. Me aportaron confianza y seguridad, algo muy necesario cuando necesitas que te asesoren en momentos complicados.'
    ),
    new Review(
      'Cristina Fuentes',
      '5',
      '4',
      'El tiempo de respuesta es excepcional. La pregunta se respondió por teléfono con rapidez y con un alto grado de comprensión.'
    ),
    new Review(
      'Rachael Thompson',
      '5',
      '5',
      'Muy recomendable. Servicio rapido, fácil y profesional. Desde la primera consulta hasta finalizar mi proceso todo el equipo han estado muy atentos y han resuelto rápido cualquier duda. Destacar el trabajo y atención de Pol y Roger; excelente.'
    ),
    new Review(
      'Santi Andres',
      '5',
      '5',
      'Bufete recomendado sin lugar a dudas. Teniendo en cuenta que los tiempos que maneja la justicia hoy en dia son muy lentos, en mi experiencia personal he quedado plenamente satisfecho por el servcio prestado. Muy agradecidos con la letrada Paula.'
    ),
  ],
  ['patentes-y-marcas']: [
    new Review(
      'Villa Francisco',
      '5',
      '5',
      'Excelente servicio por parte del equipo de Enley los recomiendo muy buen trabajo espero poder volver a usar sus servicios muchas gracias.'
    ),
    new Review(
      'Anna Llargues',
      '5',
      '5',
      'Dude al contratar sus servicios y fue todo un acierto, súper agradables, te informan de todos los pasos, te responden a tus dudas muy rápido, respetan el precio pactado. Recomendable 100%.'
    ),
    new Review(
      'Carmen Giner',
      '5',
      '5',
      'Todo perfecto, me preocupaba que el servicio se resintiera por la distancia pero ha sido impecable! Mucho mejor que otros abogados, de los de toda la vida, con los que he trabado otras veces.'
    ),
  ],
  ['gestoria']: [
    new Review(
      'Juan Carlos Saez Perez',
      '5',
      '5',
      'Muy buen servicio, trasparente, económico y muy detallado. Muchas gracias equipo'
    ),
    new Review(
      'Mario Redondo',
      '5',
      '5',
      'Trabajo excelente y siempre atentos para solucionar cualquier duda'
    ),
    new Review(
      'Agustí Oliver',
      '5',
      '5',
      'Agilidad y eficacia! términos que parecían olvidados. Muchas gracias.'
    ),
  ],
  ['tramitacion-nie']: [
    new Review('Daniel Ruiz', '5', '5', 'Todo muy bien y muy rápido.'),
    new Review(
      'Saray Flores Rodriguez',
      '5',
      '5',
      'Genial!! Súper rápidos, eficaces y resolutivos.'
    ),
    new Review(
      'Albert Stepanyan',
      '5',
      '5',
      'Muy atentos, rapidos, y buena calidad de servicio, 100% recomiendo'
    ),
    new Review('Nona Gonzalez', '5', '5', 'Fácil y Rápido'),
    new Review(
      'Jordi Puigdomenech',
      '5',
      '5',
      'Servicio impecable. En menos de una semana tenía constituida una sociedad. Muy profesionales y con trato exquisito por parte de Eric. Super recomendable.'
    ),
    new Review(
      'Neidy Ruby',
      '5',
      '5',
      'Estoy muy contenta. La verdad es que son muy profesionales. Mi trámite fue muy rápido. Muchas gracias!!!'
    ),
  ],
};
