import { Component, OnInit } from '@angular/core';
import { ProductsServices } from 'src/app/services/products.services';

@Component({
  selector: 'app-productcategory-agency-autonomos',
  templateUrl: './autonomos.component.html',
  styleUrl: './autonomos.component.css',
})
export class ProductCategoryAgencyAutonomosComponent implements OnInit {
  public featuresByTier = [];
  public priceByTier = [];

  constructor(private productService: ProductsServices) {}

  ngOnInit(): void {
    this.productService
      .getProductServicesByTier('agency_self_employed')
      .subscribe((x) => {
        this.featuresByTier = x as any;
      });
    this.productService
      .getProductPricesSimpleByName('agency_self_employed')
      .subscribe((x) => {
        x.forEach((tier) => {
          tier['price'] = (
            parseFloat(tier['total_price'].toString()) / 1.21
          ).toFixed(); //Need to rename and substract iva
          tier['name'] = tier['tier'].toLowerCase(); //Need to rename
        });
        this.priceByTier = x as any;
      });
  }
}
