import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgForm,
  NgModel,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-mutua-select',
  templateUrl: './mutua-select.component.html',
  styleUrls: ['./mutua-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MutuaSelectComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => MutuaSelectComponent),
    },
  ],
})
export class MutuaSelectComponent
  implements ControlValueAccessor, Validator, OnInit
{
  constructor(private injector: Injector) {}

  @Input() ngModel: NgModel;
  @Output() valueSet = new EventEmitter<string>();
  @Output() ngModelChange = new EventEmitter<NgModel>();

  private innerValue: string;
  private parseError: boolean;
  public form: NgForm;

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  ngOnInit() {
    this.form = this.injector.get(NgForm, null);
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v != this.innerValue) {
      this.innerValue = v;
      try {
        this.parseError = false;
      } catch (ex) {
        this.parseError = true;
      }
      this.onChangeCallback(v);
    }
    this.onChangeCallback(this.innerValue);
  }

  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onBlur() {
    this.onTouchedCallback();
  }

  validate(control: AbstractControl): ValidationErrors {
    return !this.parseError
      ? null
      : {
          jsonParseError: {
            valid: false,
          },
        };
  }
}
